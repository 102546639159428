import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'ion-searchbar[select-all],ion-input[select-all]'
})
export class SelectallDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('click')
  selectAll() {
    let nativeEl: HTMLInputElement = this.el.nativeElement.querySelector('input');
    if (nativeEl) {
      if (nativeEl.setSelectionRange) {
        return nativeEl.setSelectionRange(0, nativeEl.value.length);
      }
      nativeEl.select();
    }
  }

}
