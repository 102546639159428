import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DmsTask } from "../../../../services/dms.service";

@Component({
    selector: 'app-dmsitemspair',
    templateUrl: './dmsitemspair.dialog.html',
    styleUrls: ['./dmsitemspair.dialog.scss'],
})
export class DmsItemsPairDialog {

    @Input('vhcitems') vhcitems: DmsTask[] = [];
    @Input('combineditems') combineditems: dmsvhccombi[] = [];

    constructor(private modalController: ModalController) {}

    dismissModal() {
        this.modalController.dismiss();
    }
}

export interface dmsvhccombi {
    concern_id: string,
    item_id: string,
    concern_name: string,
}