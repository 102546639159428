import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController, PickerController, Platform } from '@ionic/angular';
import { DateTime } from 'luxon';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'iqdatepicker',
  templateUrl: './iqdatepicker.component.html',
  styleUrls: ['./iqdatepicker.component.scss'],
  animations: [
    trigger('openClose', [
      state('normal', style({
        opacity: 1,
        position: 'absolute',
        left: 0,
        top:0,
        bottom: 0,
        right: 0
      })),
      state('void', style({
        opacity: 0,
        position: 'absolute',
        left: 0,
        top:0,
        bottom: 0,
        right: 0
      })),
      state('left', style({
        opacity: 0,
        position: 'absolute',
        left: '-100px',
        top:0,
        bottom: 0,
        right: '100px'
      })),
      state('right', style({
        opacity: 0,
        position: 'absolute',
        left: '100px',
        top:0,
        bottom: 0,
        right: '-100px'
      })),
      transition('normal => left', [
        animate('0.2s')
      ]),
      transition('normal => right', [
        animate('0.2s')
      ]),
      transition('* => void', [
        animate('0s')
      ]),
      transition('void => normal', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class IqdatepickerComponent implements OnInit, OnDestroy {
    animationState = 'normal';
    panended = true
    DATE_SHORT=DateTime.DATE_SHORT
    firstYears :any[] = [
        [2016,2017,2018,2019,2020,2021],
        [2022,2023,2024,2025,2026,2027],
        [2028,2029,2030,2031,2032,2033],
        [2034,2035,2036,2037,2038,2039]
    ];
    daysInMonth :any[]= [];
    currentMonth = DateTime.local().startOf('month');
    daysTable = true;
    yearsTable = false;
    monthsTable = false;
    allMonthsArray:any = [];
    //----- if set new year or month ----
    selectedYear:number|undefined = undefined;
    selectedMonth:number|undefined = undefined;
    //----for range -----
    @Input('RangeStart') RangeStart:DateTime|null = null;
    RangeStartString ='';
    @Input('RangeEnd') RangeEnd:any = null;
    RangeEndString ='';
    mouseDay:any = null;
    //---- for time ----
    @Input('SingleDate') SingleDate:DateTime|null = null;
    SingleDateString = '';
    //----- for date now --------
    now = DateTime.local().startOf('day');
    nowString = this.now.toLocaleString(this.DATE_SHORT)
    //-----  To activate the classes -------
    hours = [[0,1,2,3,4,5],[6,7,8,9,10,11],[12,13,14,15,16,17],[18,19,20,21,22,23]];
    minutes = [[0,1,2,3,4,5,6,7,8,9],[10,11,12,13,14,15,16,17,18,19],[20,21,22,23,24,25,26,27,28,29],[30,31,32,33,34,35,36,37,38,39],[40,41,42,43,44,45,46,47,48,49],[50,51,52,53,54,55,56,57,58,59]];
    minutsNow =  DateTime.local().minute;
    //------ Choose between a date setting method -------

    @Input('IsRange') IsRange:any = !false;
    @Input('WithTime') WithTime:any = true;
    @Output('DateChanged') OnDateChanged: EventEmitter<DateTime|DateTime[]> = new EventEmitter<DateTime|DateTime[]>();

    clock?: Subscription;

    constructor(private pickerController: PickerController, private modalController: ModalController) {
    }

    ngOnInit(): void {
        if (this.IsRange) {
            this.WithTime = false;
        }

        if (this.RangeStart) {
            this.RangeStartString = this.RangeStart.toLocaleString(this.DATE_SHORT);
            this.currentMonth =  this.RangeStart.startOf('month');
        }
        if (this.RangeEnd) {
            this.RangeEndString = this.RangeEnd.toLocaleString(this.DATE_SHORT);
        }
        if (this.SingleDate) {
            this.SingleDateString = this.SingleDate.toLocaleString(this.DATE_SHORT);
            console.log(this.SingleDateString)
            this.currentMonth =  this.SingleDate.startOf('month');
        }

        this.getDaysInMonth();
        this.getAllMonthsInYears();
        // console.log('daysInMonth',this.daysInMonth)
        this.clock = timer(1000, 60000).subscribe(()=>{
            this.now = DateTime.local().startOf('day');
            this.nowString = this.now.toLocaleString(this.DATE_SHORT)
        })
    }

    ngOnDestroy(): void {
        if (this.clock) {
            this.clock.unsubscribe();
        }
    }

    onSwipe(event: any) {
        if (Math.abs(event.deltaX) > 40) {
          if (event.deltaX > 0) {
            this.getNextMonth();
          } else {
            this.getPreviousMonth();
          }
        }
      }
    
    showAllYears() {
        this.daysTable = !this.daysTable;
        this.yearsTable = !this.yearsTable
    }
    showAllDays() {
        this.daysTable = !this.daysTable;
        this.yearsTable = false;
        this.monthsTable = false;
    }
    getAllMonthsInYears() {
        let months:any =[]
        let verzamlenArrays :any[]=[]
        for( let m = 1 ; m <=12; m++) {
            months.push(this.currentMonth.set({ month: m }));
            if(months.length == 4){
                verzamlenArrays.push(months);
                months = []
            }
            this.allMonthsArray = verzamlenArrays;            
        }
    }
    setNow() {
        if(this.IsRange) {
            this.daysTable = true;
            this.yearsTable = false;
            this.monthsTable =false;
            this.currentMonth= DateTime.local().startOf('month');
        } else {
            this.currentMonth= DateTime.local().startOf('month');
            this.SingleDate = DateTime.local()
            this.SingleDateString=this.SingleDate.toLocaleString(this.DATE_SHORT)
            this.OnDateChanged.emit(this.SingleDate);
            this.selectedYear = this.SingleDate.year;
            this.selectedMonth = this.SingleDate.month;
            this.daysTable = true;
            this.yearsTable = false;
            this.monthsTable =false;
        }
        this.getDaysInMonth();
    }
    //----------- Get days in every month -----------------
    getDaysInMonth() {
        let dayInWeek :any[] =[];
        let verzamlenArray:any[] =[];
        let datum = this.currentMonth.startOf('month')
        for (let i = 0; i < datum.weekday && datum.weekday < 7; i ++){
            dayInWeek.push('');
        }
        while (datum.month == this.currentMonth.month) {
            if(dayInWeek.length == 7) {
                verzamlenArray.push(dayInWeek);
                dayInWeek = []
            }
            dayInWeek.push(datum);
            datum = datum.plus({day:1})
        }
        if(dayInWeek.length > 0) {
          
            let empty = 7 - dayInWeek.length ;
            for(let i =0 ; i < empty;i++){
                dayInWeek.push('')
            }
            verzamlenArray.push(dayInWeek);
        }
        this.daysInMonth = verzamlenArray; 
        console.log(this.daysInMonth)

    }
     //------------Navigating month and get the days  -----------------
    getNextMonth() {
        if(!this.panended) {
            return
        }
        this.panended=false;
        this.animationState = 'left'
        setTimeout(()=>{
          this.animationState = 'void'
          setTimeout(()=>{
            let nextMonth = this.currentMonth.plus({month:1});
            this.currentMonth = nextMonth;
            this.getDaysInMonth() 
            this.animationState = 'normal'
            setTimeout(()=>{this.panended =true;}, 500);
          },10)
        }, 200)
        
    }
    getPreviousMonth() {
        if(!this.panended) {
            return
        }
        this.panended=false;
        this.animationState = 'right'
        setTimeout(()=>{
          this.animationState = 'void'
          setTimeout(()=>{
            let previous = this.currentMonth.plus({month:-1});
            this.currentMonth = previous;
            this.getDaysInMonth() 
            this.animationState = 'normal'
            setTimeout(()=>{this.panended =true;}, 500);
          },10)
        }, 200)
   
    }
    //--select day and  print date ---------
    selectDayAndPrintDate(d:DateTime){
        if (!this.SingleDate) {
            this.SingleDate = DateTime.local();
            this.SingleDateString=this.SingleDate.toLocaleString(this.DATE_SHORT)
            this.OnDateChanged.emit(this.SingleDate);
        }
        if (this.IsRange){
            if (!this.RangeStart){
                if(this.selectedYear) {
                    this.RangeStart = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                    this.RangeStartString = this.RangeStart.toLocaleString(this.DATE_SHORT);
                }else {
                    this.RangeStart = d;
                    this.RangeStartString= d.toLocaleString(this.DATE_SHORT);
                    this.SingleDateString ='';
                }
            } else {
                if(this.RangeEnd ) {
                    if(this.selectedYear) {
                        this.RangeStart = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                        this.RangeStartString= this.RangeStart.toLocaleString(this.DATE_SHORT);
                    }else{
                        this.RangeStart = d;
                        this.RangeStartString= d.toLocaleString(this.DATE_SHORT);
                    }
                    this.RangeEnd = null;
                    this.RangeEndString = '';
                } else {
                    if(d < this.RangeStart ){
                        this.RangeEnd = this.RangeStart;
                        this.RangeEndString = this.RangeStart.toLocaleString(this.DATE_SHORT);
                        if(this.selectedYear) {
                            this.RangeStart = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                            this.RangeStartString= this.RangeStart.toLocaleString(this.DATE_SHORT);
                        } else {
                            this.RangeStart = d;
                            this.RangeStartString = d.toLocaleString(this.DATE_SHORT);
                        }
                    } else if(d == this.RangeStart){
                        if(this.selectedYear) {
                            this.RangeStart = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                            this.RangeStartString= this.RangeStart.toLocaleString(this.DATE_SHORT);
                            this.RangeEnd = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                            this.RangeEndString = this.RangeEnd.toLocaleString(this.DATE_SHORT);
                        } else {
                            this.RangeStart = d;
                            this.RangeStartString= d.toLocaleString(this.DATE_SHORT);
                            this.RangeEnd = d;
                            this.RangeEndString = d.toLocaleString(this.DATE_SHORT);
                        }
                    } else {
                        if(this.selectedYear) {
                            this.RangeEnd = this.currentMonth.set({day: d.day, month: d.month, year: d.year });
                            this.RangeEndString = this.RangeEnd.toLocaleString(this.DATE_SHORT);
                        } else {
                            this.RangeEnd = d;
                            this.RangeEndString = d.toLocaleString(this.DATE_SHORT);
                        }
                    }
                    this.OnDateChanged.emit([this.RangeStart, this.RangeEnd])
                }
            } 
        } else if (this.SingleDate) {
            this.SingleDate = this.SingleDate.set({year: d.year, month: d.month, day: d.day})
            this.SingleDateString = this.SingleDate.toLocaleString(this.DATE_SHORT);
            this.OnDateChanged.emit(this.SingleDate);
            // this.SingleDate = d.set({hour:DateTime.local().hour, minute:DateTime.local().minute});
        }
     
    }
    //------------Navigating in the years -----------------
    getNextTwentyfourYears() {
        if(!this.panended) {
            return
        }
        this.panended=false;
        this.animationState = 'left'
        setTimeout(()=>{
            this.animationState = 'void'
            setTimeout(()=>{
                let next = this.firstYears[3][5];
                let newArr:any[] = [];
                let verzamlenArrays :any[]=[]
                let max =next + 24;
                this.firstYears =[];
                for(let i = next+1 ; i <= max; i ++) {
                    newArr.push(i);
                    if(newArr.length == 6){
                        verzamlenArrays.push(newArr);
                        newArr =[]
                    }
                }
                this.firstYears = verzamlenArrays; 
                this.animationState = 'normal'
                setTimeout(()=>{this.panended =true;}, 500);
            },10)
          }, 200)
     
    }
    getPreviousTwentyfourYears() {
        if(!this.panended) {
            return
        }
        this.panended=false;
        this.animationState = 'right'
        setTimeout(()=>{
            this.animationState = 'void'
            setTimeout(()=>{
                let previous = this.firstYears[0][0];
                let newArr:any[] = [];
                let verzamlenArrays :any[]=[]
                let min =previous - 24;
                this.firstYears =[];
                for(let i = min; i < previous; i ++) {
                    newArr.push(i);
                    if(newArr.length == 6){
                        verzamlenArrays.push(newArr);
                        newArr =[]
                    }
                }
              
                this.firstYears = verzamlenArrays;
              this.animationState = 'normal'
              setTimeout(()=>{this.panended =true;}, 500);
            },10)
          }, 200)
       
    }
  
    //---------------Navigating year in months table --------
    getPreviousYear(){
        this.currentMonth = this.currentMonth.plus({year: -1})
    }
    getNextYear(){
        this.currentMonth =  this.currentMonth.plus({year: 1})
    }

    //--------------- If select new Month --------
    selectMonth(m:any){
        // this.activeYear = true ;
        this.selectedMonth = m;
        this.currentMonth = this.currentMonth.set({month: m.month})
        this.getDaysInMonth()
        this.monthsTable = false;
        this.daysTable = true;
    }
      //--------------- If select new Year --------
    selectYears(y:any){
        // this.activeYear = true ;
        this.yearsTable = false;
        this.monthsTable = true;
        this.currentMonth = this.currentMonth.set({year: y})
        this.selectedYear = y;
        this.getDaysInMonth();
    }
    //------area for test mouse event--------
    isTussen(day:any){
        if(!this.RangeStart && !this.RangeEnd) {
            return false;
        } else if ( this.RangeStart && this.mouseDay && !this.RangeEnd) {
            return day > this.RangeStart && day <= this.mouseDay;
        } else if (this.RangeStart && this.RangeEnd) {
            return day >= this.RangeStart && day <= this.RangeEnd;
        } else {
            return false
        }
    }
    mouseEnter(d:any){
        this.mouseDay = d;
    }
    //------ To select empty fields within the range -------
    isDayLastInArray(isFirstRow: boolean) {
        if (!this.currentMonth) {
            return;
        }
        if( this.RangeStart && this.mouseDay && !this.RangeEnd){

            if(isFirstRow ) {
                // --links
                return this.RangeStart < this.currentMonth.startOf('month') && this.mouseDay >= this.currentMonth.startOf('month')
            } else {
                //-- rechts
                return this.RangeStart <= this.currentMonth.endOf('month') && this.mouseDay> this.currentMonth.endOf('month')
            }

        }else {
            if(isFirstRow && this.RangeStart && this.currentMonth) {
                // --links
                return this.RangeStart < this.currentMonth.startOf('month') && this.RangeEnd >= this.currentMonth.startOf('month')
            } else if (this.RangeStart && this.currentMonth) {
                //-- rechts
                return this.RangeStart <= this.currentMonth.endOf('month') && this.RangeEnd > this.currentMonth.endOf('month')
            } else {
                return false
            }
        }
        
    }

    setTime(event: any, isMinute: boolean) {
        const value: number = event.target.value
        // event.target.value = value.toString().padStart(2, "0")
        if (!this.SingleDate) {
            this.SingleDate = DateTime.local();
        }
        this.SingleDate = isMinute ? this.SingleDate.set({minute: value}) : this.SingleDate.set({hour: value})
        this.SingleDateString=this.SingleDate.toLocaleString(this.DATE_SHORT)
        this.OnDateChanged.emit(this.SingleDate);
    }

    async openTimeSpinner() {
        if (!this.SingleDate) {
            this.SingleDate = DateTime.local();
            this.SingleDateString=this.SingleDate.toLocaleString(this.DATE_SHORT)
            this.OnDateChanged.emit(this.SingleDate);
        }
        let hours = []
        let minutes = []
        for (let i = 0; i < 24; i++) {
            hours.push({value:i, text: i.toString().padStart(2, "0"), selected: i == this.SingleDate.hour})
        }
        for (let i = 0; i < 60; i++) {
            minutes.push({value:i, text: i.toString().padStart(2, "0"), selected: i == this.SingleDate.minute})
        }
        const picker = await this.pickerController.create({
            animated: true,
            buttons: [{
                text: 'Cancel',
                role: 'cancel'
            }, {
                text: 'Save',
                role: 'save'
            }],
            columns: [
                {
                    name: 'hours',
                    prefix: "Hour",
                    options: hours,
                    selectedIndex: this.SingleDate.hour
                }, {
                    name: 'minutes',
                    options: minutes,
                    prefix: "Minute",
                    selectedIndex: this.SingleDate.minute
                }
            ],
            cssClass: 'picker-hours',
            mode: 'ios',
        });
        picker.present();
        let pickresult = await picker.onDidDismiss();
        if (pickresult.role) {
            if (pickresult.data) {
                this.SingleDate = this.SingleDate.set({hour: pickresult.data.hours.value, minute: pickresult.data.minutes.value})
                this.SingleDateString=this.SingleDate.toLocaleString(this.DATE_SHORT)
                this.OnDateChanged.emit(this.SingleDate);
            }
        }
    }

    dismiss() {
        this.modalController.dismiss({
            date: this.IsRange ? [this.RangeStart, this.RangeEnd] : this.SingleDate
        })
    }
}
