/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { HelperService } from '../../../services/helper.service';
import { SupaService } from "../../../services/supa.service";

@Component({
    selector: 'app-geminisearch',
    templateUrl: './geminisearch.component.html',
    styleUrls: ['./geminisearch.component.scss'],
})
export class GeminisearchDialog implements OnInit {

    searchTerm = 'AE06MLZ';
    searchResult: BehaviorSubject<geminisearchResult[]> = new BehaviorSubject([]);

    constructor(private modalCtrl: ModalController,
        private supa: SupaService,
        private helper: HelperService,
        private http: HttpClient) {
    }

    ngOnInit() { }

    dismissModal() {
        this.modalCtrl.dismiss();
    }

    keyDownHandler(event: KeyboardEvent) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.search();
        }
    }

    onClick(id,vin) {
       
    }

    async search() {
        const exconf = await this.supa.ExternalConfig();
        if (exconf.name !== 'Gemini') {
            this.dismissModal();
            return;
        }
        if (this.searchTerm.length > 2) {
            this.searchResult.next(undefined);
            const url = exconf.data.BASE_URL +
                '/jobdiary?starttime=2020-01-01T00:00:00&registration=' +
                this.searchTerm;

            this.http.get(url, {headers:{Authorization: exconf.data.AUTH_HEADER}}).subscribe((v: any) => {
                if (v?.error?.code !== 0) {
                    console.log(v.error);
                } else if (v.result?.Bookings) {
                    const rows: geminisearchResult[] = [];
                    v.result.Bookings.forEach(r => {
                        const row: geminisearchResult = {
                            BookingID: r.BookingID,
                            ChassisNumber: r.Vehicle.ChassisNumber,
                            Forename: r.InvoiceTo.Forename,
                            Surname: r.InvoiceTo.Surname,
                            Make: r.Vehicle.Make,
                            Model: r.Vehicle.Model,
                            Registration: r.Vehicle.Registration
                        };
                        rows.push(row);
                    });
                    this.searchResult.next(rows);
                    return;
                }
                this.searchResult.next([]);
            });
        }
    }

}

export interface geminisearchResult {
    BookingID: number;
    ChassisNumber: string;
    Forename: string;
    Surname: string;
    Registration: string;
    Make: string;
    Model: string;
}
