import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { JSettings } from '../../../services/supa.service';
import { VHCQuestion } from '../vhcquestionblockmodule';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-qbeditor',
  templateUrl: './qbeditor.dialog.html',
  styleUrls: ['./qbeditor.dialog.scss'],
})
export class QbeditorDialog {

  @Input('name') name: string;
  @Input('name2') name2: string;
  @Input('variable') variable: boolean;
  @Input('settings') settings: JSettings;
  @Input('questions') questions: VHCQuestion[] = [];

  constructor(private modalController: ModalController) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  save() {
    this.modalController.dismiss({
      questions: this.questions,
      name: this.name,
      name2: this.name2,
      variable: this.variable,
    });
  }

  doReorderQuestions(ev: any) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.questions = ev.detail.complete(this.questions);
  }

  doReorderAnswers(ev: any, qindex: number) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.questions[qindex].answers = ev.detail.complete(this.questions[qindex].answers);
  }

  addAnswer(question: VHCQuestion) {
    question.answers.push(['','']);
  }

  addQuestion() {
    this.questions.push({
      name: '',
      name2: '',
      answers: [],
      dateAuthorised: ''
    });
  }

  deleteQuestion(index) {
    this.questions.splice(index, 1);
  }

  deleteAnswer(question, index) {
    question.answers.splice(index, 1);
  }

  trackByFn(index, item) {
    return index;
  }

}
