import { HelperService } from "../services/helper.service";
import { InputField } from "../services/new-edit.dialog";
import { JSettings, SupaService } from "../services/supa.service";

export class Vhcmodule
{
    public ID = 0;
    public dateAuthorised = ''
    public settings: JSettings; 
    public name = "Module not implemented yet";
    public name2 = "";
    public type = "Vhcmodule type placeholder";
    public includeinsignature = true;
    
    protected helper: HelperService;
    protected supa: SupaService;

    constructor(helper: HelperService, supa: SupaService) {
        this.helper = helper;
        this.supa = supa;
    }

    public async loadSettings() {
        this.settings = await this.supa.Settings();
    }
    
    public getPdfVersion(param:any=undefined,primarylang=false) {
        return null;
    }
    
    public getHtmlVersion(param:any=false,primarylang=false) {
        return null;
    }
    
    public getName(): string {
        return this.name;
    }
    
    public getType(): string {
        return this.type;
    }

    public getIcon() :string {
        return 'square-outline' //checkbox-outline
    }

    public async openEditor(): Promise<void> {
        await this.loadSettings();

        let inputarr: InputField[] = [{ type: 'text', label: 'Change the display name ('+this.settings.lang1name+')', value: this.name, name: 'name' }]
        if (this.settings.lang2enabled) {
            inputarr.push({ type: 'text', label: 'Change the display name ('+this.settings.lang2name+')', value: this.name2, name: 'name2' });
        }

        let {data} = await this.helper.presentNewEditDialog(this.type + ' name', inputarr)
        if (data) {
            this.name = data.data.name;
            this.name2 = data.data.name2;
        }
    }

    public async openAnswer(param:any=undefined): Promise<boolean> {
        await this.helper.notify('This module can not be answered yet')
        return true
    }

    public deserialize(jsonIn: string) {
        const pob = JSON.parse(jsonIn);
        this.name = pob.name
        this.name2 = pob.name2
    }

    public serialize(): string {
        return JSON.stringify({name: this.name, name2: this.name2});
    }

    public toDatabase(includedata=false):any {
        return {name: this.name, name2: this.name2, type: this.type, dateAuthorised: this.dateAuthorised}
    }
    
    public fromDatabase(obj,includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.dateAuthorised = obj.dateAuthorised
    }

    // So the compiler does not go mad.....
    public questions:any
    public value:any
    public auth:any
    public data:any
    public visibleForCustomer:any
    public getQIcon(v:any):any{}
}