import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusname'
})
export class StatusnamePipe implements PipeTransform {

  transform(value: unknown): string {
    switch (value) {
      case "new":
        return "New";
      case "pre":
        return "Pre check";
      case "mpi":
        return "Multi point inspection";
      case "jp":
        return "Jobs & Parts";
      case "auth":
        return "Authorisation";
      case "qi":
        return "Quality inspection";
      default:
        return "Completed";
    }
  }

}
