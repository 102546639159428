/* eslint-disable max-len */
import { HelperService } from '../../services/helper.service';
import { InputField } from '../../services/new-edit.dialog';
import { SupaService } from '../../services/supa.service';
import { Vhcmodule } from '../vhcmodule';
import { CsatanswerDialog } from './csatanswer/csatanswer.dialog';

export class VhcCsatModule extends Vhcmodule
{
    public data: any = {
        value: 0,
        message: '',
        messageEnabled: false
    };

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = 'Customer_Satisfaction';
        this.name = 'Your title here';
    }

    public getIcon(): string {
        return this.value === 0 ? 'square-outline' : 'checkbox-outline';
    }

    public toDatabase(includedata?: boolean) {
        return {name: this.name, name2: this.name2, type: this.type, data: this.data};
    }

    public fromDatabase(obj: any, includedata?: boolean): void {
        console.log(obj);
        this.name = obj.name;
        this.name2 = obj.name2;
        if (obj.hasOwnProperty('data')) {
            this.data = obj.data;
        } else if (obj.hasOwnProperty('value')) {
            this.data.value = obj.value;
        }
    }

    public async openEditor(): Promise<void> {
        await this.loadSettings();

        const inputarr: InputField[] = [{ type: 'text', label: 'Change the display name ('+this.settings.lang1name+')', value: this.name, name: 'name' }]
        if (this.settings.lang2enabled) {
            inputarr.push({ type: 'text', label: 'Change the display name ('+this.settings.lang2name+')', value: this.name2, name: 'name2' });
        }
        inputarr.push({ type: 'boolean', label: 'Display the message box', value: this.data.messageEnabled, name: 'messagebox' });

        let {data} = await this.helper.presentNewEditDialog(this.type + ' name', inputarr)
        if (data) {
            this.name = data.data.name;
            this.name2 = data.data.name2;
            this.data.messageEnabled = data.data.messagebox;
        }
    }

    public async openAnswer(param?: any) {
        const {data} =  await this.helper.createAndOpenModal(CsatanswerDialog, {module: this}, 'doubled-modal');
        return data;
    }

    public getHtmlVersion(param?: any, primarylang?: boolean) {
        return '<h1>'+this.value+'</h1>';
    }

    public getPdfVersion(param?: any, primarylang?: boolean) {
        return { text: 'Please circle how satisfied you are: 0 1 2 3 4 5', margin: [0,0,0,15] };
    }
}
