import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-maintenancetables',
  templateUrl: './maintenancetables.component.html',
  styleUrls: ['./maintenancetables.component.scss'],
})
export class MaintenancetablesComponent {

  constructor(private router: Router) {}

  async onClick(file: FileDisplayItem) {    
    this.router.navigate(['/settings/maintenance-tables/editor', {id: file.id}]);
  }

  async newFile(parent:number) {
    console.log(parent, parent == null);
    this.router.navigate(['/settings/maintenance-tables/editor', {parent: parent}]);
  }

}
