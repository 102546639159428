import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-jobchoose',
  templateUrl: './jobchoose.dialog.html',
  styleUrls: ['./jobchoose.dialog.scss'],
})
export class JobchooseDialog implements OnInit {


  @Input('folder') folder = '';

  constructor(private modalController: ModalController, private alertController: AlertController) { }

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  async onClick(event) {
    const alert = await this.alertController.create({
      header: 'Do you want to choose "' + event.name + '"?',
      subHeader: 'Click \'OK\' to continue',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Ok',
          handler: ()=>{
            this.modalController.dismiss({name: event.name, id: event.id});
          }
        }
      ]
    });

    await alert.present();
  }
}
