/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { _ra } from '../../functions';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { Vhcmodule } from '../vhcmodule';
import { Cross_sellchooseDialog } from './cross_sellchoose/cross_sellchoose.dialog';

export class VhcCross_sellModule extends Vhcmodule
{
    data: any[] = [];

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = 'Cross_sell';
        this.name = 'Sales';
        this.name2 = 'Sales';
    }

    getPdfVersion(param: any=undefined,primarylang=false) {
        return { text: 'The sales items have not been added yet', margin: [0,0,0,15] };
    }

    public async openEditor(): Promise<void> {
        await this.helper.promptYesNo('Sales module', 'During the creation of a VHC Record, someone will be able to add extra sales items', ()=>{});
    }

    public toDatabase(includedata=false): any {
        return {name: this.name, name2: this.name2, type: this.type, data: this.data};
    }

    public fromDatabase(obj,includedata=false) {
        this.name = obj.name;
        this.data = obj.data;
    }

    getHtmlVersion(param: any=false,primarylang=false) {
        if (this.data.length === 0) {
            return '';
        }
        let htmlBody = `<table class="striped" style="border: 1px solid #d2d6dd;">`;
        htmlBody += `
        <thead><tr>
            <th>${primarylang ? this.name : this.name2}</th>
            <th width="75">${primarylang ? 'Sundriesprice' : ' السعر'}</th>
            <th width="75">${primarylang ? 'Price' : 'السعر'}</th>
            <th width="50">${primarylang ? 'Auth' : 'التعميد'}</th>
        </tr></thead><tbody>
        `;
        let atleastone = false;
        this.data.forEach((t,i) => {
            if (t.dateAuthorised || param === false) {
                atleastone = true;
                htmlBody += `
                    <tr style="border-top: 1px solid #d2d6dd;">
                    <td>${primarylang ? t.name : t.name2}</td>
                    <td style="border-left: 1px solid #607d8b;border-right: 1px solid #607d8b" data-hhaprice="${this.helper.tax(t.sundriesprice, 0)}">${this.helper.stringToArabic(this.helper.tax(t.sundriesprice,environment.tax), primarylang)}</td>
                    <td style="text-align: right" class="price" data-hhaprice="${this.helper.tax(t.price, 0)}">${this.helper.stringToArabic(this.helper.tax(t.price, environment.tax),primarylang)}</td>
                    <td style="border-left: 1px solid #607d8b;">
                    ${t.dateAuthorised?'🔑':''}
                    </td>
                    </tr>
                `;
            }
        });
        htmlBody += `</tbody></table><br />`;
        return atleastone ? htmlBody : '';
    }

    public async openAnswer(param: any=undefined): Promise<boolean> {
        const {data} = await this.helper.createAndOpenModal(Cross_sellchooseDialog, {}, 'doubled-modal big-modal');
        if (data) {
            const response = await this.supa.supabase.from('cross_sell').select('*').eq('id', data.id).single();
            if (response.error) {
                this.helper.notify('Could not load sales item', 'danger');
                console.log(response.error);
            } else {
                response.data.jobs.forEach(element => {
                    element.name = response.data.name + '_' + element.code;
                    element.name2 = response.data.name2 + '_' + element.code;
                });
                response.data.parts.forEach(element => {
                    element.name = response.data.name + '_' + element.code;
                    element.name2 = response.data.name2 + '_' + element.code;
                });
                const thedata = {
                    jobs: response.data.jobs,
                    parts: response.data.parts,
                    name: response.data.name,
                    name2: response.data.name2,
                    sundries: Number.parseFloat(''+response.data.sundriesprice),
                    price: 0
                };

                let price = isNaN(thedata.sundries) ? 0 : thedata.sundries;
                thedata.jobs.forEach(j => {
                    if (!isNaN(j.price)) {price += j.price;}
                });
                thedata.parts.forEach(j => {
                    if (!isNaN(j.price)) {price += j.price;}
                });
                thedata.price = Math.round(price * 100) / 100;

                this.data.push(thedata);
                console.log(thedata);
                this.helper.notify('Sales item loaded successfully', 'success');
                return false; // normally true but i dont want the screens to load the next module automatically after a Cross_sell was selected since they might want to add additional sales items.
            }
        }
        return false;
    }
}
