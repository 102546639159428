import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-backgroundbrowser',
  templateUrl: './backgroundbrowser.component.html',
  styleUrls: ['./backgroundbrowser.component.scss'],
})
export class BackgroundBrowserDialog implements OnInit {

  fileurl = environment.internalfileUrl + 'drawbackgrounds/small/'
  
  constructor(private modalController: ModalController, 
              private supa: SupaService, 
              private loadingController: LoadingController,
              private helper: HelperService) { }

  close(data) {
    this.modalController.dismiss(data)
  }

  ngOnInit() {
    this.load();
  }
  
  data=undefined

  async load() {
    let result = await this.supa.supabase.storage.from('internaldesign').list('drawbackgrounds/small')
    if (result.data) {
      this.data = result.data
    }
  }

  async loadBg(name) {
    console.log("Loading " + name);
    let result = await this.supa.supabase.storage.from('internaldesign').getPublicUrl('drawbackgrounds/big/'+name)
    if (result.data) {
      this.close(result.publicURL);
    } else {
      this.helper.notify('Could not load image');
    }
    // const loading = await this.loadingController.create({
    //   message: 'Downloading background',
    //   spinner: 'bubbles',
    //   backdropDismiss: true
    // });
    // await loading.present();
    // let result = await this.supa.supabase.storage.from('internaldesign').getPublicUrl('drawbackgrounds/big/'+name)
    // if (result.data) {
    //   let data = await this.getBase64FromUrl(result.data.publicURL);
    //   if (data) {
    //     loading.dismiss();
    //     this.close(data);
    //     return;
    //   }
    // }
    // this.helper.notify('Could not load image');
    // loading.dismiss();
  }

  // async getBase64FromUrl(url) {
  //   const data = await fetch(url);
  //   const blob = await data.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob); 
  //     reader.onloadend = () => {
  //       const base64data = reader.result;   
  //       resolve(base64data);
  //     }
  //     reader.onerror = () => {
  //       console.log(reader.error);
  //       resolve(undefined)
  //     }
  //   });
  // }

}
