import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-storagemeter',
  templateUrl: './storagemeter.component.html',
  styleUrls: ['./storagemeter.component.scss'],
})
export class StoragemeterComponent implements OnInit {

  @Output('OnSizeReady') OnSizeReadyEmitter : EventEmitter<StorageSizeEvent> = new EventEmitter<StorageSizeEvent>();

  //                     h   m    s    ms
  STORAGE_CALC_MAX_AGE = 1 * 60 * 60 * 1000
  //                     GB    MB     KB     B
  STORAGE_CALC_MAX_CAP = 25 * 1024 * 1024 * 1024
  STORAGE_CALC_MAX_CAPSTR = this.getSizeHrf(this.STORAGE_CALC_MAX_CAP)
  bucketObject: StorageSizeData = {
    TOTALSIZE : -1,
    TOTALSIZEHRF : "",
    PROGRESS:0
  }
 
  constructor(private supa: SupaService,private toastController: ToastController) {
  }

  ngOnInit() {
    this.init()
  }

  async init() {
    const {data,error} = await this.supa.supabase.from('storagemeter').select('*').order('id', {ascending: false}).limit(1);
    if(error || !data) {
      console.log('error',error)
      return;  
    } else {
      let cacheTime = Array.isArray(data) ? data.length > 0 ? new Date(data[0].created_at).getTime() : 0 : 0;
      if ((new Date()).getTime() - cacheTime < this.STORAGE_CALC_MAX_AGE) {
        console.log("storagemeter served from cache " + (((new Date()).getTime() - cacheTime ) / 1000) + "s")
        this.bucketObject.TOTALSIZE = data[0].size;
        this.bucketObject.TOTALSIZEHRF = data[0].size_hrf;
        this.bucketObject.PROGRESS = this.bucketObject.TOTALSIZE / this.STORAGE_CALC_MAX_CAP
        this.emitValue();
      } else {
        console.log("storagemeter cache expired " + (((new Date()).getTime() - cacheTime ) / 1000) + "s")
        this.bucketObject.TOTALSIZE = await this.calculateTotalStorage()
        this.bucketObject.TOTALSIZEHRF = this.getSizeHrf(this.bucketObject.TOTALSIZE);
        this.bucketObject.PROGRESS = this.bucketObject.TOTALSIZE / this.STORAGE_CALC_MAX_CAP;
        this.emitValue();
        const {data} = await this.supa.supabase.from('storagemeter').insert({size:this.bucketObject.TOTALSIZE,size_hrf: this.bucketObject.TOTALSIZEHRF });
      }
    }
  }

  emitValue() {
    this.OnSizeReadyEmitter.emit({
      currentSize: this.bucketObject.TOTALSIZE,
      currentSizeHrf: this.bucketObject.TOTALSIZEHRF,
      fraction: this.bucketObject.PROGRESS,
      STORAGE_CALC_MAX_AGE: this.STORAGE_CALC_MAX_AGE,
      STORAGE_CALC_MAX_CAP: this.STORAGE_CALC_MAX_CAP,
      STORAGE_CALC_MAX_CAPSTR: this.STORAGE_CALC_MAX_CAPSTR
    })
  }

  async calculateTotalStorage(): Promise<number> {
    const data = (await this.supa.supabase.storage.listBuckets());
    console.log(data)
    if (data.error) {
      const toast = await this.toastController.create({
        message: 'Size could not be loaded',
        duration: 2000,
        color: 'danger'
      });
      toast.present();
      console.log(data.error)
      return 0;
    } else {
      let totalSize = 0;
      for(let i = 0; i < data.data.length; i++) {
        totalSize += await this.getBucketSize(data.data[i].name);
      }
      console.log("------------------- +")
      console.log(this.getSizeHrf(totalSize))
      return totalSize;
    }
  }

  async getBucketSize(bucketName:string):Promise<number> {
    let arr:string[] = [''];
    let totalSize = 0;
    while (arr.length > 0) {
      let erbij:string[] = [];
      for(let i=0; i< arr.length ;i++) {
        const data =(await this.supa.supabase.storage.from(bucketName).list(arr[i]));
        if(data.error) {
          const toast = await this.toastController.create({
            message: 'Encountered a problem during the calculation of the size, results could be off',
            duration: 2000,
            color: 'danger'
          });
          toast.present();
        } else {
          data.data.forEach(a =>{
            if(!a.metadata){
              let path = arr[i] == '' ? '' : arr[i]+'/'
              path += a.name
              erbij.push(path)
          
            } else {
              // is het geen folder add dan de size aan totalSize
              totalSize += (a.metadata as any).size
            }
          })
        }
      }
      // arr leeg
      // push all erbij
      arr = [...erbij];
    }
    console.log(bucketName + ' size',this.getSizeHrf(totalSize))
    return totalSize
  }

  getSizeHrf(size) {
    let size_hrf = "";
    if(size < 1024) {
      //----BT
      size_hrf = size + " B"; 
    } else if(size < 1048576) {
      //----KB
      size_hrf = ( Math.round((size / 1024 ) * 100) / 100 )+ " KB"
    } else if(size < 1073741824) {
      //---MB
      size_hrf = ( Math.round((size / 1048576 ) * 100) / 100) + ' MB'
    } else {
      //---GB
      size_hrf = ( Math.round(( size / 1073741824  ) * 100)/ 100)  + ' GB'
    }
    return size_hrf
  } 
}

export interface StorageSizeData {
  TOTALSIZE : number,
  TOTALSIZEHRF : string,
  PROGRESS:number
}

export interface StorageSizeEvent {
  STORAGE_CALC_MAX_AGE: number,
  STORAGE_CALC_MAX_CAP: number,
  STORAGE_CALC_MAX_CAPSTR: string,
  currentSize: number,
  currentSizeHrf: string,
  fraction: number
}