import { Component, OnInit } from '@angular/core';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-messagetemplates',
  templateUrl: './messagetemplates.component.html',
  styleUrls: ['./messagetemplates.component.scss'],
})
export class MessagetemplatesComponent implements OnInit {

  settings:JSettings = undefined;

  templateData = undefined
  id=null

  templateArray = [
    {title: "Follow up", dbfield: "followup1", islang2: false, helptext: "Use $VHCITEMS$ and $CUSTOMER$ as a placeholder"},
    {title: "Follow up", dbfield: "followup2", islang2: true, helptext: "Use $VHCITEMS$ and $CUSTOMER$ as a placeholder"},
    {title: "Portal url", dbfield: "portalurl1", islang2: false, helptext: "Use $PORTALURL$ as a placeholder"},
    {title: "Portal url", dbfield: "portalurl2", islang2: true, helptext: "Use $PORTALURL$ as a placeholder"},
  ]

  constructor(private supa: SupaService, public helper: HelperService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.settings = await this.supa.Settings();
    let {data, error} = await this.supa.supabase.from('messagetemplate').select('*').limit(1)
    if (error) {
      this.helper.notify('Could not load the data', 'danger');
      console.log(error);
    } else {
      if (data.length == 0) {
        await this.setDefault();
      } else {
        this.templateData = data[0]
        this.id = data[0].id
      }
    }
  }

  async setDefault() {
    const defaultData = {
      followup1: '',
      followup2: '',
      portalurl1: '',
      portalurl2: ''
    };
    let {error, data} = await this.supa.supabase.from('messagetemplate').insert(defaultData).single();
    if (error) {
      this.helper.notify('Could not save a new entry in the database', 'danger');
      console.log(error)
    } else {
      this.helper.notify('A new entry has been made in the database', 'success');
      this.id = data.id;
      this.templateData = defaultData;
    }
  }

  @ConfirmDelete('Press yes to save these message templates', 'Save changes?')
  async onSave() {
    let {error} = await this.supa.supabase.from('messagetemplate').update(this.templateData, {returning: 'minimal'}).eq('id', this.id)
    if (error) {
      this.helper.notify('Could not save the terms & conditions', 'danger');
      console.log(error)
    } else {
      this.helper.notify('The terms & conditions have been saved', 'success');
    }
  }


}
