/* eslint-disable max-len */
import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FileDisplayItem } from '../components/fs/filedisplay/filedisplay.component';
import { VehiclevisualsDialog } from '../components/vehiclevisuals/vehiclevisuals.component';
import { ConfirmDelete } from '../decorators/confirmdelete.decorator';
import { HelperService } from './helper.service';
import { SupaService } from './supa.service';

@Component({
  selector: 'dia-tyrebrands',
  template: `
    <ion-header translucent>
      <ion-toolbar mode='md'>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="save()">
            <ion-icon slot="icon-only" name="save"></ion-icon>
          </ion-button>
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content mode='md' fullscreen>
      <div *ngIf="customDescription" [innerHTML]="customDescription | marked" class="markdownfield"></div>
      <ion-list>
        <ng-container *ngFor="let line of inputFields">

          <div [ngSwitch]="line.type">
            <ion-item *ngSwitchCase="'textarea'">
              <ion-label position="stacked">{{line.label}}</ion-label>
              <ion-textarea [autoGrow]="true" [(ngModel)]="line.value"></ion-textarea>
            </ion-item>

            <ion-item *ngSwitchCase="'boolean'">
              <ion-label>{{line.label}}</ion-label>
              <ion-toggle [(ngModel)]="line.value" slot="end"></ion-toggle>
            </ion-item>

            <ng-container *ngSwitchCase="'codeprice'">
              <ion-list-header class="ion-margin-top">
                <ion-label>{{line.label}}</ion-label>
                <ion-button (click)="line.value.push({code:'',price:0})">Add</ion-button>
              </ion-list-header>
              <ion-grid>
                <ion-row *ngFor="let item of line.value let i = index">
                  <ion-col>
                    <ion-item fill="outline">
                      <ion-label position="floating">Code</ion-label>
                      <ion-input [(ngModel)]="item.code" placeholder="Code"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item fill="outline">
                      <ion-label position="floating">Price</ion-label>
                      <ion-input [(ngModel)]="item.price" placeholder="Price" type="number"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col style="flex: 0 0 70px; text-align: right;">
                    <ion-button fill="clear" color="danger" (click)="line.value.splice(i,1)">
                      <ion-icon slot="icon-only" name="trash"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="line.value.length === 0">
                  <ion-col class="ion-padding-start">No items yet</ion-col>
                </ion-row>
              </ion-grid>
            </ng-container>

            <ng-container *ngSwitchCase="'codepricehour'">
              <ion-list-header class="ion-margin-top">
                <ion-label>{{line.label}}</ion-label>
                <ion-button (click)="line.value.push({code:'',price:0, hour: 0})">Add</ion-button>
              </ion-list-header>
              <ion-grid>
                <ion-row *ngFor="let item of line.value let i = index">
                  <ion-col>
                    <ion-item fill="outline">
                      <ion-label position="floating">Code</ion-label>
                      <ion-input [(ngModel)]="item.code" placeholder="Code"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item fill="outline">
                      <ion-label position="floating">Price</ion-label>
                      <ion-input [(ngModel)]="item.price" placeholder="Price" type="number"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item fill="outline">
                      <ion-label position="floating">Hour</ion-label>
                      <ion-input [(ngModel)]="item.hour" placeholder="Hour" type="number"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col style="flex: 0 0 70px; text-align: right;"><ion-button fill="clear" color="danger" (click)="line.value.splice(i,1)"><ion-icon slot="icon-only" name="trash"></ion-icon></ion-button></ion-col>
                </ion-row>
                <ion-row *ngIf="line.value.length === 0">
                  <ion-col class="ion-padding-start">No items yet</ion-col>
                </ion-row>
              </ion-grid>
            </ng-container>

            <ion-item *ngSwitchCase="'vehiclevisuals'">
              <ion-label position="floating">{{line.label}}</ion-label>
              <ion-input type="text"[(ngModel)]="line.value"></ion-input>
              <ion-badge (click)="searchVisual(line)" color="primary" style="position: relative; top: 13px;" class="pointer" mode="ios" slot="end">Search for videos</ion-badge>
            </ion-item>

            <ion-item *ngSwitchDefault>
              <ion-label position="floating">{{line.label}}</ion-label>
              <ion-input [type]="line.type" [(ngModel)]="line.value"></ion-input>
            </ion-item>
          </div>

        </ng-container>
        <ion-item *ngIf="ondelete != undefined" button detail (click)="del()">
          <ion-label color="danger">Delete</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  `,
  styles: [],
})
export class NewEditDialog {

  @Input('title') title: InputField[];
  @Input('customDescription') customDescription: string;
  @Input('inputFields') inputFields: InputField[];
  @Input('ondelete') ondelete: FileDisplayItem = undefined;

  constructor(private modalController: ModalController, private toastController: ToastController, private supa: SupaService, private helper: HelperService) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  @ConfirmDelete()
  async del() {
    const {data, error} = await this.supa.supabase.from(this.ondelete.category).delete().eq('id', this.ondelete.id);
    if (error) {
        console.log(error);
        const toast = await this.toastController.create({
            message: 'Item was not deleted',
            duration: 3000,
            color: 'error'
        });
        toast.present();
        return;
    }
    const toast = await this.toastController.create({
        message: 'Item was deleted',
        duration: 3000,
        color: 'success'
    });
    toast.present();
    this.dismissModal();
  }

  async atleastone(label) {
    const toast = await this.toastController.create({
        message: '"' + label + '" needs at least 1 character',
        duration: 2000,
        color: 'warning'
    });
    toast.present();
  }

  async nan(label) {
    const toast = await this.toastController.create({
        message: '"' + label + '" is not a number',
        duration: 2000,
        color: 'warning'
    });
    toast.present();
  }

  async searchVisual(line) {
    const result = await this.helper.createAndOpenModal(VehiclevisualsDialog, {preSelect: line.value}, 'doubled-modal big-modal');
    if (result) {
      if (result.data) {
        if (result.data.id) {
          line.value = result.data.id;
        }
      }
    }
  }

  async save() {
    const retObj = {};

    for (const i of this.inputFields) {
        retObj[i.name] = i.value;
        if (i.type !== 'number' && i.type !== 'boolean' && i.type !== 'vehiclevisuals' && i.type !== 'codeprice' && i.type !== 'codepricehour') {
          if (i.value === undefined) {
            this.atleastone(i.label);
            return;
          }
          if (i.value.toString().length < 1) {
              this.atleastone(i.label);
              return;
          }
        } else if (i.type === 'number') {
          retObj[i.name] = parseFloat(i.value.toString());
          if (isNaN(retObj[i.name])) {
            this.nan(i.label);
            return;
          }
        }
    }

    this.modalController.dismiss({ data: retObj });
  }

}

export interface InputField {
    type: InputFieldType;
    label: string;
    value: string | number | boolean | any;
    name: string;
}

export type InputFieldType = 'email' | 'codeprice' | 'codepricehour' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'boolean' | 'textarea' | 'vehiclevisuals';
// export type InputFieldType = 'checkbox' | 'radio' | 'textarea' | 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';
