/* eslint-disable eqeqeq */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HelperService } from '../services/helper.service';
import { VhcBrakeModule } from './brakemodule/vhcbrakemodule';
import { VhcFuelModule } from './fuelmodule/vhcfuelmodule';
import { VhcInputModule } from './inputmodule/vhcinputmodule';
import { VhcInstructionModule } from './instructionmodule/vhcinstructionmodule';
import { VhcMaintenancedModule } from './maintenancemodule/maintenancemodule';
import { VhcMediaModule } from './mediamodule/mediamodule';
import { VhcQuestionblockModule } from './questionblock/vhcquestionblockmodule';
import { VhcSignatureModule } from './signaturemodule/vhcsignaturemodule';
import { VhcTyreModule } from './tyremodule/vhctyremodule';
import { VhcWalkaroundModule } from './walkaroundmodule/vhcwalkaroundmodule';
import { Vhcmodule } from './vhcmodule';
import { SupaService } from '../services/supa.service';
import { VhcCross_sellModule } from './cross_sellmodule/cross_sellmodule';
import { VhcDrawModule } from './drawmodule/vhcdrawmodule';
import { VhcBoschModule } from './boschmodule/vhcboschmodule';
import { VhcCsatModule } from './csatmodule/vhccsatmodule';

export function VhcModuleFactory(helper: HelperService, supa: SupaService, data, loadData = false, includedata=false): Vhcmodule {
    let newModule: Vhcmodule;
    if (data.type == 'Input') {
        newModule = new VhcInputModule(helper, supa);
    } else if (data.type == 'Instruction') {
        newModule = new VhcInstructionModule(helper, supa);
    } else if (data.type == 'Questionblock') {
        newModule = new VhcQuestionblockModule(helper, supa);
    } else if (data.type == 'Tyres') {
        newModule = new VhcTyreModule(helper, supa);
    } else if (data.type == 'Brakes') {
        newModule = new VhcBrakeModule(helper, supa);
    } else if (data.type == 'Media') {
        newModule = new VhcMediaModule(helper, supa);
    } else if (data.type == 'Walkaround') {
        newModule = new VhcWalkaroundModule(helper, supa);
    } else if (data.type == 'Fuel') {
        newModule = new VhcFuelModule(helper, supa);
    } else if (data.type == 'Signature') {
        newModule = new VhcSignatureModule(helper, supa);
    } else if (data.type == 'Maintenance') {
        newModule = new VhcMaintenancedModule(helper, supa);
    } else if (data.type == 'Cross_sell') {
        newModule = new VhcCross_sellModule(helper, supa);
    } else if (data.type == 'Draw') {
        newModule = new VhcDrawModule(helper, supa);
    } else if (data.type == 'Bosch') {
        newModule = new VhcBoschModule(helper, supa);
    } else if (data.type == 'Customer_Satisfaction') {
        newModule = new VhcCsatModule(helper, supa);
    } else {
        newModule = new Vhcmodule(helper, supa);
        newModule.type = data.type;
    }
    if (loadData) {
        newModule.fromDatabase(data, includedata);
    }
    return newModule;
}