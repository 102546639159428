import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { HelperService } from '../../../services/helper.service';
import { IframeWrapperComponent } from '../../iframewrapper/iframewrapper.component';

@Component({
  selector: 'jjj-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input('crumb') crumb = [['Page subtitle']];
  @Input('title') pageTitle = "Page title";
  @Input('enableadd') hasAdd = false;
  @Input('enablemenu') hasMenu = false;
  @Input('enablesave') hasSaveOnly = false;
  @Input('helpurl') helpurl = "";

  @Output('add') onadd = new EventEmitter<void>();
  @Output('save') onsave = new EventEmitter<void>();
  @Output('back') onback = new EventEmitter<void>();
  @Output('delete') ondelete = new EventEmitter<void>();

  constructor(public helper: HelperService) { }

  getHelpLocation(isleft) {
    if (isleft) {
      if (this.hasSaveOnly || this.hasMenu || this.hasAdd) {
        return 'top: 84px; left: 102px';
      } else {
        return 'top: 30px; left: 30px';
      }
    } else {
      if (this.hasSaveOnly || this.hasMenu || this.hasAdd) {
        return 'top: 84px; right: 102px';
      } else {
        return 'top: 30px; right: 30px';
      }
    }
  }

  ngOnInit() {
  }

  onAdd() {
    this.onadd.emit();
  }
  
  onSave() {
    this.onsave.emit();
  }
  
  onBack() {
    this.onback.emit();
  }

  onHelp() {
    this.helper.createAndOpenModal(IframeWrapperComponent, {
      url: this.helpurl,
      title: 'Help',
      color: 'dark'
    }, 'big-modal')
  }

  @ConfirmDelete()
  onDelete() {
    this.ondelete.emit();
  }

}
