import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SupaService } from '../../../services/supa.service';
import { Tyrebrand } from '../../../interfaces/tyrebrand.interface';
import { ModalController } from '@ionic/angular';
import { TyrebrandsDialog } from './tyrebrands.dialog ';
import { FiledisplayComponent, FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-tyrebrands',
  templateUrl: './tyrebrands.component.html',
  styleUrls: ['./tyrebrands.component.scss'],
})
export class TyrebrandsComponent implements AfterViewInit {

  @ViewChild('fdisplay') fdisplay: FiledisplayComponent

  listMode=false

  constructor(private supa: SupaService, private modalController: ModalController, private helper: HelperService) { }

  ngAfterViewInit() {
    this.init()
  }

  async init() {
    this.fdisplay.setLoading();
    let { data, error } = await this.supa.supabase.from('tyrebrand').select('id,name,logo').order('name');
    if (error) {
      console.log(error);
      this.helper.notify('Could not load the data', 'danger');
    } else { 
      this.fdisplay.setFiles(data.map(i => {
        return {
          isFolder: false,
          category: 'tyrebrand',
          id: i.id,
          name: i.name,
          icon: i.logo
        }
      }))
    }
  }

  async deleteItem(item: FileDisplayItem) {
    this.fdisplay.setLoading();
    this.supa.supabase.from('tyrebrand').delete().eq('id', item.id).then(v => {
      if (v.error) {
        this.helper.notify('Could not delete tyrebrand', 'danger');
      } else {
        this.helper.notify('Tyrebrand was deleted', 'success');
      }
      this.init()
    })
  }

  async onAdd(id=null) {
    const modal = await this.modalController.create({
      component: TyrebrandsDialog,
      cssClass: 'medium-modal',
      componentProps: { id: id },
      mode: 'ios'
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      if (data.parentShouldReload) {
        this.init();
      }
    }
  }
}
