import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { jlocation_name_code } from '../../interfaces/jlocation.interface';
import { Vehicle } from '../../interfaces/vehicle.interface';
import { followupdata } from '../../pages/followup/followupitem.interface';
import { HelperService } from '../../services/helper.service';
import { JSettings, SupaService } from '../../services/supa.service';
import { MarkdownDialog } from '../markdowndialog/markdowndialog.component';

@Component({
  selector: 'app-followupeditdialog',
  templateUrl: './followupeditdialog.component.html',
  styleUrls: ['./followupeditdialog.component.scss'],
})
export class FollowupeditDialog implements OnInit {

  @Input('id') id;
  settings: JSettings = null;
  userList:Map<string,{fullname:string, employee_id:string}>;

  followUpData: followupdata;
  vehicle: Vehicle;

  constructor(private modalController: ModalController, 
    private loadingController: LoadingController,
    private supa: SupaService, 
    private helper: HelperService,
    private router: Router) { }

  ngOnInit() { 
    this.init()
  }

  dismissModal(save=false) {
    if (save) {
      this.modalController.dismiss({saves: true});
    } else {
      this.modalController.dismiss();
    }
  }

  statusChange(event, item, i) {
    this.followUpData.history.entries.unshift({
      date: new Date(),
      description: `Status for №${i}, ${item.name} went from '${item.status}' to '${event.detail.value}'`,
      employeeuid: this.supa.LoginObject.value.id
    })
    item.status = event.detail.value;
  }

  getUser(employeeuid) {
    if (this.userList) {
      if (this.userList.has(employeeuid)) {
        return this.userList.get(employeeuid);
      }
    }
    return {fullname:"?", employee_id:"?"}
  }

  @ConfirmDelete("Do you want to unassign this employee?")
  async removeEmp(item, index, i) {
    const eid = item.assignees[index].id;
    this.followUpData.history.entries.unshift({
      date: new Date(),
      description: `Employee removed from №${i}, ${this.getUser(eid).fullname}, ${this.getUser(eid).employee_id}`,
      employeeuid: this.supa.LoginObject.value.id
    })
    item.assignees.splice(index, 1)
  }

  async assignEmp(item, i) {
    let result = await this.helper.assignEmployeeToArray('', item.assignees)
    if (result) {
      this.followUpData.history.entries.unshift({
        date: new Date(),
        description: `Employee addded to №${i}, ${result.name}, ${this.getUser(result.id).employee_id}`,
        employeeuid: this.supa.LoginObject.value.id
      })
    }
  }

  async init() {
    this.userList = await this.supa.getUserList();
    console.log(this.userList);
    this.settings = await this.supa.Settings();
    let { data, error } = await this.supa.supabase.from('followup').select('*').eq('id', this.id).single()
    if (error) {
      this.helper.notify('Can not load follow up', 'danger');
      console.log(error);
      this.dismissModal()
      return;
    }

    let vehicleResult = await this.supa.supabase.from<Vehicle>('vehicle').select('*').eq('vin', data.vin).single();
    if (vehicleResult.error) {
      this.helper.notify('Can not load follow up', 'danger');
      console.log(error);
      this.dismissModal()
      return;
    }
    data.items.sort((a,b)=> ((new Date(a.date)).getTime() - (new Date(b.date)).getTime()) )
    this.followUpData = data;
    this.vehicle = vehicleResult.data;
  }

  openVehicle() {
    this.router.navigate(['/vehicles/editor',{vin: this.vehicle?.vin}])
    this.modalController.dismiss();
  }

  gotorecord() {
    this.router.navigateByUrl('vhc-record;id=' + this.followUpData.vhcrecord)
    this.modalController.dismiss();
  }

  async chooseDateTime(item) {
    let res = await this.helper.chooseDateTime({IsRange: false, WithTime: false, SingleDate: DateTime.fromJSDate(new Date(item.date))})
    console.log(res);
    if (res) {
      item.date = (res as DateTime).toJSDate();
    }
  }

  async save() {
    const loading = await this.loadingController.create({
      message: 'Saving follow up',
      spinner: 'bubbles'
    });
    await loading.present();
    this.followUpData.history.entries.unshift({
      date: new Date(),
      description: `Saved the followup`,
      employeeuid: this.supa.LoginObject.value.id
    })
    let { data, error } = await this.supa.supabase.from('followup').update(this.followUpData, { returning: 'minimal'}).eq('id', this.id)
    loading.dismiss();
    if (error) {
      this.helper.notify('Followup could not be saved');
      console.log(error); 
      this.followUpData.history.entries.slice(0, 1);
    } else {
      this.helper.promptYesNo('Followup was saved.', 'Do you want to close this modal?', () => {
        this.modalController.dismiss({saves: true});
      }, 'Close', 'Keep editing')
    }
  }

  async preview() {

    // const loading = await this.loadingController.create({
    //   message: 'Loading template',
    //   spinner: 'bubbles'
    // });
    // await loading.present();

    // let {data, error} = await this.supa.supabase.from("messagetemplate").select(this.followUpItem.islang1 ? 'followup1' : 'followup2').single()
    // if (error) {
    //   loading.dismiss()
    //   console.log(error);
    //   this.helper.notify('Template could not be loaded', 'danger');
    //   return
    // }

    // let vehicleData = await this.supa.supabase.from('vehicle').select('drivername,ownername,communicatewithowner').eq('vin', this.followUpItem.vin).single();
    // if (vehicleData.error) {
    //   console.log(error);
    //   loading.dismiss();
    //   this.helper.notify("Could not load vehicle information", "danger");
    //   return;
    // }

    // let table = "Risk|Name|Recommendation\n"
    // table    += "-|-|-\n"
    // this.followUpItem.items.forEach(i => {
    //   table += (i.value == "Red" ? 'High' : 'Medium') + "|" + (this.followUpItem.islang1 ? i.name : i.name2) + "|" + (this.followUpItem.islang1 ? i.recommendation : i.recommendation2) + "\n"
    // })

    // let msg = this.followUpItem.islang1 ? data.followup1 : data.followup2;
    // msg = msg.replace(/\$CUSTOMER\$/g, (vehicleData.data.communicatewithowner ? vehicleData.data.ownername : vehicleData.data.drivername))
    // msg = msg.replace(/\$VHCITEM\$/g, table)
    
    // loading.dismiss();

    // this.helper.createAndOpenModal(MarkdownDialog, {mdstring: msg, islang2: !this.followUpItem.islang1}, 'doubled-modal medium-modal');
  }

}
