import { Component, ViewChild } from '@angular/core';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobsComponent {

  @ViewChild('browser') browser: FilebrowserComponent

  constructor(private supa: SupaService, private helper: HelperService) {}

  async onClick(file: FileDisplayItem) {
    const loader = await this.helper.getLoader();
    loader.present();
    
    let singlerow = await this.supa.supabase.from('job').select('*').eq('id', file.id).single();
    loader.dismiss();

    let settings: JSettings = await this.supa.Settings();
    
    let {data} = await this.helper.presentNewEditDialog(
      'Edit job', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: singlerow.data.name },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: singlerow.data.name2 },
        { name: 'code', label: 'Labour code', type: 'text', value: singlerow.data.code },
        { name: 'price', label: 'Labour price ' + settings.lang1currency, type: 'number', value: singlerow.data.price },
        { name: 'hour', label: 'Labour hour', type: 'number', value: singlerow.data.hour },
        { name: 'vehiclevisuals', label: 'Video explanation', type: 'vehiclevisuals', value: singlerow.data.vehiclevisuals }
    ], file)
    if (data) {
      await this.supa.supabase.from('job').update(data.data).eq('id', file.id);
      this.browser.reload();
    }
  }

  async newFile(parent:number) {
    let settings: JSettings = await this.supa.Settings();

    let {data} = await this.helper.presentNewEditDialog(
      'Add new job', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: '' },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: '' },
        { name: 'code', label: 'Labour code', type: 'text', value: '' },
        { name: 'price', label: 'Labour price ' + settings.lang1currency, type: 'number', value: 0 },
        { name: 'hour', label: 'Labour hour', type: 'number', value: 0.5 },
        { name: 'vehiclevisuals', label: 'Video explanation', type: 'vehiclevisuals', value: '' }
    ])
    if (data) {
      data.data.parent = parent
      await this.supa.supabase.from('job').insert(data.data);
      this.browser.reload();
    }
  }

}
