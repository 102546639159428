import { Component, OnInit } from '@angular/core';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-toceditor',
  templateUrl: './toceditor.component.html',
  styleUrls: ['./toceditor.component.scss'],
})
export class ToceditorComponent implements OnInit {

  id = null
  lang1toc = undefined
  lang2toc = ``

  settings:JSettings = undefined;

  constructor(private supa: SupaService, public helper: HelperService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.settings = await this.supa.Settings();
    let {data, error} = await this.supa.supabase.from('toc').select('*').limit(1)
    if (error) {
      this.helper.notify('Could not load the data', 'danger');
      console.log(error);
    } else {
      if (data.length == 0) {
        await this.setDefault();
        this.lang1toc = ""
        this.lang2toc = ""
      } else {
        this.id = data[0].id
        this.lang1toc = data[0].lang1toc
        this.lang2toc = data[0].lang2toc
      }
    }
  }

  async setDefault() {
    let {error,data} = await this.supa.supabase.from('toc').insert({lang1toc: '', lang2toc: ''}).single()
    if (error) {
      this.helper.notify('Could not save a new entry in the database', 'danger');
      console.log(error)
    } else {
      this.helper.notify('A new entry has been made in the database', 'success');
      this.id = data.id;
    }
  }

  @ConfirmDelete('Press yes to save the terms & conditions', 'Save changes?')
  async onSave() {
    let {error} = await this.supa.supabase.from('toc').update({lang1toc: this.lang1toc, lang2toc: this.lang2toc}, {returning: 'minimal'}).eq('id', this.id)
    if (error) {
      this.helper.notify('Could not save the terms & conditions', 'danger');
      console.log(error)
    } else {
      this.helper.notify('The terms & conditions have been saved', 'success');
    }
  }

}
