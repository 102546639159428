/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from "@ionic/angular";
import { BehaviorSubject } from 'rxjs';
import { SupaService } from '../../../services/supa.service';
import { HelperService } from '../../../services/helper.service';
import { HttpClient } from '@angular/common/http';
import { DmsCarApiResult, DmsService } from 'src/app/services/dms.service';
import { ConfirmDelete } from 'src/app/decorators/confirmdelete.decorator';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-osbwizard',
    templateUrl: './osbwizard.component.html',
    styleUrls: ['./osbwizard.component.scss'],
})
export class OSBWizardDialog implements OnInit {

    currency = '~';

    currentStep = 0;
    isloading   = true;
    releaseDMS  = false;
    existingRecordId=-1;

    step0Data = []; //locations
    step1Data = {additionals:[],services:[],recalls:[]}; //jobs
    step2Data = []; //timeslots

    step0Result: any = -1; // selected location
    step0Vin = '';//'JM5TCAWY0K0302221';//'JM7BK226751109109'; //JM5TCAWY0K0302221 <- has contracts
    step0PhoneNumber = '';
    step0LicensePlate = '';
    step0Model = '';
    step0CustomerName = '';

    step1Result = []; // selected jobs
    step2Result = ''; // selected timeslot

    selectedService = '0';
    selectedDiagnose = '0';
    selectedAdditionals = [];
    customDiagnoses=[];
    specification={chosen:null, specs:[]}

    constructor(private modalCtrl: ModalController,
        private supa: SupaService,
        private http: HttpClient,
        private dms: DmsService,
        private alertController: AlertController,
        private helper: HelperService,
        private loadingController: LoadingController) {}

    ngOnInit() {
        this.load0Data();
        this.supa.Settings().then(s => {
            this.currency = s.lang1currency;
        });
        console.log(this.supa.LoginObject.getValue());
    }

    async load0Data() {
        this.isloading = true;
        const locationdata: any = await this.http.get(`${environment.osbUrl}/api/customer/locations`).toPromise();
        this.step0Data = locationdata;

        console.log('Figure location out');
        for (const perm of this.supa.LoginObject.getValue()?.permissions) {
            console.log(perm);
            if (perm.startsWith('Location')) {
                console.log('Is a location');
                const prefloc = perm.substring(8);
                for (const d of locationdata) {
                    console.log(d.locationCode, prefloc);
                    if (d.locationCode === prefloc) {
                        this.step0Result = d;
                        console.log('Jo eem');
                        break;
                    }
                }
                break;
            }
        }

        this.isloading = false;
    }

    async checkJobtype() {

    }

    async load1Data() {
        console.log(this.step0Result);
        this.isloading = true;
        this.selectedService = '0';
        this.selectedDiagnose = '0';
        if (!this.step0Result.id) { this.goToStep(0); return; };
        const servicedata: any = await this.http.get(`${environment.osbUrl}/api/customer/services?locationid=${this.step0Result.id}&carline=${this.step0Vin.substring(3,5)}&specification=&vin=${this.step0Vin}`).toPromise();
        const contracts = await this.dms.getDMS_SERVICEPLANS(this.step0Vin);
        servicedata['contracts'] = contracts.items.map(c => {
            return {
                'carLine' : this.step0Vin.substring(3,5),
                'id' : 275,
                'kms' : 0,
                'realCode' : c.operation_id,
                'realDuration' : c.std_time * 60,
                'realPrice' : "0",
                'service_arb' : c.operation_desc + ' - ' + c.contract_no,
                'service_eng' : c.operation_desc + ' - ' + c.contract_no,
                'specification' : ""
            };
        });

        this.step1Data = servicedata;
        this.isloading = false;
    }

    async load2Data() {
        this.isloading = true;
        const now = new Date();
        for(let monthOffset=0; monthOffset<12; monthOffset++){
            const body = {
                fromCustomer: false,
                month: ((now.getMonth() + monthOffset)%12)+1,
                year: now.getFullYear()+(+((now.getMonth() + monthOffset)>=12)),
                slotMinutes: this.step0Result.slotMinutes,
                specificReceptionistId: 'no',
                timeCap: this.step0Result.timeCap,
                jobs: this.step1Result.map(t => ({
                    duration: t.service_eng !== undefined ? t.realDuration : t.duration,
                    code: t.service_eng !== undefined ? t.realCode : t.operationCode
                })),
            };
            const timeslots: any = await this.http.post(this.step0Result.apiUrlAvailability, body).toPromise();
            this.step2Data =  timeslots.filter(t => new Date(t)>=now);
            if (this.step2Data.length>0)
                break;           
        }
        if(this.step2Data.length==0){
            return;
        }
        this.isloading = false;
        this.step2Result = this.step2Data[0]
    }

    async confirmBooking() {
        const loader = await this.helper.getLoader();
        await loader.present();

        const service = this.step1Result.find(s => s.service_eng !== undefined);
        const arrivalDate = new Date(this.step2Result);
        const arrival = Math.round(arrivalDate.getTime() / 1000);
        const jobs = this.step1Result.map(r => ({
            operationCode: r.service_eng !== undefined ? r.realCode : r.operationCode,
            duration: r.service_eng !== undefined ? r.realDuration : r.duration,
            name: r.service_eng !== undefined ? r.service_eng : r.name,
            price: r.service_eng !== undefined ? r.realPrice : r.price
        }));

        const body = {
            appointmentCancelled: false,
            handoverTime: 0,
            brand: 'Mazda',
            cancelDate: null,
            creationTextSent: 0,
            reminderTextSent: 0,
            receptionist: '',
            fromCustomer: false,
            withUser: this.supa.LoginObject.getValue()?.employee_id ? this.supa.LoginObject.getValue()?.employee_id : '',
            hhaService: service ? service.service_eng : 'other',
            kms: service ? service.kms : '0',
            seenFolder: false,
            arrivalTime: arrival,
            arrivalTimeChosen: arrivalDate.getDate().toString().padStart(2, '0') + '-' + (arrivalDate.getMonth()+1).toString().padStart(2, '0')+ '-' +arrivalDate.getFullYear().toString() + ' ' + this.step2Result.substring(11,16),
            customerAddress: '',
            customerCompany: '',
            customerEmail: '',
            customerName: this.step0CustomerName,
            customerPostalCode: '',
            phoneNumber: this.step0PhoneNumber,
            licensePlate: this.step0LicensePlate,
            model: this.step0Model,
            vin: this.step0Vin,
            locationCode: this.step0Result.locationCode,
            jobs,
            language: 'en',
            hhaNote: '(IQVHC) booked with vhc wizard',
            SendClientSMS:false,
            hhaAutoRemark: jobs.map(j => j.name).join(',')
        };

        const result: any = await this.http.post(`${environment.osbUrl}/api/customer/newappointment`, body).toPromise();
        console.log(result);
        loader.dismiss();

        if (result.id) {
            this.helper.notify('Appointment was created, appontment id is ' + result.id);
            this.modalCtrl.dismiss({osbid:result.id, vin:this.step0Vin});
        } else {
            this.helper.notify('Appointment was probably not created', 'warning');
        }
    }

    @ConfirmDelete("An appointment already exists. Do you want to delete it?")
    delete(exists){
        const _time = DateTime.utc().toSeconds().toString();
        const left = (Number.parseInt(_time.substr(0,5))*2).toString();
        const right = (Number.parseInt(_time.substring(5))*2).toString();
        let withUser    = ""
        let id          = exists.id
        let code        = "99999"
        let phone       = exists.o
        return this.http.get(`${environment.osbUrl}/api/customer/deleteappointment?idVal=${left}.${id*2}.${right}&withUser=${withUser}&code=${code}&o=${phone}&sendclientsms=${false}`).toPromise();

    }

    async findVehicleDMS() {

        const exconf = await this.supa.ExternalConfig();

        let loading = await this.loadingController.create({
            message: 'Checking if there are appoinments for this VIN',
            spinner: 'bubbles',
            backdropDismiss: true
        });
        loading.present()
        this.http.get(`${environment.osbUrl}/api/customer/appointmentexists?vin=${this.step0Vin}`).toPromise().then(async x=>{
            loading.dismiss()
            if(x['appointmentExists']){
                await this.delete(x)
            }
            if (exconf.name === 'Keyloop') {
                loading =  await this.loadingController.create({
                    message: 'Checking if there are appoinments for this VIN',
                    spinner: 'bubbles',
                    backdropDismiss: true
                });
                await loading.present()
                const keyloopvehicle = await this.supa.supabase.rpc('get_vehicle_info', {vin: this.step0Vin});
                if ((keyloopvehicle as any)?.body?.identification?.licensePlate) {
                    this.step0LicensePlate = (keyloopvehicle.body as any).identification.licensePlate;
                    this.step0Model = (keyloopvehicle.body as any).baseDetails.description + ' ' + (keyloopvehicle.body as any).baseDetails.exteriorColor;
                    loading.dismiss()
                } else {
                    loading.dismiss()
                    this.helper.notify('Vehicle is not in the DMS', 'danger');
                    return false;
                }
            } else if (exconf.name === 'HHA') {
                const vehicleresult: DmsCarApiResult = await this.dms.getDMS_VEHICLE(this.step0Vin);
                console.log(vehicleresult);
                if (vehicleresult.items.length === 0) {
                    this.helper.notify('Vehicle is not in the DMS', 'danger');
                    return false;
                }
                this.step0PhoneNumber = vehicleresult.items[0].phonenumber;
                this.step0LicensePlate = vehicleresult.items[0].licenseplate;
                this.step0Model = vehicleresult.items[0].model;
                this.step0CustomerName = vehicleresult.items[0].customer_name;
            } else {
                this.helper.notify('DMS search not implemented', 'danger');
            }
        });
    }

    async findRecord(event){
        if(event.target.value.length==17){
            this.carSpecification(event.target.value)
            const vehiclecheck = await this.supa.supabase.from('vhcrecord').select('id').eq('vin', event.target.value).eq('isdone', false);
            if (vehiclecheck.data.length>0) {
                this.releaseDMS         = false;
                this.existingRecordId   = vehiclecheck.data[0].id
                return;
            }
            this.existingRecordId =-1
            this.releaseDMS=true;
            console.log("record not present")
        }else{
            this.specification.chosen=null;
            this.releaseDMS=false;
        }
    }

    gotoRecord(){
        this.modalCtrl.dismiss({gotorecord: this.existingRecordId})
    }

    async findVehiclePhone() {
        console.log('findVehiclePhone');

        const loader = await this.helper.getLoader();
        await loader.present();
        const getresult: any = await this.http.get('https://m.hha.com.sa/ords/hhaapi/osb/cardata/?phonenumber=' + this.step0PhoneNumber, {headers: {
            Authorization: 'Basic ' + window.btoa('hhaprodrestuser:hhaprodrestpass1')
        }}).toPromise();
        loader.dismiss();
        if (!getresult.items) {
            this.helper.notify('No contact with the DMS', 'danger');
            return
        }
        const result = getresult.items;

        if (result.length === 0) {
            this.helper.notify('Vehicle is not in the DMS', 'danger');
            this.releaseDMS=false;
            return false;
        }
        let idx = 0;
        if (result.length > 1) {
            const alert = await this.alertController.create({
                header: 'Choose vehicle',
                subHeader: 'Please choose the correct vehicle',
                buttons: [
                    ...result.map((r, i) => ({ text: r.model, role: i })),
                    { text: 'Cancel', role: 'cancel' }
                ]
            });
            await alert.present();
            const ans = await alert.onDidDismiss();
            idx = Number.parseInt(ans.role, 10);
        }

        if (Number.isInteger(idx) && idx >= 0 && idx < result.length) {
            this.step0Vin = result[idx].vin;
            this.step0LicensePlate = result[idx].licensePlate ? result[idx].licensePlate : result[idx].licenseplate;
            this.step0Model = result[idx].model;
            this.step0CustomerName = result[idx].customer_name;
            this.releaseDMS=true;
        } else {
            this.releaseDMS=false;
        }
    }

    changeService(event: any) {
        const sidx = this.step1Result.findIndex(s => s.service_eng !== undefined);
        if (sidx >= 0) {
            this.step1Result.splice(sidx, 1);
        }
        if (event.detail.value !== '0') {
            this.step1Result.unshift(event.detail.value);
        }
    }

    changeDiagnose(event: any) {
        const sidx = this.step1Result.findIndex(s => s.service_eng === undefined && s.isConcern);
        if (sidx >= 0) {
            this.step1Result.splice(sidx, 1);
        }
        if (event.detail.value !== '0') {
            this.step1Result.push(event.detail.value);
        }
    }

    changeAdditional(event: any) {
        this.step1Result = this.step1Result.filter(s => !(s.service_eng === undefined && !s.isConcern));
        this.step1Result.push(...event.detail.value);
    }

    changeCustomDiagnose(event:any){
        const sidx = this.step1Result.findIndex(s => s.service_eng === undefined && s.isConcern);
    }

    removeCustomDiagnose(idx: number){
        this.customDiagnoses.splice(idx, 1);
    }

    addCustomDiagnose(){
        this.customDiagnoses.push({name:"", operationCode:"HHADIG001",duration: 30, nameAr:"" });
    }

    dismissModal() {
        this.modalCtrl.dismiss();
    }

    stepAvailable(step) {
        if (step === 0) {
            return true;
        }
        if (step === 1) {
            return this.step0Result !== -1 &&
                this.step0Vin !== '' &&
                this.step0PhoneNumber !== '' &&
                this.step0LicensePlate !== '' &&
                this.step0Model !== '' &&
                this.step0CustomerName !== '' &&
                this.stepAvailable(step - 1);
        }
        if (step === 2) {
            return true;
        }
    }

    async goToStep(step: number) {
        console.log(step);
        if (this.stepAvailable(step)) {
            if (step === 0) {
                this.step1Result = [];
                this.step2Result = '';
            }
            if (step === 1) {
                if (this.currentStep === 0) {
                    const existing: any = await this.http.get(`${environment.osbUrl}/api/customer/appointmentexists?vin=` + this.step0Vin).toPromise();
                    if(existing.appointmentExists) {
                        await this.delete(existing)
                    }
                }
                
                this.step1Result = [];
                this.step2Result = '';
                this.load1Data();
            }
            if (step === 2) {
                if(this.customDiagnoses.filter(x=>x.name=="").length>0){
                    this.helper.notify('Please provide all customer diagnoses with a primary name', 'danger');
                    return;
                }else{
                    this.step1Result.push(...this.customDiagnoses)
                    await this.load2Data() 
                    if(this.step2Data.length>0){
                        this.confirmBooking()
                    }else{
                        this.helper.notify('No timeslot is available for this record', 'danger');
                        this.isloading=false;
                        this.goToStep(1);
                        return;
                    }
                }
            }
            this.currentStep = step;
        }
    }

    public carSpecification(vin, spec=null){
        const four_wheel_drive  = ["GL"]
        const turbo             = ["TB", "DK"]
        const two_onesix_liter  = ["BN", "BM", "BL", "BK"]
        const carline           = vin.substring(3,5).toUpperCase()
        if(four_wheel_drive.includes(carline)){
            this.specification.specs= [{label:"Four wheel drive", spec:"4WD"}, {label:"Two wheel drive", spec:"2WD"}]
        }else if (turbo.includes(carline)){    
            this.specification.specs=  [{label:"Turbo", spec:"WithTurbo"}, {label:"No turbo", spec:"WithoutTurbo"}]
        }else if (two_onesix_liter.includes(carline)){
            this.specification.specs=  [{label:"2.0 liter", spec:"2.0L"}, {label:"1.6 liter", spec:"1.6L"}]
        }else{
            this.specification.specs=[]
        }
    }
}
