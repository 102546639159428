import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as pdfMake from "pdfmake/build/pdfmake";
import { HelperService } from '../../../../services/helper.service';
import { SupaService } from '../../../../services/supa.service';
import { pdfMake_vfs, pdfmake_fonts } from '../../../../vfs_fonts';

(<any>pdfMake).vfs = pdfMake_vfs;
(<any>pdfMake).fonts = pdfmake_fonts;

@Component({
  selector: 'app-quotationlayouteditor',
  templateUrl: './quotationlayouteditor.component.html',
  styleUrls: ['./quotationlayouteditor.component.scss'],
})
export class QuotationlayouteditorComponent implements OnInit {

  loadingDone = undefined
  qId = undefined

  bgChanged = false;

  // template settings
  name = "New quote"
  margintop = 120
  rightCustomerAlign = true
  rightApprovalAlign = true
  background = null
  marginModifier = 1.0
  maxlines = 3
  selectedfont = 'ubuntu'
  daysvalid = 30
  disclaimer = 'This estimate is an approximation and is not guaranteed. The estimate is based on information provided by the client regarding project requirements. Actual cost may change once all project elements are finalized or negotiated. Prior to any changes of cost, the client will be notified. Estimate valid for 30 days.'
  
  // Fake details
  customerDetails1 = ""
  customerDetails2 = ""
  customerDetails3 = ""
  quotenumber = ""
  createdate = ""
  validdate = ""

  pdfblob = undefined

  constructor(private helper: HelperService, private supa: SupaService, private route: ActivatedRoute, private router: Router) { }

  onBack() {
    this.helper.back();
  }

  async onDelete() {
    if(!this.qId) {
      this.helper.notify("This has never been saved yet", "warning");
      return
    }
    let { data, error } = await this.supa.supabase.from('quotationlayout').delete().eq('id', this.qId);
    if (error) {
      this.helper.notify("Could not delete the layout", "danger");
      return;
    }
    this.router.navigate(['/settings/quotationlayout/'])
  }

  async onSave() {
    let newData = {
      name: this.name,
      margintop: this.margintop,
      rightcustomeralign: this.rightCustomerAlign,
      rightapprovalalign: this.rightApprovalAlign,
      marginmodifier: this.marginModifier,
      maxlines: this.maxlines,
      selectedfont: this.selectedfont,
      daysvalid: this.daysvalid,
      disclaimer: this.disclaimer
    }
    if (this.bgChanged) {
      newData["background"] = this.background
    }
    if (this.qId) {
      let { data, error } = await this.supa.supabase.from('quotationlayout').update(newData).eq('id', this.qId);
      if (error) {
        this.helper.notify("Could not save the layout", "danger");
        return;
      }
    } else {
      let { data, error } = await this.supa.supabase.from('quotationlayout').insert(newData).single()
      if (error) {
        this.helper.notify("Could not save the layout", "danger");
        return;
      }
      this.qId = data.id
    }
    this.helper.promptYesNo('Layout was saved.', 'Dow you want to leave the editor?', () => {
      this.router.navigate(['/settings/quotationlayout/'])
    }, 'Leave editor', 'Keep editing')
    this.bgChanged = false;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params, Object.keys(params));
      if (Object.keys(params).includes('id')) {
        this.qId = params.id;
        this.loadLayout();
      } else {
        this.loadingDone = true
        setTimeout(() => {
          this.populatePdf();
        }, 2000)
      }
    });
    this.randomDetails();
  }

  async loadLayout() {
    let { data, error } = await this.supa.supabase.from('quotationlayout').select("*").eq('id', this.qId).single();
    if (error) {
      this.helper.notify("Could not load the layout", "danger");
      this.router.navigate(['/settings/quotationlayout/'])
      return;
    }
    this.name = data.name;
    this.margintop = data.margintop;
    this.rightCustomerAlign = data.rightcustomeralign;
    this.rightApprovalAlign = data.rightapprovalalign;
    this.background = data.background;
    this.marginModifier = data.marginmodifier;
    this.maxlines = data.maxlines;
    this.selectedfont = data.selectedfont;
    this.daysvalid = data.daysvalid;
    this.disclaimer = data.disclaimer;
    this.loadingDone = true;

    setTimeout(() => {
      this.populatePdf();
    }, 2000)
  }

  populatePdf() {
    this.pdfblob = undefined;
    this.randomDetails();
    const pdfDocGenerator = pdfMake.createPdf(this.generatePdf());
    pdfDocGenerator.getBase64((data) => {
      this.pdfblob = 'data:application/pdf;base64,' + data
    });
  }

  downloadPdf() {
    const pdfDocGenerator = pdfMake.createPdf(this.generatePdf());
    pdfDocGenerator.download();
  }

  randomDetails() {
    this.customerDetails1 = this.fnames[Math.round(Math.random() * (this.fnames.length - 1))] + " " + this.lnames[Math.round(Math.random() * (this.lnames.length - 1))]
    this.customerDetails2 = this.roadnames[Math.round(Math.random() * (this.roadnames.length - 1))] + this.roadendings[Math.round(Math.random() * (this.roadendings.length - 1))] + ' ' + Math.round(Math.random() * 200)
    this.customerDetails3 = Math.round(Math.random() * 1111) + " " + this.alphabet[Math.round(Math.random() * (this.alphabet.length - 1))] + this.alphabet[Math.round(Math.random() * (this.alphabet.length - 1))]
    this.customerDetails3 += ' ' + this.cities[Math.round(Math.random() * (this.cities.length - 1))]
    const d = new Date();
    this.quotenumber = Math.round((Math.random() * 8) + 1).toString() + d.getHours().toString().padStart(2, '0') + d.getFullYear() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0')
    this.createdate = d.getFullYear() + '/' + d.getMonth().toString().padStart(2, '0') + '/' + d.getDate().toString().padStart(2, '0')
    const t = new Date(d.getTime() + 30 * 24 * 60 * 60 * 1000)
    this.validdate = t.getFullYear() + '/' + t.getMonth().toString().padStart(2, '0') + '/' + t.getDate().toString().padStart(2, '0')
  }

  generatePdf() {
    const noBorder = [false, false, false, false]
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [40, this.margintop, 40, 60],
      background: function (currentPage, pageSize) {
        console.log(pageSize);
        return {
          image: 'myBack',
          width: pageSize.width,
          height: pageSize.height
        }
      },
      content: [
        { text: this.customerDetails1, alignment: this.rightCustomerAlign ? 'right' : 'left' },
        { text: this.customerDetails2, alignment: this.rightCustomerAlign ? 'right' : 'left' },
        { text: this.customerDetails3, alignment: this.rightCustomerAlign ? 'right' : 'left' },
        { text: 'QUOTE Q-' + this.quotenumber, alignment: 'center', bold: true, fontSize: 20, margin: 50 * this.marginModifier },
        {
          layout: 'lightHorizontalLines',
          margin: [0, 0, 0, 20 * this.marginModifier],
          table: {
            body: [
              ['Date', this.createdate],
              ['Valid until', this.validdate],
              ['Quote number', this.quotenumber]
            ]
          }
        },
        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', '*', 'auto', 'auto'],
            body: [
              ['Count', 'Code', 'Description', 'Total', 'Tax'],
              ['1', 'iqp', 'Fantastic planning tool', { text: '€189.99', alignment: 'right' }, '21%'],
              ['1', 'iqosb', 'Online booking tool', { text: '€75.00', alignment: 'right' }, '21%'],
              ['1', 'iqmobile', 'Mobile app', { text: '€6.50', alignment: 'right' }, '21%'],
              // Totals
              [{ colSpan: 3, italics: true, text: 'Total', alignment: 'right', border: noBorder }, '', '', { text: '€271.49', alignment: 'right', italics: true, border: noBorder }, { text: '', border: noBorder }],
              [{ colSpan: 3, italics: true, text: 'Tax 21% (€271.49)', alignment: 'right', border: noBorder }, '', '', { text: '€57.013', alignment: 'right', italics: true, border: noBorder }, { text: '', border: noBorder }],
              [{ colSpan: 3, bold: true, text: 'Amount to be paid', alignment: 'right', border: noBorder }, '', '', { text: '€328.50', alignment: 'right', bold: true }, { text: '', border: noBorder }]
            ]
          }
        },
        { text: this.disclaimer, alignment: 'center', fontSize: 10, italics: true, margin: [0, 50 * this.marginModifier, 0, 40 * this.marginModifier] },
        { text: 'For approval:', alignment: this.rightApprovalAlign ? 'right' : 'left', fontSize: 10, margin: [0, 0, 0, 40 * this.marginModifier] },
        { text: '----------------------', alignment: this.rightApprovalAlign ? 'right' : 'left', fontSize: 10 },
        { text: this.customerDetails1, alignment: this.rightApprovalAlign ? 'right' : 'left', fontSize: 10 },
      ],
      images: {
        myBack: this.background == undefined || this.background == null ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=' : this.background,
      },
      defaultStyle: {
        font: this.selectedfont,
        fontSize: 11
      }
    }
    return docDefinition
  }

  uploadPicture(event) {
    if (event.target.files == undefined) {
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (event.target.files[0] == undefined) {
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (event.target.files[0].type == undefined) {
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (!event.target.files[0].type.includes("image")) {
      this.helper.notify("Please upload an image", 'warning')
      return;
    }
    if (event.target.files[0].size == undefined) {
      this.helper.notify("Please upload an image", 'warning')
      return;
    }

    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(file);
      this.background = reader.result.toString();
      this.bgChanged = true;
      this.helper.notify("Loaded background successfully", 'success');
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.helper.notify("Could not load image", 'danger')
    };
  }

  fnames = ["Renee", "Rohaan", "Wade", "Harvir", "Sorcha", "Rachael", "Kailan", "Mia-Rose", "Krisha", "Manpreet"]
  lnames = ["Hurst", "Carver", "Matthams", "Brett", "Gutierrez", "Stuart", "Drew", "Beaumont", "Simmonds", "Herring"]
  roadendings = [" lane", " street", ""]
  roadnames = ["Gothse", "Gibsonfirebreatha", "Greenpayne", "Berryhowla Barneclaw", "Pointydaniels Grangriffiths", "Jenkinininki", "Dobanderson", "Drogcole", "Hahugia", "Zimbradley", "Pixlane Bateswood", "Countess Shary Woofang", "Gra-in-the-Green", "Harrificent", "Goliread", "Keblex", "Phillipsskelisia", "Munozow Pricedall", "Evansdelia", "Scro'reilly"]
  cities = ["Greenland", "Tallwich", "Greenfield", "Saydale Falls", "New Hapford", "North Waterford", "Great Beachdol", "Sandkarta", "Hapmouth", "Goldworth", "Eggkarta", "Costsville Beach", "Hallside", "Costston", "Costsmouth Falls", "Stonestead", "Postview", "Hardville", "Millburg", "Angerford", "Goldburg", "Medcaster", "Buoycester", "Lunstead"]
  alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
}