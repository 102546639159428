import { Component, OnInit } from '@angular/core';
import { ConfirmDelete } from 'src/app/decorators/confirmdelete.decorator';
import { HelperService } from 'src/app/services/helper.service';
import { VincouplereditComponent } from './vincoupleredit/vincoupleredit.component';
import { SupaService } from 'src/app/services/supa.service';

@Component({
  selector: 'app-vincoupler',
  templateUrl: './vincoupler.component.html',
  styleUrls: ['./vincoupler.component.scss'],
})
export class VincouplerComponent implements OnInit {

  couplers: VinCouple[] = [];
  loading = true;

  constructor(private helper: HelperService, private supa: SupaService) { }

  ngOnInit() {
    this.supa.supabase.from('vincoupler').select('*').order('sort').then(v => {
      if (v.error) {
        this.helper.notify('Could not load the couplers', 'danger');
        console.log(v.error);
      } else {
        this.couplers = v.data;
        this.loading = false;
      }
    });
  }

  async add() {
    const c: VinCouple = {folder: '', sort: this.couplers.length, vinfilter: ''};
    const result = await this.helper.createAndOpenModal(VincouplereditComponent, {couple: c}, 'big-modal');
    if (result.data?.couple) {
      this.couplers.push(result.data.couple);
    }
  }

  async edit(index: number) {
    const result = await this.helper.createAndOpenModal(VincouplereditComponent, {couple: this.couplers[index]}, 'big-modal');
    if (result.data?.couple) {
      this.couplers[index] = result.data.couple;
    }
  }

  @ConfirmDelete()
  remove(index: number) {
    this.couplers.splice(index, 1);
  }

  doReorder(ev: any) {
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    this.couplers = ev.detail.complete(this.couplers);
  }

  async save() {
    const loader = await this.helper.getLoader();
    loader.message = 'Removing previous values, please wait';
    loader.present();

    await this.supa.supabase.from('vincoupler').delete({returning: 'minimal'}).neq('id', 0);

    this.couplers.forEach(async (v,i)=>{
      loader.message = `Adding new values ${i+1} / ${this.couplers.length}, please wait`;
      v.sort = i;
      await this.supa.supabase.from('vincoupler').insert(v, {returning: 'minimal'});
    });

    loader.dismiss();
    this.helper.notify('Saved successfully', 'success');
  }

}

export interface VinCouple {
  sort: number;
  folder: string;
  vinfilter: string;
}
