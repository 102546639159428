import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-datetimechooser',
  templateUrl: './datetimechooser.component.html',
  styleUrls: ['./datetimechooser.component.scss'],
})
export class DatetimechooserDialog implements OnInit {

  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;

  @Input("date") datevalue: Date = new Date();
  @Input("dateOnly") dateOnly = false;

  clickedDone = false;

  startValue = "1992-01-24T23:47:20.789+02:00"; //"YYYY-MM-DDTHH:mm:ssTZD"

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.startValue = 
      this.datevalue.getFullYear() + '-' + 
      (this.datevalue.getMonth()+1).toString().padStart(2, '0') + '-' + 
      this.datevalue.getDate().toString().padStart(2, '0') + 'T' + 
      this.datevalue.getHours().toString().padStart(2, '0') + ':' +
      this.datevalue.getMinutes().toString().padStart(2, '0');
  }

  cancel() {
    this.modalController.dismiss();
  }

  onDTChanged(e) {
    if (this.clickedDone) {
      let d = new Date(e.detail.value)
      if (this.dateOnly) {
        d.setMilliseconds(0);
        d.setSeconds(0);
        d.setMinutes(0);
        d.setHours(12);
      }
      this.modalController.dismiss({date: d});
    }
  }
  
  async done() {
    this.clickedDone = true;
    this.datetime.confirm()
  }

}
