import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthObject } from '../../../interfaces/authobject.interface';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';
import { VhcBrakeModule } from '../../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcCross_sellModule } from '../../../vhcmodules/cross_sellmodule/cross_sellmodule';
import { VhcQuestionblockModule } from '../../../vhcmodules/questionblock/vhcquestionblockmodule';
import { VhcTyreModule } from '../../../vhcmodules/tyremodule/vhctyremodule';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';

@Component({
  selector: 'app-authobjview',
  templateUrl: './authobjview.dialog.html',
  styleUrls: ['./authobjview.dialog.scss'],
})
export class AuthobjviewDialog implements OnInit {

  @Input('premodules') premodules: Vhcmodule[]
  @Input('mpimodules') mpimodules: Vhcmodule[]
  @Input('qimodules') qimodules: Vhcmodule[]
  @Input('authobjs') authobjs: AuthObject[] = []

  modulelists = undefined

  settings: JSettings = undefined

  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService) {

  }

  currentDatetime=''

  setModules() {
    this.modulelists = []
    const modulePredicate = ((module:Vhcmodule) => {
      if (module.type == 'Questionblock') {
        return (module as VhcQuestionblockModule).questions.findIndex(v => v.dateAuthorised == this.currentDatetime) >= 0
      } else if (module.type == 'Tyres') {
        return (module as VhcTyreModule).data.tyres.findIndex(v => v.dateAuthorised == this.currentDatetime) >= 0
      } else if (module.type == 'Brakes') {
        return (module as VhcBrakeModule).data.brakes.findIndex(v => v.dateAuthorised == this.currentDatetime) >= 0
      } else if (module.type == 'Cross_sell') {
        return (module as VhcCross_sellModule).data.findIndex(v => v.dateAuthorised == this.currentDatetime) >= 0
      } else {
        return module.dateAuthorised == this.currentDatetime
      }
    });
    this.authobjs.forEach(authobj => {
      this.currentDatetime = authobj.datetime;
      this.modulelists.push([
        ...this.premodules.filter(modulePredicate),
        ...this.mpimodules.filter(modulePredicate),
        ...this.qimodules.filter(modulePredicate)
      ])
    }); 
  }

  filteredTyres(tyres, datetime) {
    return tyres.filter(t => t.dateAuthorised == datetime)
  }
  
  filteredBrakes(brakes, datetime) {
    return brakes.filter(t => t.dateAuthorised == datetime)
  }

  filteredCross_sellItems(cross_sell_items, datetime) {
    return cross_sell_items.filter(t => t.dateAuthorised == datetime)
  }

  openLightBox(images) {
    this.helper.openLightBox(images)
  }

  ngOnInit() {
    this.loadSettings();
    this.setModules();
  }
  
  async loadSettings() {
    this.settings = await this.supa.Settings();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

}
