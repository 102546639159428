import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { Vhcmodule } from '../vhcmodule';
import { MediaAnswerDialog } from './mediaanswer/mediaanswer.dialog';

export class VhcMediaModule extends Vhcmodule
{
    includeinsignature = false;

    data = {
        videos: [],
        images: []
    };

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = 'Media';
        this.name = 'Capture media';
    }

    async openAnswer(param: any) {
        await this.loadSettings();

        const {data} = await this.helper.createAndOpenModal(MediaAnswerDialog, {
            module: this,
        }, 'doubled-modal tall-modal');

        return data;
    }

    public getIcon(): string {
        return this.data?.videos?.length > 0 || this.data?.images?.length > 0 ? 'checkbox-outline' : 'square-outline';
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, data: this.data, type: this.type, dateAuthorised: this.dateAuthorised};
    }

    public fromDatabase(obj, includedata=false) {
        this.name = obj.name;
        this.name2 = obj.name2;
        if (includedata) {
            this.data = obj.data;
            if (this.data === undefined) { this.data = {videos:[], images: []}; }
            else {
                if (!this.data.hasOwnProperty('images')) { this.data.images = []; }
                if (!this.data.hasOwnProperty('videos')) { this.data.videos = []; }
            }
        }
        this.dateAuthorised = obj.dateAuthorised;
    }

    getHtmlVersion(param: any=false,primarylang=false) {
        let atleastone = false;
        let htmlBody = `<p>${primarylang ? this.name : this.name2}</p>`;
        this.data.images.forEach(i => {
            atleastone = true;
            htmlBody += `<img src="${i}" style="width: 250px;height: 250px;object-fit: contain;margin-right: 10px;object-position: center;border: 2px solid #ccc;" />`;
        });
        htmlBody += '<br /><br />';
        return atleastone ? htmlBody : '';
    }
}
