import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FiledisplayComponent, FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-brakediscpads',
  templateUrl: './brakediscpads.component.html',
  styleUrls: ['./brakediscpads.component.scss'],
})
export class BrakediscpadsComponent implements AfterViewInit {

  @ViewChild('fdisplay') fdisplay: FiledisplayComponent
  
  listMode=false
  
  constructor(private alertController: AlertController, private supa: SupaService, private helper: HelperService) {}
  
  ngAfterViewInit() {
    this.init()
  }

  async init() {
    this.fdisplay.setLoading();
    let { data, error } = await this.supa.supabase.from('brakediscpad').select('id,name').order('name');
    if (error) {
      console.log(error);
      this.helper.notify('Could not load the data', 'danger');
    } else {
      this.fdisplay.setFiles(data.map(i => {
        return {
          isFolder: false,
          category: 'brakediscpad',
          id: i.id,
          name: i.name
        }
      }))
    }
  }

  async deleteItem(item: FileDisplayItem) {
    this.fdisplay.setLoading();
    this.supa.supabase.from('brakediscpad').delete().eq('id', item.id).then(v => {
      if (v.error) {
        this.helper.notify('Could not delete brake & disc pad set', 'danger');
      } else {
        this.helper.notify('Brake & disc pad set was deleted', 'success');
      }
      this.init()
    })
  }

  async onClick(file: FileDisplayItem) {
    const loader = await this.helper.getLoader();
    loader.present();
    
    let singlerow = await this.supa.supabase.from('brakediscpad').select('*').eq('id', file.id).single();
    loader.dismiss();
    
    let {data} = await this.helper.presentNewEditDialog(
      'Edit brake & disc pad set', [
        { name: 'name', label: 'Axel name', type: 'text', value: singlerow.data.name },
        { name: 'discamberwidthmm', label: 'Disc Amber width (mm)', type: 'number', value: singlerow.data.discamberwidthmm },
        { name: 'discredwidthmm', label: 'Disc Red width (mm)', type: 'number', value: singlerow.data.discredwidthmm },
        { name: 'padamberwidthmm', label: 'Pad Amber width (mm)', type: 'number', value: singlerow.data.padamberwidthmm },
        { name: 'padredwidthmm', label: 'Pad Red width (mm)', type: 'number', value: singlerow.data.padredwidthmm },
    ], file)
    if (data) {
      await this.supa.supabase.from('brakediscpad').update(data.data).eq('id', file.id);
      this.init()
    }
  }

  async onAdd() {
    let {data} = await this.helper.presentNewEditDialog(
      'Add new brake & disc pad set', [
        { name: 'name', label: 'Axel name', type: 'text', value: '' },
        { name: 'discamberwidthmm', label: 'Disc Amber width (mm)', type: 'number', value: 25 },
        { name: 'discredwidthmm', label: 'Disc Red width (mm)', type: 'number', value: 23 },
        { name: 'padamberwidthmm', label: 'Pad Amber width (mm)', type: 'number', value: 9 },
        { name: 'padredwidthmm', label: 'Pad Red width (mm)', type: 'number', value: 7 },
    ])
    if (data) {
      await this.supa.supabase.from('brakediscpad').insert(data.data);
      this.init();
    }
  }

}
