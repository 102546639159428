import { Component, ViewChild } from '@angular/core';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-cross_sell',
  templateUrl: './cross_sell.component.html',
  styleUrls: ['./cross_sell.component.scss'],
})
export class Cross_sellComponent {

  @ViewChild('browser') browser: FilebrowserComponent;

  constructor(private supa: SupaService, private helper: HelperService) {}

  async onClick(file: FileDisplayItem) {
    const loader = await this.helper.getLoader();
    loader.present();
    
    let singlerow = await this.supa.supabase.from('cross_sell').select('*').eq('id', file.id).single();
    loader.dismiss();

    let settings: JSettings = await this.supa.Settings();
    
    let {data} = await this.helper.presentNewEditDialog(
      'Edit sales item', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: singlerow.data.name },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: singlerow.data.name2 },
        { name: 'sundriesprice', label: 'Sundries price ' + settings.lang1currency, type: 'number', value: singlerow.data.sundriesprice },
        { name: 'vehiclevisuals', label: 'Video explanation', type: 'vehiclevisuals', value: singlerow.data.vehiclevisuals },
        { name: 'parts', label: 'Parts', type: 'codeprice', value: singlerow.data.parts },
        { name: 'jobs', label: 'Jobs', type: 'codepricehour', value: singlerow.data.jobs },
    ], file)
    if (data) {
      await this.supa.supabase.from('cross_sell').update(data.data).eq('id', file.id);
      this.browser.reload();
    }
  }

  async copyFile(file: FileDisplayItem) {
    const loader = await this.helper.getLoader();
    loader.present();
    
    let singlerow = await this.supa.supabase.from('cross_sell').select('*').eq('id', file.id).single();
    loader.dismiss();

    let settings: JSettings = await this.supa.Settings();

    let {data} = await this.helper.presentNewEditDialog(
      'Duplicate sales item', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: singlerow.data.name },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: singlerow.data.name2 },
        { name: 'sundriesprice', label: 'Sundries price ' + settings.lang1currency, type: 'number', value: singlerow.data.sundriesprice },
        { name: 'vehiclevisuals', label: 'Video explanation', type: 'vehiclevisuals', value: singlerow.data.vehiclevisuals },
        { name: 'parts', label: 'Parts', type: 'codeprice', value: singlerow.data.parts },
        { name: 'jobs', label: 'Jobs', type: 'codepricehour', value: singlerow.data.jobs },
    ], file)
    if (data) {
      data.data.parent = singlerow.data.parent;
      await this.supa.supabase.from('cross_sell').insert(data.data);
      this.browser.reload();
    }
  }

  async newFile(parent_id:number) {
    let settings: JSettings = await this.supa.Settings();

    let {data} = await this.helper.presentNewEditDialog(
      'Add new sales item', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: '' },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: '' },
        { name: 'sundriesprice', label: 'Sundries price ' + settings.lang1currency, type: 'number', value: '' },
        { name: 'vehiclevisuals', label: 'Video explanation', type: 'vehiclevisuals', value: '' },
        { name: 'parts', label: 'Parts', type: 'codeprice', value: [] },
        { name: 'jobs', label: 'Jobs', type: 'codepricehour', value: [] }
    ])
    if (data) {
      data.data.parent = parent_id
      await this.supa.supabase.from('cross_sell').insert(data.data);
      this.browser.reload();
    }
  }

}
