/* eslint-disable max-len */
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { Job } from '../../interfaces/job.interface';
import { Part } from '../../interfaces/part.interface';
import { HelperService } from '../../services/helper.service';
import { JSettings, SupaService } from '../../services/supa.service';
import { JobchooseDialog } from '../jobchoose/jobchoose.dialog';
import { PackagechooseDialog } from '../packagechoosedialog/packagechoosedialog.component';
import { PartchooseDialog } from '../partchoose/partchoose.dialog';
import { VehiclevisualsDialog } from '../vehiclevisuals/vehiclevisuals.component';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-jobpartitem',
  templateUrl: './jobpartitem.component.html',
  styleUrls: ['./jobpartitem.component.scss'],
})
export class JobpartitemComponent implements OnInit, OnDestroy {
  
  @Input('item') item:any = undefined;
  @Input('iname') name = "Name"
  @Input('itype') type = "Type"
  @Input('ispre') ispre = false
  @Input('vhcrecord') vhcrecord: any

  settings: JSettings = undefined

  constructor(private helper: HelperService, private supa: SupaService) { }

  autoPriceActive = true;

  ngOnInit() {
    this.loadSettings();
    if (this.item) {
      this.sanitiseItem();
    }
    this.autoPriceActive = true;
    this.autoPrice();
  }

  ngOnDestroy() {
    this.autoPriceActive = false;
  }

  autoPrice() {
    if (this.autoPriceActive) {
      this.sanitiseItem();
      this.calculatePrice();
      setTimeout(()=>{
        this.autoPrice();
      }, 1500);
      console.log('auto pricing');
    } else {
      console.log('auto price stopped');
    }
  }

  async loadSettings() {
    this.settings = await this.supa.Settings();
  }

  async searchVisual(item) {
    let result = await this.helper.createAndOpenModal(VehiclevisualsDialog, {preSelect: item.vehiclevisuals}, 'doubled-modal big-modal')
    if (result) {
      if (result.data) {
        if (result.data.id) {
          item.vehiclevisuals = result.data.id
        }
      }
    }
  }

  @ConfirmDelete()
  deleteJP(index, isJob = true) {
    if (isJob) {
      this.item.jobs.splice(index, 1)
    } else {
      this.item.parts.splice(index, 1)
    }
    this.calculatePrice()
  }

  async addJob() {
    this.sanitiseItem()
    const folder = await this.helper.vinToFolder(this.vhcrecord?.vin);
    let { data } = await this.helper.createAndOpenModal(JobchooseDialog, {folder}, 'doubled-modal big-modal')
    if (data) {
      let response = await this.supa.supabase.from('job').select('*').eq('id', data.id).single()
      if (response.error) {
        this.helper.notify('Could not load job', 'danger')
        console.log(response.error);
      } else {
        let job: Job = {
          name: response.data.name,
          name2: response.data.name2,
          code: response.data.code,
          price: Number.parseFloat(response.data.price),
          hour: response.data.hour
        }
        if ((response.data.vehiclevisuals+"").length > 0) {
          this.item.vehiclevisuals = response.data.vehiclevisuals;
        }
        if(isNaN(job.price)) job.price = 0;
        this.item.jobs.push(job)
        this.calculatePrice()
      }
    }
  }

  async addPart() {
    this.sanitiseItem()
    const folder = await this.helper.vinToFolder(this.vhcrecord?.vin);
    let { data } = await this.helper.createAndOpenModal(PartchooseDialog, {folder}, 'doubled-modal big-modal')
    if (data) {
      let response = await this.supa.supabase.from('part').select('*').eq('id', data.id).single()
      if (response.error) {
        this.helper.notify('Could not load part', 'danger')
        console.log(response.error);
      } else {
        let part: Part = {
          name: response.data.name,
          name2: response.data.name2,
          code: response.data.code,
          price: Number.parseFloat(response.data.price),
        }
        if(isNaN(part.price)) part.price = 0;
        this.item.parts.push(part)
        this.calculatePrice()
      }
    }
  }

  async addPackage() {
    this.sanitiseItem()
    const folder = await this.helper.vinToFolder(this.vhcrecord?.vin);
    let { data } = await this.helper.createAndOpenModal(PackagechooseDialog, {folder}, 'doubled-modal big-modal')
    if (data) {
      let response = await this.supa.supabase.from('package').select('*').eq('id', data.id).single()
      if (response.error) {
        this.helper.notify('Could not load predefined work', 'danger')
        console.log(response.error);
      } else {
        if ((response.data.vehiclevisuals+'').length > 0) {
          this.item.vehiclevisuals = response.data.vehiclevisuals;
        }
        if(!this.item.sundries) {this.item.sundries = 0;}

        response.data.jobs.forEach(j => {
          j.name = response.data.name + '_' + j.code;
          j.name2 = response.data.name2 + '_' + j.code;
          this.item.jobs.push(j);
        });
        response.data.parts.forEach(p => {
          p.name = response.data.name + '_' + p.code;
          p.name2 = response.data.name2 + '_' + p.code;
          this.item.parts.push(p);
        });
        if (!this.item.dateAuthorised) {
          const spr = Number.parseFloat(response.data.sundriesprice);
          this.item.sundries +=  (isNaN(spr) ? 0 : spr);
        }
        this.calculatePrice();
      }
    }
  }

  setSundries() {
    this.sanitiseItem();
    this.helper.promptInput('Set sundries', 'Please enter the amount for sundries: ' + this.settings.lang1currency, this.item.sundries.toString(), (v) => {
      this.item.sundries = Number.parseFloat(0 + v);
      this.calculatePrice();
    });
  }

  sanitiseItem() {
    if (!this.item.jobs) {
      this.item.jobs = [];
    }
    if (!this.item.parts) {
      this.item.parts = [];
    }
    if (!this.item.sundries || isNaN(this.item.sundries)) {
      this.item.sundries = 0;
    }
  }

  calculatePrice() {
    if (!this.item.dateAuthorised) {
      let price = isNaN(this.item.sundries) ? 0 : this.item.sundries;

      this.item.jobs.forEach(j => {
        if (!isNaN(j.price)) {price += j.price;}
      });
      this.item.parts.forEach(j => {
        if (!isNaN(j.price)) {price += j.price;}
      });

      this.item.price = Math.round(price * 100) / 100;
    }
  }

  openLightBox(images) {
    this.helper.openLightBox(images);
  }

  async editJob(index) {
    let { data } = await this.helper.presentNewEditDialog(
      'Edit job', [
      { name: 'name', label: 'Name (' + this.settings.lang1code + ')', type: 'text', value: this.item.jobs[index].name },
      { name: 'name2', label: 'Name (' + this.settings.lang2code + ')', type: 'text', value: this.item.jobs[index].name2 },
      { name: 'code', label: 'Labour code', type: 'text', value: this.item.jobs[index].code },
      { name: 'price', label: 'Labour price ' + this.settings.lang1currency, type: 'number', value: this.item.jobs[index].price },
      { name: 'hour', label: 'Labour hour', type: 'number', value: this.item.jobs[index].hour },
    ], undefined, 'doubled-modal');
    if (data) {
      if (index >= 0) {
        let job: Job = {
          name: data.data.name,
          name2: data.data.name2,
          code: data.data.code,
          price: Number.parseFloat(data.data.price),
          hour: data.data.hour
        };
        if(isNaN(job.price)) {job.price = 0;}
        this.item.jobs[index] = job;
      }
      this.calculatePrice();
    }
  }

  async editPart(index) {
    let { data } = await this.helper.presentNewEditDialog(
      'Edit part', [
      { name: 'name', label: 'Name (' + this.settings.lang1code + ')', type: 'text', value: this.item.parts[index].name },
      { name: 'name2', label: 'Name (' + this.settings.lang2code + ')', type: 'text', value: this.item.parts[index].name2 },
      { name: 'code', label: 'Part code', type: 'text', value: this.item.parts[index].code },
      { name: 'price', label: 'Part price ' + this.settings.lang1currency, type: 'number', value: this.item.parts[index].price },
    ], undefined, 'doubled-modal');

    if (data) {
      if (index >= 0) {
        let part: Part = {
          name: data.data.name,
          name2: data.data.name2,
          code: data.data.code,
          price: Number.parseFloat(data.data.price),
        }
        if(isNaN(part.price)) part.price = 0;
        this.item.parts[index] = part
      }
      this.calculatePrice()
    }
  }

  setPrice() {
    if (this.item.dateAuthorised) {
      return
    }
    this.helper.promptInput('What is the price?', 'Please enter a price ' + this.settings.lang1currency, this.item.price ? this.item.price.toString() : '', (v) => {
      this.item.price = v
    }, 'Price', 'number')
  }
}
