import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { VhcMessage } from './messages.component';

@Component({
    selector: 'messages-dialog',
    styleUrls: [],
    template: `
    <ion-header translucent>
        <ion-toolbar mode='md'>
            <ion-title translate>Notification</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="dismissModal()">
                    <ion-icon slot="icon-only" name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <strong>Notification received on</strong>
        <br />
        <br />
        {{message.created_at|date:'dd MMMM yyyy, HH:mm:ss'}}
        <br />
        <br />
        <strong>Message content</strong>
        <br />
        <br />
        {{message.message}}
    </ion-content>
    <ion-footer *ngIf="message.url.length > 0">
        <ion-toolbar>
            <ion-title>
                <ion-button (click)="onNavigate()" expand="block" fill="clear" shape="round">
                    Open location
                </ion-button>
            </ion-title>
        </ion-toolbar>
    </ion-footer>
`
})
export class MessageDialog {

    @Input('message') message: VhcMessage;

    constructor(private modalController: ModalController, private router: Router) { }

    dismissModal() {
        this.modalController.dismiss();
    }

    onNavigate() {
        this.modalController.dismiss();
        this.router.navigateByUrl(this.message.url)
    }

}