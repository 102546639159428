import { AlertController } from '@ionic/angular';
import { SharedModule } from './shared.module';

export function ConfirmDelete(message: string = 'Do you want to delete this item?', title='Delete item') {
    
    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        const original = descriptor.value;
        
        descriptor.value = async function(...args: any[]) {
            const alertctrl = SharedModule.injector.get(AlertController)
            let continuedelete = false;

            const alert = await alertctrl.create({
                header: title,
                subHeader: message,
                buttons: [
                  {
                    text: 'No',
                    role: 'cancel'
                  }, {
                    text: 'Yes',
                    handler: ()=>{ continuedelete = true; }
                  }
                ]
            });
          
            await alert.present();
            await alert.onDidDismiss();
            
            if(continuedelete) {
                return original.apply(this, args);
            } else {
                return null
            }

        }
        return descriptor;
    }
}

