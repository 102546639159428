import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'dateAway'
})
export class DateAwayPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            return DateTime.fromISO(value).toRelative({round: true, style: 'long'})
        }
        return value;
    }


}
