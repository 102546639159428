import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import SignaturePad from 'signature_pad';
import { TacDialog } from '../../../components/tacdialog/tacdialog.component';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { AuthObject } from '../../../interfaces/authobject.interface';
import { Vehicle } from '../../../interfaces/vehicle.interface';
import { vhcrecord } from '../../../interfaces/vhcrecord.interface';
import { HelperService } from '../../../services/helper.service';
import { JSettings } from '../../../services/supa.service';
import { VhcBrakeModule } from '../../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcCross_sellModule } from '../../../vhcmodules/cross_sellmodule/cross_sellmodule';
import { VhcQuestionblockModule } from '../../../vhcmodules/questionblock/vhcquestionblockmodule';
import { VhcTyreModule } from '../../../vhcmodules/tyremodule/vhctyremodule';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';

@Component({
  selector: 'app-authview',
  templateUrl: './authview.dialog.html',
  styleUrls: ['./authview.dialog.scss'],
})
export class AuthviewDialog implements OnInit, AfterViewInit {

  @Input('vhcrecord') vhcrecord: vhcrecord
  @Input('vehicle') vehicle: Vehicle
  @Input('premodules') premodules: Vhcmodule[]
  @Input('mpimodules') mpimodules: Vhcmodule[]
  @Input('qimodules') qimodules: Vhcmodule[]
  @Input('settings') settings: JSettings
  @Input('isPrimaryLang') isPrimaryLang: boolean
  @Input('includepre') includepre: boolean
  @Input('includempi') includempi: boolean
  @Input('includeqi') includeqi: boolean

  datetime = ""
  displayGreensAndAuthorised = false
  displayItemInfo = false
  acceptedTAC = false

  signaturePad: SignaturePad;
  @ViewChild('canvas', { static: true }) signaturePadElement;

  constructor(private helper: HelperService, private modalController: ModalController) {
    this.datetime = helper.getDateTime();
    this.acceptedTAC = false
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  getModules() {
    return [...(this.includepre?this.premodules:[]),...(this.includempi?this.mpimodules:[]),...(this.includeqi?this.qimodules:[])]
  }

  displayTyres(module) {
    if (this.displayGreensAndAuthorised)
      return true;
    return module.data.tyres.findIndex(t => t.auth || (t.value == 1 || t.value == 2) && !t.dateAuthorised) >= 0
  }
  
  displayBrakes(module) {
    if (this.displayGreensAndAuthorised)
      return true;
    return module.data.brakes.findIndex(t => t.auth || (t.value == 1 || t.value == 2) && !t.dateAuthorised) >= 0
  }

  displayQBlock(module: VhcQuestionblockModule) {
    if (this.displayGreensAndAuthorised)
      return true;
    return module.questions.findIndex(q => q.auth || (q.value == 1 || q.value == 2) && !q.dateAuthorised) >= 0
  }

  filteredTyres(tyremodule) {
    return tyremodule.filter(t => (this.displayGreensAndAuthorised || t.auth || ((t.value==2 || t.value==1) && !t.dateAuthorised)))
  }
  
  filteredBrakes(brakemodule) {
    return brakemodule.filter(t => (this.displayGreensAndAuthorised || t.auth || ((t.value==2 || t.value==1) && !t.dateAuthorised)))
  }
  
  filteredCross_sellItems(cross_sell_items) {
    return cross_sell_items.filter(t => (this.displayGreensAndAuthorised || cross_sell_items.auth || !t.dateAuthorised))
  }

  setPrice(item) {
    if (item.dateAuthorised) {
      return
    }
    this.helper.promptInput('What is the price?', 'Please enter a price ' + this.settings.lang1currency, item.price, (v)=>{
      item.price = v
    })
  }

  async openTAC() {
    let {data} = await this.helper.createAndOpenModal(TacDialog, {isPrimaryLang: this.isPrimaryLang}, 'doubled-modal')
    if (data) {
      this.acceptedTAC = true;
    }
  }

  ngOnInit() {}

  @ConfirmDelete('Do you want to close this authorisation?', 'Close')
  dismissModal() {
    this.modalController.dismiss();
  }

  openLightBox(images) {
    this.helper.openLightBox(images)
  }

  save() {
    const signDateTime = this.helper.getDateTime();
    this.getModules().forEach(vhcmodule => {
      if (vhcmodule.type == "Questionblock") {
        (vhcmodule as VhcQuestionblockModule).questions.forEach(question => {
          if (!question.dateAuthorised && question.auth) {
            question.dateAuthorised = signDateTime;
          }
          question.auth = false
        })
      } else if (vhcmodule.type == "Tyres") {
        (vhcmodule as VhcTyreModule).data.tyres.forEach(tyre => {
          if (!tyre.dateAuthorised && tyre.auth) {
            tyre.dateAuthorised = signDateTime;
          }
          tyre.auth = false
        })
      } else if (vhcmodule.type == "Brakes") {
        (vhcmodule as VhcBrakeModule).data.brakes.forEach(brake => {
          if (!brake.dateAuthorised && brake.auth) {
            brake.dateAuthorised = signDateTime;
          }
          brake.auth = false
        })
      } else if (vhcmodule.type == "Cross_sell") {
        (vhcmodule as VhcCross_sellModule).data.forEach(pckg => {
          if (!pckg.dateAuthorised && pckg.auth) {
            pckg.dateAuthorised = signDateTime;
          }
          pckg.auth = false
        })
      } else {
        if (!vhcmodule.dateAuthorised) {
          vhcmodule.dateAuthorised = signDateTime
        }
        vhcmodule.auth = false
      }
    })
    const authObj: AuthObject = {
      signature: this.signaturePad.toDataURL(),
      datetime: signDateTime,
      revoked: []
    }
    this.vhcrecord.authobjects.push(authObj)
    this.modalController.dismiss(authObj);
  }

}
