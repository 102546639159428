import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { SupaService, UserPermissions } from '../../services/supa.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {

  helpurl="https://www.youtube.com/embed/IZpgM6_zM9g"
  provider_id: string | undefined = undefined
  userdata: UserPermissions|undefined = undefined

  constructor(private supa: SupaService, private helper: HelperService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.provider_id = this.supa.user?.user_metadata.provider_id
    if (!this.provider_id) {
      this.signOut();
    }
    this.userdata = this.supa.LoginObject.value
  }

  signOut() {
    this.helper.notify('Signing out', 'dark')
    this.supa.signOut()
  }

  refreshPermissions() {
    this.supa.refreshPermissions(this.supa.session);
  }
}