import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
    name: 'csell_pckg_price'
})
export class CsellPckgPricePipe implements PipeTransform {
    public transform(value: any): string {
        const sundriescost = value?.sundries ? value?.sundries : 0;
        let partcost = 0;
        let jobcost = 0;
        value.jobs.forEach(j => {
            jobcost += isNaN(Number.parseFloat(j.price)) ? 0 : Number.parseFloat(j.price);
        });
        value.parts.forEach(j => {
            partcost += isNaN(Number.parseFloat(j.price)) ? 0 : Number.parseFloat(j.price);
        });

        const c = partcost+jobcost+(Number.parseFloat(sundriescost.toString()));

        return (Math.round(c* (100+environment.tax))/100) + ' (incl.tax)';
    }
}