import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { VideorecorderDialog } from '../components/videorecorder/videorecorder.component';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor
    (
      private toastController: ToastController,
      private modalController: ModalController,
      private loadingController: LoadingController
    ) {
    console.log("INIT M Service");
  }

  public async takeVideo():Promise<string|undefined> {
    let vrecorder = await this.modalController.create({component: VideorecorderDialog, cssClass: 'fullscreen-modal doubled-modal'})
    await vrecorder.present()
    let res = await vrecorder.onDidDismiss();
    if (res) {
      if (res.data) {
        if (res.data.path) {
          return res.data.path
        }
      }
    }
    return undefined
  }

  public takePicture(quality = 55):Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const loading = await this.loadingController.create({
        message: 'Loading picture',
        spinner: 'bubbles',
      });
      await loading.present();
      Camera.getPhoto({
        quality: quality,
        // width: 800,
        // height: 600,
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt
      }).then(v => {
        loading.dismiss()
        resolve('data:image/'+v.format+';base64,' + v.base64String)
      })
      .catch(reason => {
        loading.dismiss()
        resolve('rejected')
      })
    });
  }

}