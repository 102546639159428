import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements AfterViewInit {

  @ViewChild('ifr') ifr: ElementRef;
  
  constructor(private helper: HelperService) {

  }

  ngAfterViewInit(): void {
    this.reloadIfr();
  }

  startDate = DateTime.now().startOf('month')
  endDate = DateTime.now().endOf('month')

  async setDate(ev) {
    let newdates = await this.helper.chooseDateTime({
      IsRange: true,
      WithTime: false,
      RangeEnd: this.endDate,
      RangeStart: this.startDate
    })
    this.startDate = newdates[0].startOf('day');
    this.endDate = newdates[1].endOf('day');

    this.reloadIfr();
  }

  reloadIfr() {
    if (this.ifr) {
      if (this.ifr.nativeElement) {
        this.ifr.nativeElement.src = `https://osbreport.release.iq-motive.com/d/s93B5t54k/vhc?orgId=1&kiosk&from=${this.startDate.toMillis()}&to=${this.endDate.toMillis()}`;
      }
    }
  }

  preventEsc(e) {
    console.log(e)
  }
}
