import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { HelperService } from '../../../services/helper.service';
import { SupaService } from "../../../services/supa.service";

@Component({
    selector: 'app-osbsearch',
    templateUrl: './osbsearch.component.html',
    styleUrls: ['./osbsearch.component.scss'],
})
export class OSBSearchDialog implements OnInit {

    @Input('locationFilter') locationFilter = '-'
    searchTerm = ""
    searchResult: BehaviorSubject<OsbSearchResult[]> = new BehaviorSubject([]);

    constructor(private modalCtrl: ModalController, private supa: SupaService, private helper: HelperService) { }

    ngOnInit() { }

    dismissModal() {
        this.modalCtrl.dismiss();
    }

    keyDownHandler(event:KeyboardEvent) {
        if (event.code == 'Enter' || event.code == 'NumpadEnter') {
            this.search()
        }
    }

    onClick(id,vin) {
        this.modalCtrl.dismiss({
            id:id,
            vin:vin
        });
    }

    async search() {
        this.searchResult.next(undefined);
        let { data, error } = await this.supa.supabase.rpc('search_osb_appointment', {searchvalue: this.searchTerm.replace(' ','%20'),locations:this.locationFilter}).single()
        if (error) {
            console.log(error);
            this.helper.notify('Could not connect with osb');
            this.searchResult.next([]);
        } else {
            if (data) {
                if (data.data) {
                    // console.log(data);
                    this.searchResult.next(data.data);
                    return;
                }
            }
            this.searchResult.next([]);
        }
    }

}

export interface OsbSearchResult {
    id: number,
    licensePlate: string,
    customerName: string,
    phoneNumber: string,
    vin: string,
    arrivalTime: number,
    locationCode: string,
    creationTextSent: number,
    reminderTextSent: number,
    appointmentCancelled: boolean,
    appointmentCompletedLate: boolean
}