import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-vhcblueprints',
  templateUrl: './vhcblueprints.component.html',
  styleUrls: ['./vhcblueprints.component.scss'],
})
export class VhcblueprintsComponent {

  @ViewChild('browser') browser: FilebrowserComponent

  constructor(private alertController: AlertController, private supa: SupaService, private helper: HelperService, private router: Router) {}

  async onClick(file: FileDisplayItem) {    
    this.router.navigate(['/settings/vhcblueprints/editor', {id: file.id}]);
  }

  async newFile(parent:number) {
    console.log(parent, parent == null);
    this.router.navigate(['/settings/vhcblueprints/editor', {parent: parent}]);
  }

}
