import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';

import { lzw_decode } from '../../functions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  constructor(private supa: SupaService, private platform: Platform) { }

  async withSSO() {
    const { user, session, error } = await this.supa.supabase.auth.signIn({
      provider: 'keycloak',
    }, {
      scopes: 'roles profile email openid',
      redirectTo: window.location.origin,
    })
    console.log(user,session,error)
  }
}
