import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-moduleoverview',
  templateUrl: './moduleoverview.dialog.html',
  styleUrls: ['./moduleoverview.dialog.scss'],
})
export class ModuleoverviewDialog implements OnInit {

  @Input("hidemaintainance") hidemaintainance = false;

  constructor(private modalController: ModalController) { }

  dismissModal(module:string=undefined) {
    if (module) {
      this.modalController.dismiss({
        type: module
      });
    } else {
      this.modalController.dismiss();
    }
  }

  ngOnInit() {}

}
