import { HelperService } from "../../services/helper.service";
import { Vhcmodule } from "../vhcmodule";
import { SupaService } from "../../services/supa.service";
import { VHCQuestion } from "../questionblock/vhcquestionblockmodule";
import { InputField } from "../../services/new-edit.dialog";
import { BoschAnswerDialog } from "./boschanswer/boschanswer.dialog";

export class VhcBoschModule extends Vhcmodule
{
    questions: VHCQuestion[] = [];
    api: string = "";

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.name = "Bosch"
        this.type = "Bosch";
    }

    
    async openEditor() {
        await this.loadSettings();

        let inputarr: InputField[] = [{ type: 'text', label: 'Change the display name ('+this.settings.lang1name+')', value: this.name, name: 'name' }]
        if (this.settings.lang2enabled) {
            inputarr.push({ type: 'text', label: 'Change the display name ('+this.settings.lang2name+')', value: this.name2, name: 'name2' });
        }
        
        inputarr.push({ type: 'text', label: 'API Url', value: this.api, name: 'api' });

        let {data} = await this.helper.presentNewEditDialog(this.type + ' name', inputarr)
        console.log(data);
        if (data) {
            this.name = data.data.name;
            this.name2 = data.data.name2;
            this.api = data.data.api
        }
    }

    async openAnswer(qidx) {
        await this.loadSettings();

        let {data} = await this.helper.createAndOpenModal(BoschAnswerDialog, {
            bmodule: this,
            settings: this.settings
        }, 'doubled-modal tall-modal');

        return data
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, questions: this.questions, type: this.type, api: this.api}
    }
    
    public fromDatabase(obj, includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.questions = obj.questions;
        this.api = obj.api
        if (includedata) {
            this.questions.forEach((v,i)=>{
                if (!v.hasOwnProperty('value')) {
                    v.value = 0
                }
                if (!v.hasOwnProperty('recommendation')) {
                    v.recommendation = ""
                }
                if (!v.hasOwnProperty('recommendation2')) {
                    v.recommendation2 = ""
                }
                if (!v.hasOwnProperty('images')) {
                    v.images = []
                }
            })
        }
    }

    public getQIcon(value) {
        if (value == 3) {
            return 'green-text'
        }
        if (value == 1) {
            return 'red-text'
        }
        if (value == 2) {
            return 'amber-text'
        }
        return 'grey-text'
    }

    // VHC RECORD PART
    //q value, 0=grey, 1=red,2=amber,3=green
}