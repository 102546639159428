import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {

  locations = undefined

  constructor(private supa: SupaService, private helper: HelperService, private router: Router) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    let { data, error } = await this.supa.supabase.from('jlocation').select('code,name')
    this.locations = data;
  }

  async onAdd() {
    this.router.navigateByUrl('/locations/editor');
  }

  async onClick(item) {
    this.router.navigate(['/locations/editor', {code: item.code}]);
  }

}
