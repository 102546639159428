import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { RecommendationEditDialog } from '../../../../components/recommendationdialog/recommendationedit.dialog';
import { ConfirmDelete } from '../../../../decorators/confirmdelete.decorator';
import { HelperService } from '../../../../services/helper.service';
import { JSettings, SupaService } from '../../../../services/supa.service';

@Component({
  selector: 'app-maintenanceeditor',
  templateUrl: './maintenanceeditor.component.html',
  styleUrls: ['./maintenanceeditor.component.scss'],
})
export class MaintenanceeditorComponent implements OnInit {
  
  mId = undefined
  mparent = undefined
  settings: JSettings;

  slotvalues: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  items: MaintenanceItem[] = []

  name = "New table"

  loadingDone = false

  constructor(private alertController: AlertController, private supa: SupaService, private helper: HelperService, private route: ActivatedRoute, private router: Router, private modalController: ModalController) { }

  ngOnInit() {
    this.loadUp()
  }

  async loadUp() {
    this.settings = await this.supa.Settings();
    this.route.params.subscribe(params => {
      if (Object.keys(params).includes('id')) {
        this.mId = params.id;
        this.supa.supabase.from('maintenancetable').select('name,itemdata').eq('id', params.id).single().then(v => {
          if (v.error) {
            console.log(v.error);
            this.helper.notify('Could not load this maintenance table', 'danger')
            this.router.navigate(['settings/maintenance-tables'])  
          } else {
            this.slotvalues = v.data.itemdata.slotvalues;
            this.items = v.data.itemdata.items;
            this.name = v.data.name;
            this.loadingDone = true;
          }
        }, e => {
          console.log(e);
          this.helper.notify('Could not load this maintenance table', 'danger')
          this.router.navigate(['settings/maintenance-tables'])
        })
      } else if (Object.keys(params).includes('parent')) {
        this.mparent = params.parent == 'null' ? null : Number.parseInt(params.parent)
        this.loadingDone = true;
      } else {
        this.router.navigate(['settings/maintenance-tables'])
      }
    });
  }

  async editItem(mindex) {
    let prop: MaintenanceItem = 
      mindex === undefined
        ? {
            recommendations: [],
            slotvalues: this.slotvalues.map(v => ''),
            text: '',
            text2: ''
          }
        : this.items[mindex];
    const modal = await this.modalController.create({
      component: RecommendationEditDialog,
      cssClass: "fixed-modal",
      componentProps: { prop: prop, settings: this.settings }
    });
  
    await modal.present();
  
    const data = await modal.onDidDismiss();

    if (data.data) {
      if (mindex === undefined) {
        this.items.push(data.data.prop);
      } else {
        this.items[mindex] = data.data.prop;
      }
    }
  }

  addColumn() {
    this.helper.promptInput('Add column', 'Please enter the mileage (x1000)', '', (v)=>{
      v = Number.parseInt(v);
      if (v) {
        let index = this.slotvalues.length;
        for (let i = 0; i < this.slotvalues.length; i++) {
          console.log(this.slotvalues[i], v);
          if (this.slotvalues[i] > v) {
            index = i;
            break;
          }
        }
        console.log(index);
        this.slotvalues.splice(index, 0, v)
        this.items.forEach(i => {
          i.slotvalues.splice(index, 0, "")
        })
      }
    }, '10', 'number')
  }

  copy() {
    localStorage.setItem('MAINTENANCEITEMS', JSON.stringify({items: this.items, slotvalues: this.slotvalues}))
    this.helper.notify('Maintainance items were copied.', 'success');
  }

  paste() {
    if (localStorage.getItem('MAINTENANCEITEMS') == null) {
      this.helper.notify('No maintainance items in memory, copy something first.', 'warning');
      return
    }
    const retrievedObj = JSON.parse(localStorage.getItem('MAINTENANCEITEMS'));
    this.items = retrievedObj.items;
    this.slotvalues = retrievedObj.slotvalues;

    this.helper.notify('Maintainance items were pasted.', 'success');

  }

  async editColumn(sindex, milagetext) {
    const alert = await this.alertController.create({
      cssClass: 'alertcenteredbtns',
      header: 'Add column',
      subHeader: 'Please enter the mileage (x1000)',
      inputs: [
        {
          name: 'theinput',
          type: 'number',
          value: milagetext
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Remove',
          role: 'delete',
          cssClass: 'red',
          handler: () => {
            this.slotvalues.splice(sindex, 1);
            this.items.forEach(i => {
              i.slotvalues.splice(sindex, 1);
            })
          }
        }, {
          text: 'Save',
          handler: (q) => { 
            let v = Number.parseInt(q.theinput);
            if (v) {
              this.slotvalues.splice(sindex, 1);
              let index = this.slotvalues.length;
              for (let i = 0; i < this.slotvalues.length; i++) {
                console.log(this.slotvalues[i], v);
                if (this.slotvalues[i] > v) {
                  index = i;
                  break;
                }
              }
              this.slotvalues.splice(index, 0, v)
              this.items.forEach(i => {
                let oldItem = i.slotvalues.splice(sindex, 1)
                i.slotvalues.splice(index, 0, oldItem[0])
              })
            }
          }
        }
      ]
    });
    await alert.present();
  }

  @ConfirmDelete()
  deleteItem(midex) {
    this.items.splice(midex, 1);
  }

  async setValue(mindex, sindex) {
    const alert = await this.alertController.create({
      cssClass: 'alertcenteredbtns',
      header: 'Maintenance type',
      subHeader: 'Choose a type',
      buttons: [{ text: 'Do nothing', role: '' }, { text: 'Do always', role: '.' }, { text: 'Tighten', role: 'T' }, { text: 'Adjust', role: 'A' }, { text: 'Clean', role: 'C' }, { text: 'Replace', role: 'R' }, { text: 'Inspect', role: 'I' }],
    });

    await alert.present();
    let { role } = await alert.onDidDismiss();
    if (role != 'backdrop') {
      this.items[mindex].slotvalues[sindex] = role;
    }
  }

  async setRow(mindex) {
    const alert = await this.alertController.create({
      cssClass: 'alertcenteredbtns',
      header: 'Maintenance type',
      subHeader: 'Choose a type for the entire row',
      buttons: [{ text: 'Do nothing', role: '' }, { text: 'Do always', role: '.' }, { text: 'Tighten', role: 'T' }, { text: 'Adjust', role: 'A' }, { text: 'Clean', role: 'C' }, { text: 'Replace', role: 'R' }, { text: 'Inspect', role: 'I' }],
    });

    await alert.present();
    let { role } = await alert.onDidDismiss();
    if (role != 'backdrop') {
      this.items[mindex].slotvalues.forEach((i, idx) => {
        this.items[mindex].slotvalues[idx] = role;
      });
    }
  }

  onBack() {
    this.helper.back();
  }

  currentDropTarget = null;
  currentDragIndex = null

  stopDrag(from_index) {
    const from_data = JSON.stringify(this.items[from_index]);
    this.items.splice(from_index, 1);
    this.items.splice(this.currentDropTarget, 0, JSON.parse(from_data))
    this.currentDropTarget = null;
    this.currentDragIndex = null;
  }

  async onDelete() {
    if(!this.mId) {
      this.helper.notify("This has never been saved yet", "warning");
      return
    }
    let { data, error } = await this.supa.supabase.from('maintenancetable').delete().eq('id', this.mId);
    if (error) {
      this.helper.notify("Could not delete the item", "danger");
      return;
    }
    this.router.navigate(['/settings/maintenance-tables/'])
  }

  async onSave() {
    let newData = {
      name: this.name,
      itemdata: {
        slotvalues: this.slotvalues,
        items: this.items
      }
    }
    if (this.mId) {
      let { data, error } = await this.supa.supabase.from('maintenancetable').update(newData).eq('id', this.mId);
      if (error) {
        this.helper.notify("Could not save the item", "danger");
        return;
      }
    } else {
      newData["parent"] = this.mparent;

      let { data, error } = await this.supa.supabase.from('maintenancetable').insert(newData).single()
      if (error) {
        this.helper.notify("Could not save the item", "danger");
        return;
      }
      this.mId = data.id
    }
    this.helper.promptYesNo('Layout was saved.', 'Dow you want to leave the editor?', () => {
      this.router.navigate(['/settings/maintenance-tables/'])
    }, 'Leave editor', 'Keep editing')
  }

}

export interface MaintenanceItem {
  text: string;
  text2: string;
  recommendations: string[][];
  slotvalues?: string[];
}