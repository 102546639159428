import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../../services/helper.service';
import { JSettings } from '../../../services/supa.service';
import { VhcWalkaroundModule } from '../vhcwalkaroundmodule';

import { MazdaFrames } from './3dmazda';
import { ImgcamEvent, ImgcamEventType, ImgcamService } from '../../../services/imgcam.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-walkaroundanswer',
  templateUrl: './walkaroundanswer.dialog.html',
  styleUrls: ['./walkaroundanswer.dialog.scss'],
})
export class WalkaroundanswerDialog implements OnInit {

  @Input('walkaroundmodule') walkaroundmodule: VhcWalkaroundModule;
  @Input('settings') settings: JSettings;

  constructor(private helper: HelperService, private modalController: ModalController, private imgcam: ImgcamService) { }

  ngOnInit() {
    
  }

  startCam() {
    console.log('here');
  }

  dismissModal() {
    this.modalController.dismiss(false, 'cancel');
  }
  
  nextModal() {
    this.modalController.dismiss(true);
  }

  async addImg(fromstart=false) {
    let idx = fromstart ? 0 : this.ImgIndex;
    const permiss = await this.imgcam.checkPermissions();
    console.log('perms', permiss);
    if (permiss == 'OK') {
      console.log('set overlay');
      this.imgcam.setOverlay(MazdaFrames[idx]);
      console.log('start cam');
      this.imgcam.startCamera();
    } else {
      this.helper.notify(permiss, 'warning')
    }
    if(this.imgcamSubscription) {
      this.imgcamSubscription.unsubscribe();
    }
    this.imgcamSubscription = this.imgcam.OnImgEvent.subscribe((v:ImgcamEvent)=>{
      console.log(v);
      if (v.type == ImgcamEventType.EXIT) {
        if (this.imgcamSubscription) {
          this.imgcamSubscription.unsubscribe();
        }
        console.log('Exit was initiated');
      } else if (v.type == ImgcamEventType.ERROR) {
        this.helper.notify("Camera module did not work correctly", 'danger')
      } else {
        console.log('Image taken');
        this.walkaroundmodule.images[idx] = v.image;
        idx++;
        if (idx >= 16 || !fromstart) {
          this.imgcam.stopCamera();
        } else {
          this.helper.notify("Please continue with picture " + idx+1)
          this.imgcam.setOverlay(MazdaFrames[idx]);
        }
      }
    })
  }

  imgcamSubscription:Subscription = null;

  getImage() {
    return this.walkaroundmodule.images[this.ImgIndex] ? this.walkaroundmodule.images[this.ImgIndex] : MazdaFrames[this.ImgIndex];
  }

  MouseIsDown = false;
  StartingX = 0;
  ImgIndex = 6
  ImgStartIndex = 6
  setMouseDown(event, isDown: boolean, isTouch=false) {
    const screenx = isTouch ? event.changedTouches[0].screenX : event.screenX
    if (isDown) {
      this.StartingX = screenx;
      this.ImgStartIndex = this.ImgIndex;
      this.MouseIsDown = true;
    } else {
      this.MouseIsDown = false;
    }
  }

  mouseMove(event:any, isTouch=false) {
    const screenx = isTouch ? event.changedTouches[0].screenX : event.screenX
    if (this.MouseIsDown) {
      const deltaScrollX = this.StartingX - screenx;
      const frames = Math.round(Math.abs(deltaScrollX) / 20)
      if (frames == 0) {
        this.ImgIndex = this.ImgStartIndex
        return;
      }
      if (deltaScrollX >= 0) {
        this.ImgIndex = (this.ImgStartIndex + frames) % 16
      } else {
        this.ImgIndex = ((Math.abs(Math.floor( (this.ImgStartIndex - frames)/16 ))*16) + (this.ImgStartIndex - frames)) % 16
      }
    }
  }

}