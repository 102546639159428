import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-quotationlayout',
  templateUrl: './quotationlayout.component.html',
  styleUrls: ['./quotationlayout.component.scss'],
})
export class QuotationlayoutComponent implements OnInit {

    qlayouts = undefined

    constructor(private supa: SupaService, private helper: HelperService, private router: Router) { }

    ngOnInit() {
      this.init();
    }

    async init() {
      let { data, error } = await this.supa.supabase.from('quotationlayout').select('id,name')
      this.qlayouts = data
    }

    async onAdd() {
      this.router.navigateByUrl('/settings/quotationlayout/editor');
    }

    async onClick(item) {
      this.router.navigate(['/settings/quotationlayout/editor', {id: item.id}]);
    }

  }
