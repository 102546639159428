
//STAGING FOR DEBUG
// export const sid = "orgftzaexhegkadwknus";
// export const environment = {
//   production: true,
//   supabaseUrl: 'https://' + sid + '.supabase.co',
//   supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9yZ2Z0emFleGhlZ2thZHdrbnVzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE2NzAwMDMsImV4cCI6MjAwNzI0NjAwM30.laz4vTxVkId7pqRotGW0KiCGZBLLvHXCu4Kri_RaJ-I',
//   vehicleVisualsUrlNoPartId: 'https://iqmotive.vehiclevisuals.com/thirdpartyapi/#!/viewAnimation/PARTNUMBERHERE?show_menu=0&is_interactive=0&show_left_sidebar=0&show_description=0&video_only=1&auto_play=0',
//   SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
//   logoUrl: 'https://' + sid + '.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
//   osbUrl: 'https://osb.staging.iq-motive.com',
//   osbFrontendUrl: 'https://osb.staging.iq-motive.com',
//   internalfileUrl: 'https://' + sid + '.supabase.co/storage/v1/object/public/internaldesign/',
//   portalUrl: 'https://vhcportal.staging.iq-motive.com',
//   emailAPIUrl:"",
//   tax: 15
// };

export const sid = "slgbgdbbsrsydpeoduik";
export const environment = {
  production: true,
  supabaseUrl: 'https://' + sid + '.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNsZ2JnZGJic3JzeWRwZW9kdWlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk5MTM2ODIsImV4cCI6MTk5NTQ4OTY4Mn0.vxsVq8guG33rvD7kYC8UUBw8qxjgHp-SLOjMfOvek08',
  vehicleVisualsUrlNoPartId: 'https://iqmotive.vehiclevisuals.com/thirdpartyapi/#!/viewAnimation/PARTNUMBERHERE?show_menu=0&is_interactive=0&show_left_sidebar=0&show_description=0&video_only=1&auto_play=0',
  SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
  logoUrl: 'https://' + sid + '.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
  osbUrl: 'https://book.hha.sa:10004',
  osbFrontendUrl: 'https://book.hha.sa',
  internalfileUrl: 'https://' + sid + '.supabase.co/storage/v1/object/public/internaldesign/',
  portalUrl: 'https://vhcportal.hha.iq-motive.com',
  emailAPIUrl:"https://email.hha.iq-motive.com/email",
  tax: 15
};




// export const vw_environment = {
//   production: false,
//   logoUrl: 'https://oxqusnbpamaqppeadzex.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
//   osbUrl: 'https://hhaosb.iq-motive.com',
//   portalUrl: '',
//   supabaseUrl: 'https://oxqusnbpamaqppeadzex.supabase.co',
//   supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyMjYzNTM1NiwiZXhwIjoxOTM4MjExMzU2fQ.LeBqbiPi72bY091l-Sne2RFeHCOeT5v7MShUrDhUpt8',
//   SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
//   currentVersion: 3
// };

// export const hha_environment = {
//   production: false,
//   logoUrl: 'https://oxqusnbpamaqppeadzex.supabase.co/storage/v1/object/public/internaldesign/companylogo.png',
//   osbUrl: 'https://hhaosb.iq-motive.com',
//   portalUrl: '',
//   supabaseUrl: 'https://oxqusnbpamaqppeadzex.supabase.co',
//   supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyMjYzNTM1NiwiZXhwIjoxOTM4MjExMzU2fQ.LeBqbiPi72bY091l-Sne2RFeHCOeT5v7MShUrDhUpt8',
//   SettingsExpiretime: 600000, // 10*60*1000 = 10 minutes
//   currentVersion: 3
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
