import { _ra } from "../../functions";
import { HelperService } from "../../services/helper.service";
import { SupaService } from "../../services/supa.service";
import { Vhcmodule } from "../vhcmodule";

export class VhcMaintenancedModule extends Vhcmodule
{
    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = "Maintenance";
        this.name = "Maintenance table";
    }

    getPdfVersion(param:any=undefined,primarylang=false) {
        return primarylang?'The maintenance items have not been added yet':_ra("  لم يتم اضافه عناصر الصيانة بعد")
    }

    public async openEditor(): Promise<void> {
        await this.helper.promptYesNo('Maintenance module', "During the creation of a VHC Record, the technician will pick the correct maintenance table", ()=>{});
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, type: this.type}
    }
}