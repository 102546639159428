import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'jjj-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  @Output('newFolder') newFolder = new EventEmitter<any>();
  @Output('newFile') newFile = new EventEmitter<any>();
  @Output('delete') delete = new EventEmitter<any>();
  @Output('setList') setList = new EventEmitter<any>();
  @Output('setTiles') setTiles = new EventEmitter<any>();

  @Input('hideFolder') hideFolder = false;
  @Input('hideFile') hideFile = false;
  @Input('hideDelete') hideDelete = false;
  @Input('hideList') hideList = false;
  @Input('hideTiles') hideTiles = false;


  constructor() { }

  ngOnInit() {}

}
