import { ApplicationRef, ChangeDetectorRef, Component, OnInit, NgZone } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { fader } from './route-animation';
import { ImgcamService } from './services/imgcam.service';
import { SupaService, UserPermissions } from './services/supa.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './services/helper.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    fader
  ]
})
export class AppComponent implements OnInit {

  logoUrl = environment.logoUrl;
  currentUrl='';
  isWCamMode=false;
  userpermissions: UserPermissions|undefined = undefined;
  langMenuOpen=false;

  constructor(
      private router: Router,
      public imgcam: ImgcamService,
      public supa: SupaService,
      private cdr: ChangeDetectorRef,
      public translate: TranslateService,
      public helper: HelperService,
      public deeplinks: Deeplinks
    ) {
    this.translate.setDefaultLang('en');
    this.translate.langs.push('kr');
    this.translate.langs.push('ar');
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    localStorage.removeItem('EXTCONF');
    localStorage.removeItem('SETTINGS');

    this.supa.LoginObject.subscribe(lo => {
      this.userpermissions = lo
    })
  }

  setlang(code) {
    this.langMenuOpen = false;
    this.translate.use(code)
  }

  menuOpened(e) {
    var event = new MouseEvent('mouseover', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    e.target.dispatchEvent(event);
  }

  visible(url) {
    return this.currentUrl.startsWith(url);
  }
  
  hasSubmenu() {
    return this.currentUrl.startsWith('/settings');
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData
  }
}
