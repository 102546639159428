import { animate, query, style, transition, trigger } from '@angular/animations';

export const fader = trigger('routeAnimations', [
    transition('* <=> *', [
        query(':enter', [
            style({
                position: 'absolute',
                width: '100%',
                left: 0,
                opacity: 0,
                transform: 'translateY(20px)'
            })
        ], { optional: true }),
        query(':enter', [
            animate('500ms ease',
                style({ opacity: 1, transform: 'translateY(0)' })
            )
        ], { optional: true })
    ])
])