import { Injector, NgModule } from "@angular/core";
import { AlertController } from "@ionic/angular";

@NgModule({
    declarations: [],
    imports: [],
    providers: [AlertController]
  })
  export class SharedModule {
    static injector: Injector;
  
    constructor(injector: Injector) {
      SharedModule.injector = injector;
    }
  }