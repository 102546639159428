import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { FollowupeditDialog } from '../../components/followupeditdialog/followupeditdialog.component';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { followupdata } from './followupitem.interface';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss'],
})
export class FollowupComponent implements OnInit {

  currentActive = 'deferred'

  FollowUpData: followupdata[];
  SelectedFollowUpData: followupdata[];
  FollowUpDataTableObservable: BehaviorSubject<followupdata[]|undefined> = new BehaviorSubject<followupdata[]|undefined>(undefined);

  // pages
  from = 0
  amount = 15;
  totalRecords = 0;

  constructor(private helper: HelperService, private supa: SupaService, private platform: Platform) { }

  ngOnInit() {
    this.getData(true);
  }

  ngOnDestroy() {
   
  }

  setActive(tab) {
    this.currentActive = tab;
    this.getPageData(true);
  }

  countStatus(followup: followupdata) {
    return followup.items.filter(i => i.status == this.currentActive).length;
  }
  
  firstDate(followup: followupdata) {
    let items = followup.items.filter(i => i.status == this.currentActive).map(i => new Date(i.date)).sort((a,b)=>a.getTime()-b.getTime());
    return items.length > 0 ? items[0] : null;
  }

  latestData = 0;
  async getData(startFromZero=false) {
    console.log("Loading follow up data")
    let {data, error} = await this.supa.supabase.from('followup').select('id,vin,items');
    if (error) {
      this.helper.notify("Could not load follow up data", "danger");
    } else {
      this.FollowUpData = data;
      this.latestData = (new Date()).getTime();
      this.getPageData(startFromZero);
    }
  }

  getPageData(startFromZero=false) {
    this.FollowUpDataTableObservable.next(undefined);
    if ((new Date()).getTime() - this.latestData > 60000) {
      console.log("Data to old, getting new data");
      this.getData(startFromZero);
      return;
    }
    console.log("Loading follow up page data")
    if (startFromZero) {
      this.SelectedFollowUpData = this.FollowUpData.filter(i => i.items.findIndex(s => s.status == this.currentActive) >= 0);
      this.from = 0;
      this.totalRecords = this.SelectedFollowUpData.length;
    }
    console.log(this.FollowUpData)
    this.FollowUpDataTableObservable.next(this.SelectedFollowUpData.slice(this.from, this.from+this.amount))
  }

  async openFU(fu: followupdata) {
    let res = await this.helper.createAndOpenModal(FollowupeditDialog, {id: fu.id}, (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) ? 'big-modal' : 'fullscreen-modal')
    if (res.data) {
      this.getData(true);
    }
  }

}
