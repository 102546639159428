/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VinCouple } from '../vincoupler.component';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-vincoupleredit',
  templateUrl: './vincoupleredit.component.html',
  styleUrls: ['./vincoupleredit.component.scss'],
})
export class VincouplereditComponent implements OnInit {

  @Input('couple') couple: VinCouple = {folder: '', sort: 0, vinfilter: ''};

  Folder = '';
  CurPos = 0;
  SelectedMode = 'all';
  RangeFrom = '9';
  RangeTill = 'A';
  VinDigits = [
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
    '*',
  ];

  constructor(private modalController: ModalController, private helper: HelperService) {

  }

  ngOnInit(): void {
    if (this.couple.vinfilter === '' || this.couple.vinfilter == null || this.couple.vinfilter === undefined) {
      this.couple.vinfilter = '*****************';
    }
    //*[9-A]*************3*
    const lVinDigits: string[] = [];
    for (let i = 0; i < this.couple.vinfilter.length; i++) {
      if (this.couple.vinfilter[i] === '*') {
        lVinDigits.push('*');
      } else if (this.couple.vinfilter[i] === '[' && this.couple.vinfilter.length - i >= 5) {
        lVinDigits.push(this.couple.vinfilter[i + 1] + '-' + this.couple.vinfilter[i + 3]);
        i += 4;
      } else {
        lVinDigits.push(this.couple.vinfilter[i]);
      }
    }
    while (lVinDigits.length < 17) {
      console.log('Add some');
      lVinDigits.push('*');
    }
    if (lVinDigits.length > 17) {
      console.log('Delete some');
      lVinDigits.splice(17, lVinDigits.length - 17);
    }
    this.Folder = this.couple.folder;
    this.VinDigits = lVinDigits;
    this.setPos(0);
  }

  save() {
    if (this.Folder.length < 2) {
      this.helper.notify('The folder has to be at least 2 characters long', 'warning');
      return;
    }
    console.log(this.generateCode());
    this.modalController.dismiss({ couple: {folder: this.Folder, sort: this.couple.sort, vinfilter: this.generateCode()} as VinCouple });
  }

  generateCode() {
    let val = '';
    this.VinDigits.forEach(v => {
      if (v.includes('-')) {
        val += '[' + v + ']';
      } else {
        val += v;
      }
    });
    return val;
  }

  setPos(pos) {
    if (this.VinDigits[pos].includes('-')) {
      this.SelectedMode = 'range';
    } else if (this.VinDigits[pos].includes('*')) {
      this.SelectedMode = 'all';
    } else {
      this.SelectedMode = 'specific';
    }
    this.CurPos = pos;
  }

  selectedModeChanged(event) {
    if (event.detail.value === 'all') {
      this.VinDigits[this.CurPos] = '*';
    } else if (event.detail.value === 'specific') {
      this.VinDigits[this.CurPos] = '0';
    } else if (event.detail.value === 'range') {
      this.RangeFrom = '9';
      this.RangeTill = 'A';
      this.VinDigits[this.CurPos] = '9-A';
    }
  }

}
