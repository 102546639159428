import { Component, ViewChild } from '@angular/core';
import { FilebrowserComponent } from '../../../components/fs/filebrowser/filebrowser.component';
import { FileDisplayItem } from '../../../components/fs/filedisplay/filedisplay.component';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-part',
  templateUrl: './part.component.html',
  styleUrls: ['./part.component.scss'],
})
export class PartsComponent {

  @ViewChild('browser') browser: FilebrowserComponent

  constructor(private supa: SupaService, private helper: HelperService) {}

  async onClick(file: FileDisplayItem) {
    const loader = await this.helper.getLoader();
    loader.present();
    
    let singlerow = await this.supa.supabase.from('part').select('*').eq('id', file.id).single();
    loader.dismiss();

    let settings: JSettings = await this.supa.Settings();
    
    let {data} = await this.helper.presentNewEditDialog(
      'Edit part', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: singlerow.data.name },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: singlerow.data.name2 },
        { name: 'code', label: 'Part code', type: 'text', value: singlerow.data.code },
        { name: 'price', label: 'Part price ' + settings.lang1currency, type: 'number', value: singlerow.data.price },
    ], file)
    if (data) {
      await this.supa.supabase.from('part').insert(data.data);
      this.browser.reload();
    }
  }

  async newFile(parent:number) {
    let settings: JSettings = await this.supa.Settings();

    let {data} = await this.helper.presentNewEditDialog(
      'Add new part', [
        { name: 'name', label: 'Name ('+settings.lang1code+')', type: 'text', value: '' },
        { name: 'name2', label: 'Name ('+settings.lang2code+')', type: 'text', value: '' },
        { name: 'code', label: 'Part code', type: 'text', value: '' },
        { name: 'price', label: 'Part price ' + settings.lang1currency, type: 'number', value: '' },
    ])
    if (data) {
      data.data.parent = parent
      await this.supa.supabase.from('part').insert(data.data);
      this.browser.reload();
    }
  }

}
