import { EventEmitter, Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CameraPreview, CameraPreviewOptions } from '@ionic-native/camera-preview/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Injectable({
  providedIn: 'root'
})
export class ImgcamService {

  public image: string = "";
  public ImgCamMask: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public OnImgEvent: EventEmitter<ImgcamEvent> = new EventEmitter<ImgcamEvent>();

  /**
   * Sets the camera preview image
   * @param image Image to be displayed in the center of the camera preview; has to be base64; Pass empty string for no picture at all
   */
  public setOverlay(image: string): void {
    this.image = image;
  }

  /**
   * Start the camera
   */
  public async startCamera() {
    console.log("IMGCAM Current screen orientation:" + this.screenOrientation.type); 
    await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);

    console.log('Wait a bit fot the rotation of the scren (animation)');
    await (new Promise(resolve => setTimeout(resolve, 500)));

    console.log('IMGCAM Start camera');
    const camPrevOpts: CameraPreviewOptions = {
      camera: 'rear',
      toBack: true,
    }

    this.cameraPreview.startCamera(camPrevOpts).then(
      (res) => {
        this.ImgCamMask.next(true);
        console.log(res)
      },
      (err) => {
        console.log(err)
        this.errorEvent(err)
      });
  }

  private errorEvent(msg) {
    this.cameraPreview.stopCamera();
    this.screenOrientation.unlock();
    console.log('IMGCAM Error');
    this.ImgCamMask.next(false);
    this.OnImgEvent.emit({
      type: ImgcamEventType.ERROR
    })
  }

  /**
   * Stop the camera
   */
  public stopCamera(): void {
    this.cameraPreview.stopCamera();
    this.screenOrientation.unlock();
    console.log('IMGCAM Stop camera');
    this.ImgCamMask.next(false);
    this.OnImgEvent.emit({
      type: ImgcamEventType.EXIT
    })
  }

  /**
   * Check camera open status
   * @returns True when camera is currently open
   */
  public IsCameraOpen(): boolean {
    return this.ImgCamMask.value;
  }

  public async checkPermissions(): Promise<string> {
    if (!(this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p)))) {
      console.log('Is Mobile');
      
      let checkResult = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA);
      console.log('Has camera permission? :' + checkResult.hasPermission);
      
      if (checkResult.hasPermission) {
        return 'OK'
      }
      
      console.log('Requesting camera permission');
      let requestResult = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      console.log('Has camera permission been granted? :' + requestResult.hasPermission);

      if (requestResult.hasPermission) {
        return 'OK'
      }

      return 'No camera permission'
    } else {
      console.log('Is Not Mobile');
      return "Only available on a mobile device";
    }
  }

  constructor(public platform: Platform, private androidPermissions: AndroidPermissions, private cameraPreview: CameraPreview, private screenOrientation: ScreenOrientation) { }

  public async takePicture() {
    console.log('IMGCAM Take picture');

    this.cameraPreview.takePicture({
      height: 500,
      width: 800,
      quality: 85
    }).then((imageData) => {
      this.OnImgEvent.emit({
        type: ImgcamEventType.PICTURE,
        image: 'data:image/jpeg;base64,' + imageData
      })
    }, (err) => {
      console.log(err);
      this.errorEvent(err);
    });
  }
}

export interface ImgcamEvent {
  type: ImgcamEventType;
  image?: string;
}

export enum ImgcamEventType {
  EXIT,
  PICTURE,
  ERROR
}