import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-usersearch',
  templateUrl: './usersearch.component.html',
  styleUrls: ['./usersearch.component.scss'],
})
export class UserSearchDialog implements OnInit {

  @Input('locationcode') locationcode = ""
  employeeid = ""
  namefilter = ""

  SearchResults: Subject<UserSearchResult[]|null|undefined> = new Subject<UserSearchResult[]|null|undefined>();
  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    let filters:string[] = []
    const limit = 10;

    this.SearchResults.next(undefined);

    let {data} = await this.supa.supabase.rpc('find_users', {
      eid: this.employeeid ? `%${this.employeeid}%` : '%%',
      ename:  this.namefilter ? `%${this.namefilter}%` : '%%',
      egroup: this.locationcode ? this.locationcode : '',
    })

    this.SearchResults.next(data.map((v:UserSearchResult) => {
      v.permissions = v.permissions.filter(p => !p.startsWith('Osb') && !p.startsWith('default-'))
      return v;
    }))
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  selectUser(user :UserSearchResult) {
    this.helper.createAndOpenAlert(user.fullname, 'Do you want to select this user?', () => {
      this.modalController.dismiss({user:user});
    }, [])
  }

}

export interface UserSearchResult {
  id: string;
  fullname: string;
  employee_id: string;
  permissions: string[];
}