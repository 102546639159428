import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { SupaService } from '../../../services/supa.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { HelperService } from '../../../services/helper.service';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { MediaService } from '../../../services/media.service';
import { VideodialogDialog } from '../../../components/videodialog/videodialog.dialog';
import { SpeachService } from '../../../services/speach.service';
import { Subscription } from 'rxjs';
import { VhcMediaModule } from '../mediamodule';

@Component({
  selector: 'app-mediaanswer',
  templateUrl: './mediaanswer.dialog.html',
  styleUrls: ['./mediaanswer.dialog.scss'],
})
export class MediaAnswerDialog implements OnInit, OnDestroy {

  @Input('module') module: VhcMediaModule;

  constructor(private actionSheetController: ActionSheetController, 
        private helper: HelperService, 
        private modalController: ModalController, 
        public platform: Platform, 
        private androidPermissions: AndroidPermissions, 
        private media: MediaService, 
        private photoViewer: PhotoViewer, 
        private supa: SupaService,
    ) { 
  }

  subs:Subscription|undefined;

  ngOnInit() {
    if (!(this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p)))) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      );
    }
  }

  ngOnDestroy() {

  }

  dismissModal() {
    this.modalController.dismiss();
  }

  nextModal() {
    this.modalController.dismiss(true);
  }

  async addPicture() {
    if (!this.module.data.images) this.module.data.images = [];
    const picture = await this.media.takePicture()
    if (picture == 'rejected') {
      return;
    }
    this.module.data.images.push(picture);
  }

  @ConfirmDelete()
  deleteImg(i) {
    this.module.data.images.splice(i, 1);
  }

  async addVideo() {
    if (!this.module.data.videos) this.module.data.videos = [];
    let path = await this.media.takeVideo();
    if (path) {
      this.module.data.videos.push(path);
    }
  }

  @ConfirmDelete()
  deleteVideo(i) {
    this.module.data.videos.splice(i, 1);
  }

  async openPicture(imgs, index) {
    if (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) {
      //Desktop
      this.helper.openLightBox(imgs, index)
    } else {
      // android / ios
      this.photoViewer.show(imgs[index]);
    }
  }

  async openVideo(index) {
    if (this.module?.data?.videos?.length) {
      let urlObj = await this.supa.supabase.storage.from('internaldesign').createSignedUrl(this.module.data.videos[index], 600);
      console.log("URLOBJ", index, this.module.data.videos[index], urlObj)
      this.helper.createAndOpenModal(VideodialogDialog, { url: urlObj.signedURL }, 'doubled-modal fullscreen-modal')
    }
  }

}
