import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';

@Component({
  selector: 'app-tacdialog',
  templateUrl: './tacdialog.component.html',
  styleUrls: ['./tacdialog.component.scss'],
})
export class TacDialog implements OnInit {

  @Input('isModal') isModal: boolean = true;
  @Input('isPrimaryLang') isPrimaryLang: boolean = true;

  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService) { }

  tocdata=undefined

  ngOnInit() {
    this.init();
  }

  async init() {
    let {data, error} = await this.supa.supabase.from('toc').select(this.isPrimaryLang?'lang1toc':'lang2toc').limit(1)
    if (error) {
      this.helper.notify('Could not load the terms and conditions', 'danger');
      console.log(error);
    } else {
      if (data.length == 0) {
        this.tocdata = "No terms and conditions present in the database.";
      } else {
        this.tocdata = this.isPrimaryLang ? data[0].lang1toc : data[0].lang2toc
      }
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  accept() {
    this.modalController.dismiss(true);
  }

}
