import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../../services/helper.service';
import { VhcCsatModule } from '../vhccsatmodule';

@Component({
  selector: 'app-csatanswer',
  templateUrl: './csatanswer.dialog.html',
  styleUrls: ['./csatanswer.dialog.scss'],
})
export class CsatanswerDialog implements OnInit {

  @Input() module: VhcCsatModule;

  constructor(private modalController: ModalController, private helper: HelperService) { }

  dismissModal() {
    this.modalController.dismiss(false, 'cancel');
  }

  nextModal() {
    this.modalController.dismiss(true);
  }

  ngOnInit() {
  }

}
