import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-markdowndialog',
  templateUrl: './markdowndialog.component.html',
  styleUrls: ['./markdowndialog.component.scss'],
})
export class MarkdownDialog implements OnInit {

  @Input("islang2") islang2 = false;
  @Input("mdstring") mdstring = "";

  constructor(private modalController: ModalController) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {}

}
