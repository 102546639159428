/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable max-len */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';
import { FiledisplayComponent, FileDisplayItem } from '../filedisplay/filedisplay.component';
import { FilestructureComponent, folderNode } from '../filestructure/filestructure.component';

@Component({
  selector: 'filebrowser',
  templateUrl: './filebrowser.component.html',
  styleUrls: ['./filebrowser.component.scss'],
})
export class FilebrowserComponent implements AfterViewInit {

  @Input('folder') folder = '';
  
  @Input('category') category = 'brakediscpad';
  @Input('readonly') readonly = false;
  @Input('allowcopy') allowcopy = false;

  @ViewChild('fdisplay') fdisplay: FiledisplayComponent;
  @ViewChild('ftree') ftree: FilestructureComponent;

  @Output('fileclick') fileclick = new EventEmitter<FileDisplayItem>();
  @Output('newfile') newfile = new EventEmitter<number>();
  @Output('copyfile') copyfile = new EventEmitter<FileDisplayItem>();

  /**
   * @description Sets the current viewing mode
   */
  public listMode = false;

  constructor(private supa: SupaService, private helper: HelperService) { }

  ngAfterViewInit() {
    this.init();
  }

  async init() {
    if (this.folder) {
      const fdat = await this.supa.supabase.from('folderstructure').select('*').is('parent', null).eq('category', this.category);
      const datidx = fdat.data.findIndex(f => f.name === this.folder);
      if (datidx >= 0) {
        this.currentRoot = {id: fdat.data[datidx].id};
        this.fileClick({
          isFolder: true,
          name: fdat.data[datidx].name,
          id: fdat.data[datidx].id,
          category: this.category
        });
        return;
      }
    }
    this.loadFiles();
  }

  async itemDragged(event: number[]) {
    if (event.length !== 3) { return; }

    const isFolder = event[0] === 1;
    const fromId = event[1];
    const toId = event[2];

    const l = await this.helper.getLoader();
    l.message = 'Saving changes';
    await l.present();
    await this.supa.supabase.from(isFolder ? 'folderstructure' : this.category).update({parent: toId}).eq('id', fromId);
    l.dismiss();
    this.reload();
  }

  /**
   * @description gets called when users clicks on new file in the toolbar
   * @emits current_parent_id
   */
  public newFile() {
    this.newfile.emit(this.currentRoot.id)
  }

  /**
   * @description reloads files in the tree and display
   */
  public reload() {
    this.ftree.reload();
    this.loadFiles();
  }

  newFolder() {
    this.helper.promptInput("Adding a new folder", "Please enter the name of the new folder", '', async (q)=>{
      if (q.length < 1) {
        this.helper.notify('The name needs to be at least 1 character long', 'warning')
        return;
      } else {
        let {data,error} = await this.supa.supabase.from('folderstructure').insert({
          parent: this.currentRoot.id,
          category: this.category,
          name: q
        });
        if (error) {
          this.helper.notify('Could not save the folder', 'danger')
        }
        this.reload();
      }
    }, "folder name")
  }

  folderEdit(item: FileDisplayItem) {
    this.helper.promptInput('Rename', 'Change folder name', item.name, async (q) => {
      if (q != item.name && q.length >= 1) {
        let {data, error} = await this.supa.supabase.from('folderstructure').update({name: q}).eq('id', item.id).single()
        if (error) {
          this.helper.notify('Could not rename the folder', 'danger')
        }
        this.reload();
      } else if (q.length < 1) {
        this.helper.notify('The name needs to be at least 1 character long', 'warning')
      }
    })
  }

  treeClick(node: folderNode) {
    this.currentRoot = node;
    this.loadFiles()
  }
  
  fileClick(node: FileDisplayItem) {
    if (node.isFolder) {
      this.currentRoot = node;
      this.ftree.setNodeFromFile(node);
      this.loadFiles()
    } else {
      this.fileclick.emit(node)
    }
  }
  
  copyFileClick(node: FileDisplayItem) {
    if (node.isFolder) {
      return
    } else {
      this.copyfile.emit(node)
    }
  }

  async deleteItem(item: FileDisplayItem) {
    await this.supa.supabase.from(item.isFolder ? 'folderstructure' : this.category).delete().eq('id', item.id)
    this.reload();
  }

  currentRoot={id:null};
  currentParentFolderId=-1
  async loadFiles() {
    this.fdisplay.setLoading();

    this.currentParentFolderId = this.currentRoot.id != null ? (await this.supa.supabase.from('folderstructure').select('parent').eq('id', this.currentRoot.id).single()).data?.parent ?? null : null;

    let folderResult;
    if (this.currentRoot.id == null) {
      folderResult = await this.supa.supabase.from('folderstructure').select('id,name').is('parent', this.currentRoot.id).eq('category', this.category)
    } else {
      folderResult = await this.supa.supabase.from('folderstructure').select('id,name').eq('parent', this.currentRoot.id).eq('category', this.category)
    }

    if (folderResult.error) {
      console.log(folderResult.error);
      this.helper.notify('Could not load the folders')
      return;
    }

    let fileResult;
    if (this.currentRoot.id == null) {
      fileResult = await this.supa.supabase.from(this.category).select('id,name').is('parent', this.currentRoot.id)
    } else {
      fileResult = await this.supa.supabase.from(this.category).select('id,name').eq('parent', this.currentRoot.id)
    }

    if (fileResult.error) {
      console.log(fileResult.error);
      this.helper.notify('Could not load the files')
      return;
    }
    this.fdisplay.setFiles([...folderResult.data.map(f => { return {category: this.category, id: f.id, name: f.name, isFolder: true}}), ...fileResult.data.map(f => { return {category: this.category, id: f.id, name: f.name, isFolder: false}})])
    console.log(this.currentRoot);
  }

}
