import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MaintenanceItem } from '../../pages/settings/maintenancetables/maintenanceeditor/maintenanceeditor.component';
import { JSettings, SupaService } from '../../services/supa.service';

@Component({
    selector: 'app-recommendationedit',
    templateUrl: './recomendationedit.dialog.html',
    styles: []
})
export class RecommendationEditDialog {

    // used by tyre module and maintenance module

    @Input('prop') prop: MaintenanceItem;
    @Input('settings') settings: JSettings;
    
    constructor(private modalController: ModalController, private supa: SupaService) {}

    dismissModal() {
        this.modalController.dismiss();
    }

    save() {
        this.modalController.dismiss({
            prop: this.prop
        });
    }

    doReorder(ev: any) {
        console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
        this.prop.recommendations = ev.detail.complete(this.prop.recommendations);
    }

    addAnswer() {
        if (this.prop.recommendations) {
            this.prop.recommendations.push(["",""])
        } else {
            this.prop.recommendations = [["",""]]
        }
    }


    deleteAnswer(index) {
        this.prop.recommendations.splice(index, 1)
    }

    trackByFn(index, item) {
        return index;
    }

}

