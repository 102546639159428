import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagedialogDialog } from '../../../components/imagedialog/imagedialog.dialog';
import { HelperService } from '../../../services/helper.service';
import { VhcTyreModule } from '../vhctyremodule';
import { JSettings } from '../../../services/supa.service';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { TyresizesDialog } from '../tyresizes/tyresizes.dialog';
import { Tyrebrand } from '../../../interfaces/tyrebrand.interface';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { MediaService } from '../../../services/media.service';
import { Part } from '../../../interfaces/part.interface';
import { Job } from '../../../interfaces/job.interface';

@Component({
  selector: 'app-tyreanswer',
  templateUrl: './tyreanswer.dialog.html',
  styleUrls: ['./tyreanswer.dialog.scss'],
})
export class TyreanswerDialog implements OnInit {

  @Input('readonly') readonly = false;
  @Input('tyremodule') tyremodule: VhcTyreModule;
  @Input('settings') settings: JSettings;
  @Input('tyrebrands') tyrebrands: Tyrebrand[] = [];

  helpcontent: NgxTippyProps = {
    allowHTML: true,
    placement: 'bottom',
    trigger: 'click',
    content: '<img style="width: 100%;height: 193px;object-fit: contain;" src="../../../../assets/tiresize.png" alt="" />'
  };

  selectedtyre = 0

  async openSizes(tyre: TyreAnswer) {
    const modal = await this.modalController.create({
      component: TyresizesDialog,
      cssClass: 'doubled-modal'
    });

    await modal.present();
    let { data } = await modal.onDidDismiss();    
    if (data) {
      if(data.size) {
        tyre.size = data.size
      }
    }
  }

  async openRecommendations() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Select a predefined recommendation',
      buttons: [...this.tyremodule.recommendations.map((answ, i) => {
        return {
          text: answ[0],
          role: i.toString()
        }
      }), {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel'
      }]
    });

    await actionSheet.present();
    let { role } = await actionSheet.onDidDismiss();
    if (!(role == "backdrop" || role == "cancel")) {
      const idx = Number.parseInt(role);
      this.tyremodule.data.tyres[this.selectedtyre].recommendation = this.tyremodule.recommendations[idx][0]
      this.tyremodule.data.tyres[this.selectedtyre].recommendation2 = this.tyremodule.recommendations[idx][1]
    }
  }

  copyValues(tyre: TyreAnswer) {
    this.tyremodule.data.tyres.forEach((v,i)=>{
      this.tyremodule.data.tyres[i].size = tyre.size
      this.tyremodule.data.tyres[i].brandid = tyre.brandid
      this.tyremodule.data.tyres[i].loadindex = tyre.loadindex
      this.setAnswer(this.tyremodule.data.tyres[i])
    })
  }

  getBrandName(id) {
    if (id) {
      const res = this.tyrebrands.find(t => t.id == id)
      if (res) {
        return res.name
      }
    }
    return 'Unknown brand'
  }

  // @ConfirmDelete()
  // removeTyre(index) {
  //   this.tyremodule.data.tyres.splice(index, 1);
  // }

  // addTyre() {
  //   if (this.tyremodule.data.tyres.length > 0) {
  //     const lastIdx = this.tyremodule.data.tyres.length-1
  //     this.tyremodule.data.tyres.push({name: this.tyremodule.data.tyres[lastIdx].name+"_copy", value: 0, size: this.tyremodule.data.tyres[lastIdx].size, mm: undefined, loadindex: this.tyremodule.data.tyres[lastIdx].loadindex,brandid:this.tyremodule.data.tyres[lastIdx].brandid, dateAuthorised: ''})
  //   } else {
  //     this.tyremodule.data.tyres.push({name: "LF", value: 0, size: "", mm: undefined, loadindex: "",brandid:undefined, dateAuthorised: ''})
  //   }
  // }

  constructor(private helper: HelperService, private modalController: ModalController, public platform: Platform, 
    private actionSheetController: ActionSheetController, private androidPermissions: AndroidPermissions, private media: MediaService, private photoViewer: PhotoViewer) { }

  ngOnInit() {
    console.log(this.tyremodule);
    if (!(this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p)))) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      );
    }
  }

  dismissModal() {
    this.modalController.dismiss(false, 'cancel');
  }
  
  nextModal() {
    this.modalController.dismiss(true);
  }

  brandDetails(id) {
    if (id == undefined) {
      return "-"
    }
    const b = this.tyrebrands.find(b => b.id == id);
    if (b) {
      return "Expires after " +  b.expiredays + " days (" + (Math.round((b.expiredays / 365)*10)/10) + " years), red = " + b.reddepthmm + "mm, amber = " + b.amberdepthmm + "mm"
    }
    return "Brand does not exist anymore"
  }

  async addPicture() {
    const picture = await this.media.takePicture()
    if (picture == 'rejected') {
      return;
    }
    this.tyremodule.data.tyres[this.selectedtyre].images.push(picture);
  }

  async openPicture(imgstr) {
    if (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) {
      //Desktop
      let { data } = await this.helper.createAndOpenModal(ImagedialogDialog, {
        Url: imgstr
      }, 'doubled-modal auto-height-image');
    } else {
      // android / ios
      this.photoViewer.show(imgstr);
    }
  }

  @ConfirmDelete()
  deleteImg(i) {
    this.tyremodule.data.tyres[this.selectedtyre].images.splice(i,1)
  }

  setAnswer(tyre:TyreAnswer) {
    const brandIdx = this.tyrebrands.findIndex(b => b.id == tyre.brandid)
    if (brandIdx < 0) return;
    if (tyre.mm == null) return;
    if (tyre.mm <= this.tyrebrands[brandIdx].reddepthmm) {
      tyre.value = 1
    } else if (tyre.mm <= this.tyrebrands[brandIdx].amberdepthmm) {
      tyre.value = 2
    } else {
      tyre.value = 3
    }
  }

}

export interface TyreAnswerData {
  tyres: TyreAnswer[],
  portalid?: string,
}

export interface TyreAnswer {
  name: string;
  value: number;
  brandid: number;
  size: string;
  loadindex: string;
  mm: number;
  dateAuthorised: string;
  images: string[];
  recommendation: string;
  recommendation2?: string;
  portalid?: string;
  auth?:boolean,
  jobs:Job[],
  parts:Part[],
  price: number
}