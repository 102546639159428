/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';

@Component({
  selector: 'filedisplay',
  templateUrl: './filedisplay.component.html',
  styleUrls: ['./filedisplay.component.scss'],
})
export class FiledisplayComponent implements OnInit {

  @Input('category') category='brakediscpad'
  @Input('listMode') asList = false
  @Input('allowcopy') allowcopy = false;
  @Input('currentParentFolderId') currentParentFolderId = -1;
  @Input('hideParentFolder') hideParentFolder = true;

  @Output('onClick') onClick = new EventEmitter<FileDisplayItem>()
  @Output('onDelete') onDelete = new EventEmitter<FileDisplayItem>()
  @Output('onCopy') onCopy = new EventEmitter<FileDisplayItem>()
  @Output('onEditFolder') onEditFolder = new EventEmitter<FileDisplayItem>()
  @Output('onDragged') onDragged = new EventEmitter<number[]>(); // [0] -> isFolder<0,1>; [1] -> file_id; [2] -> new_parent_id

  files : BehaviorSubject<FileDisplayItem[]> = new BehaviorSubject<FileDisplayItem[]>(undefined);

  constructor() { }

  ngOnInit() {
  }

  public labelClick(event, file: FileDisplayItem) {
    if (event.target.localName != 'ion-icon') {
      this.onClick.emit(file);
    }
  }

  public setLoading() {
    this.files.next(undefined);
  }
  
  public setFiles(files: FileDisplayItem[]) {
    files.sort((a,b)=> (+a.isFolder) + (+b.isFolder) || a.name.localeCompare(b.name));
    this.files.next(files);
  }

  public async editItem(item: FileDisplayItem) {
    this.onEditFolder.emit(item);
  }
  
  public async copyItem(item: FileDisplayItem) {
    this.onCopy.emit(item);
  }

  @ConfirmDelete()
  public deleteItem(item: FileDisplayItem) {
    this.onDelete.emit(item);
  }

  draggingfile = undefined;
  onDragStart(event: DragEvent, file: FileDisplayItem) {
    this.draggingfile = JSON.parse(JSON.stringify(file));
  }

  onDragOver(event: DragEvent, file: FileDisplayItem) {
    if (file === null || file.isFolder && (!this.draggingfile.isFolder || file.id !== this.draggingfile.id)) {
      event.preventDefault();
    }
  }

  onDrop(event: DragEvent, file: FileDisplayItem) {
    if (file === null) {
      this.onDragged.emit([this.draggingfile.isFolder ? 1 : 0, this.draggingfile.id, this.currentParentFolderId]);
    } else if (file.isFolder && (!this.draggingfile.isFolder || file.id !== this.draggingfile.id)) {
      this.onDragged.emit([this.draggingfile.isFolder ? 1 : 0, this.draggingfile.id, file.id]);
    }
  }

}

export interface FileDisplayItem
{
  isFolder: boolean,
  name:string,
  id: number,
  category: string,
  icon?: string
}