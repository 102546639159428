import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';
import { FileObject } from '../../../../../node_modules/@supabase/storage-js';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { VideodialogDialog } from '../../../components/videodialog/videodialog.dialog';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
})
export class StorageComponent implements OnInit {

  MAX_VID_AGE = 7 * 24 * 60 * 60 * 1000 // 7 days
  newfiles: FileObject[] = []
  oldfiles: FileObject[] = []
  idsdownloaded:string[]=[]
  notLoading = false;

  constructor(private supa: SupaService, private helper: HelperService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    let {data,error} = await this.supa.supabase.storage.from('internaldesign').list('uservids');
    const now = new Date().getTime()
    let _newfiles: FileObject[] = []
    let _oldfiles: FileObject[] = []
    data.forEach(v => {
      if (now - new Date(v.created_at).getTime() > this.MAX_VID_AGE) {
        if ((v.metadata?v.metadata as any:{size:0}).size > 2) _oldfiles.push(v);
      } else {
        if ((v.metadata?v.metadata as any:{size:0}).size > 2) _newfiles.push(v);
      }
    })
    this.oldfiles = _oldfiles;
    this.newfiles = _newfiles;
    this.notLoading = true;
  }

  async play(file: FileObject) {
    let {data,error} = await this.supa.supabase.storage.from('internaldesign').getPublicUrl('uservids/'+file.name)
    this.helper.createAndOpenModal(VideodialogDialog, {url: data.publicURL})
  }

  async download(file: FileObject) {
    let l = await this.helper.getLoader();
    l.present()

    let {data,error} = await this.supa.supabase.storage.from('internaldesign').download('uservids/'+file.name)

    var blobUrl = URL.createObjectURL(data);
    l.dismiss();

    var link = document.createElement("a"); // Or maybe get it from the current document
    link.href = blobUrl;
    link.download = file.name
    link.innerHTML = "Click here to download the file";
    link.click();
    
    this.idsdownloaded.push(file.id)
  }
  
  @ConfirmDelete()
  async doDelete(file: FileObject) {
    for (let i = this.idsdownloaded.length-1; i >= 0; i--) {
      if (file.id == this.idsdownloaded[i]) {
        this.idsdownloaded.splice(i, 1);
      }
    }
    let l = await this.helper.getLoader();
    l.present()
    let {data,error} = await this.supa.supabase.storage.from('internaldesign').remove(['uservids/'+file.name]);
    l.dismiss();
    this.helper.notify(file.name + " was deleted.")
    this.newfiles.forEach((v,i) => {if (v.id == file.id){ this.newfiles.splice(i,1) }});
    this.oldfiles.forEach((v,i) => {if (v.id == file.id){ this.oldfiles.splice(i,1) }});
  }

  @ConfirmDelete('Do you want to delete videos older than 1 week?', 'Clean')
  @ConfirmDelete('Do you want to delete videos older than 1 week?', 'Please confirm again')
  async clean() {
    let l = await this.helper.getLoader();
    l.present()
    let {data,error} = await this.supa.supabase.storage.from('internaldesign').remove(this.oldfiles.map(f => 'uservids/'+f.id));
    l.dismiss();
    this.helper.notify("Old files were deleted")
    this.oldfiles = [];
    this.idsdownloaded = [];
  }

  getSizeHrf(size) {
    let size_hrf = "";
    if(size < 1024) {
      //----BT
      size_hrf = size + " B"; 
    } else if(size < 1048576) {
      //----KB
      size_hrf = ( Math.round((size / 1024 ) * 100) / 100 )+ " KB"
    } else if(size < 1073741824) {
      //---MB
      size_hrf = ( Math.round((size / 1048576 ) * 100) / 100) + ' MB'
    } else {
      //---GB
      size_hrf = ( Math.round(( size / 1073741824  ) * 100)/ 100)  + ' GB'
    }
    return size_hrf
  }

}
