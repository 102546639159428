/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Vehicle } from '../../../interfaces/vehicle.interface';
import { HelperService } from '../../../services/helper.service';
import { SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-vehicleeditor',
  templateUrl: './vehicleeditor.component.html',
  styleUrls: ['./vehicleeditor.component.scss'],
})
export class VehicleeditorComponent implements OnInit {

  vIn = undefined
  vehicle: Vehicle = undefined

  vhchistory = undefined
  managevehicles = false

  exconfig;

  constructor(private helper: HelperService, private supa: SupaService, private route: ActivatedRoute, private router: Router) { }
  ngOnInit() {
    this.managevehicles = (this.supa.LoginObject.value?.permissions.includes('VhcVehiclesManage') == true)
    this.route.params.subscribe(params => {
      if (Object.keys(params).includes('vin')) {
        this.vIn = params.vin;
        this.supa.supabase.from('vehicle').select('*').eq('vin', params.vin).single().then(async v => {
          if (v.error) {
            console.log(v.error);
            this.helper.notify('Could not load this vehicle', 'danger')
            this.router.navigate(['vehicles'])
          } else {

            this.supa.ExternalConfig().then(async ex => {
              this.exconfig = ex;

              if (this.exconfig.name === 'Keyloop') {
                const askupdate: any = {};
                const keyloopvehicle = await this.supa.supabase.rpc('get_vehicle_info', {vin: v.data.vin});
                console.log(keyloopvehicle);

                if (v.data.licenseplate !== (keyloopvehicle.body as any).identification.licensePlate) {
                  askupdate.licenseplate = (keyloopvehicle.body as any).identification.licensePlate;
                }
                if (v.data.cartype !== (keyloopvehicle.body as any).baseDetails.description + ' ' + (keyloopvehicle.body as any).baseDetails.exteriorColor) {
                  askupdate.cartype = (keyloopvehicle.body as any).baseDetails.description + ' ' + (keyloopvehicle.body as any).baseDetails.exteriorColor;
                }
                if (v.data.enginecode !== (keyloopvehicle.body as any).technicalData.engineConcept) {
                  askupdate.enginecode = (keyloopvehicle.body as any).technicalData.engineConcept;
                }
                if (v.data.yearofbuild !== (keyloopvehicle.body as any).baseDetails.modelYear) {
                  askupdate.yearofbuild = (keyloopvehicle.body as any).baseDetails.modelYear;
                }

                if (Object.keys(askupdate).length > 0) {
                  const doupdate = await this.helper.promptYesNo('Update from Keyloop', 'Would you like to update the vehcle from keyloop?', ()=>{});
                  if (doupdate) {
                    Object.keys(askupdate).forEach(f => {
                      v.data[f] = askupdate[f];
                    });
                    const didupdate = await this.supa.supabase.from('vehicle').update(askupdate).eq('vin', this.vIn);
                    if (didupdate.error) {
                      this.helper.notify("Could not update the vehicle", "danger");
                      return;
                    }
                  }
                }
              }

              this.vehicle = v.data;

              this.supa.supabase.from('vhcrecord').select('blueprintname,created_at,id,isdone,deactivated').eq('vin', this.vehicle.vin).order('id', {ascending: false}).then(v => {
                if (v.error) {
                  this.helper.notify("Could not load VHC History");
                } else {
                  this.vhchistory = v.data;
                }
              })  
            });

          }

        }, e => {
          console.log(e);
          this.helper.notify('Could not load this vehicle', 'danger')
          this.router.navigate(['vehicles'])
        })
      } else {
        this.router.navigate(['vehicles'])
      }
    });
  }

  newVhc() {
    this.helper.createNewRecord(this.vehicle.vin);
  }

  openVhc(id:number) {
    this.router.navigate(['vhc-record', {id: id}])
  }

  onBack() {
    this.router.navigate(['/vehicles'])
  }

  async onDelete() {
    if (!this.vIn) {
      this.router.navigate(['/vehicles/'])
      return
    }
    let { data, error } = await this.supa.supabase.from('vehicle').delete().eq('vin', this.vIn);
    if (error) {
      this.helper.notify("Could not delete the item", "danger");
      return;
    }
    this.router.navigate(['/vehicles/'])
  }

  async onSave() {
    if (this.vIn) {
      let { data, error } = await this.supa.supabase.from('vehicle').update(this.vehicle).eq('vin', this.vIn);
      if (error) {
        this.helper.notify("Could not save the item", "danger");
        return;
      }
    } else {
      this.router.navigate(['/vehicles/'])
      return
    }
    this.helper.promptYesNo('Vehicle was saved.', 'Dow you want to leave the editor?', () => {
      this.router.navigate(['/vehicles/'])
    }, 'Leave editor', 'Keep editing')
  }

}
