import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';

@Component({
  selector: 'app-locationeditor',
  templateUrl: './locationeditor.component.html',
  styleUrls: ['./locationeditor.component.scss'],
})
export class LocationeditorComponent implements OnInit {

  loadingDone = false
  settings: JSettings = undefined;
  lCode = undefined
  name = ""
  name2 = ""
  address = ""
  iqplanning = ""

  constructor(private helper: HelperService, private supa: SupaService, private route: ActivatedRoute, private router: Router) { }

  onBack() {
    this.helper.back();
  }

  async onDelete() {
    if(!this.lCode) {
      this.helper.notify("This has never been saved yet", "warning");
      return
    }
    let { data, error } = await this.supa.supabase.from('jlocation').delete().eq('code', this.lCode);
    if (error) {
      this.helper.notify("Could not delete the layout", "danger");
      return;
    }
    this.router.navigate(['/users/locations/'])
  }

  async onSave() {
    let newData = {
      code: this.lCode,
      name: this.name,
      name2: this.name2,
      address: this.address,
      iqplanning: this.iqplanning
    }
    if (this.isUpdate) {
      delete newData.code;
      let { data, error } = await this.supa.supabase.from('jlocation').update(newData).eq('code', this.lCode);
      if (error) {
        this.helper.notify("Could not save the layout", "danger");
        return;
      }
    } else {
      let { data, error } = await this.supa.supabase.from('jlocation').insert(newData).single()
      if (error) {
        this.helper.notify("Could not save the location", "danger");
        return;
      }
      this.lCode = data.code
    }
    this.isUpdate = false;
    this.helper.promptYesNo('Location was saved.', 'Dow you want to leave the editor?', () => {
      this.router.navigate(['/users/locations/'])
    }, 'Leave editor', 'Keep editing')
  }

  ngOnInit() {
    this.init();
  }
  
  isUpdate = false;
  async init() {
    this.settings = await this.supa.Settings();
    this.route.params.subscribe(params => {
      console.log(params, Object.keys(params));
      if (Object.keys(params).includes('code')) {
        this.lCode = params.code;
        this.loadData();
      } else {
        this.loadingDone = true
      }
    });
  }

  async loadData() {
    let { data, error } = await this.supa.supabase.from('jlocation').select("*").eq('code', this.lCode).single();
    if (error) {
      this.helper.notify("Could not load the location", "danger");
      this.router.navigate(['/users/locations/'])
      return;
    }
    this.isUpdate = true;
    this.name = data.name;
    this.name2 = data.name2;
    this.address = data.address;
    this.iqplanning = data.iqplanning;
    this.loadingDone = true;
  }

}
