import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { brakediscpad } from '../../../interfaces/brakediscpad.interface';
import { Tyrebrand } from '../../../interfaces/tyrebrand.interface';
import { HelperService } from '../../../services/helper.service';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';

@Component({
  selector: 'app-mpiscreen',
  templateUrl: './mpiscreen.dialog.html',
  styleUrls: ['./mpiscreen.dialog.scss'],
})
export class MpiscreenDialog implements OnInit {

  @Input('description') description: string
  @Input('completed') completed: string
  @Input('mpimodules') mpimodules: Vhcmodule[]
  @Input('tyrebrands') tyrebrands:Tyrebrand[]
  @Input('brakediscpads') brakediscpads:brakediscpad[]

  constructor(private modalController: ModalController, private helper: HelperService) { }

  ngOnInit() {
    console.log(this.mpimodules);
  }

  @ConfirmDelete()
  removeCSell(module, i) {
    module.data.splice(i,1)
  }

  async openQuestion(midx: number, qindex: number) {
    if (qindex == 0) {
      this.helper.notify('Opening questionblock ' + this.mpimodules[midx].name)
    }
    let result = await this.mpimodules[midx].openAnswer(qindex);
    if (result != undefined) {
      if (this.mpimodules[midx].questions.length > qindex+1) {
        this.openQuestion(midx, qindex+1)
      } else {
        this.openModule(midx+1)
      }
    }
  }

  async openModule(midx,wasauto=false) {
    if (midx >= this.mpimodules.length) {
      this.helper.notify('You have reached the end of the list')
      return;
    }
    if (this.mpimodules[midx].type == 'Cross_cell' && wasauto) {
      this.helper.notify('You have reached the sales module')
      return;
    }
    if (this.mpimodules[midx].type == 'Instruction') {
      this.helper.notify('instruction: ' + this.mpimodules[midx].name)
      this.openModule(midx+1)
      return;
    }
    if (this.mpimodules[midx].type == 'Questionblock') {
      this.openQuestion(midx, 0)
      return;
    }

    let ans;
    if (this.mpimodules[midx].type == 'Tyres') {
      ans = await this.mpimodules[midx].openAnswer(this.tyrebrands)
    } else if (this.mpimodules[midx].type == 'Brakes') {
      ans = await this.mpimodules[midx].openAnswer(this.brakediscpads)
    } else {
      ans = await this.mpimodules[midx].openAnswer()
    }
    if (ans) {
      this.openModule(midx+1,true)
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  
  @ConfirmDelete("Please note that all data regarding this module will be lost", 'Do you want to reset the maintenance table?')
  resetMaintenance() {
    this.modalController.dismiss({resetmt: true});
  }
  
  saveModal() {
    this.modalController.dismiss({savedata: true});
  }

  complete() {
    this.helper.promptYesNo('Do you want to complete the multi point inspection?', 'The multi point inspection will be finished and the next step will unlock.', ()=>{
      this.modalController.dismiss({completestep: true});
    }, 'Complete step', 'Cancel')
  }

}
