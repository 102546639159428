import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Folder } from '../../../interfaces/folder.interface';
import { SupaService } from '../../../services/supa.service';
import { FileDisplayItem } from '../filedisplay/filedisplay.component';

@Component({
  selector: 'filestructure',
  templateUrl: './filestructure.component.html',
  styleUrls: ['./filestructure.component.scss'],
})
export class FilestructureComponent implements OnInit {

  @Input('category') category = "";

  folders=undefined
  treeControl = new NestedTreeControl<folderNode> (node => node.children);

  @Output('onselect') OnSelect: EventEmitter<folderNode> = new EventEmitter<folderNode>();
  public SelectedNode = {id:null};

  constructor(private supa: SupaService) { }

  ngOnInit() {
    this.init()
  }

  selectNode(node) {
    this.SelectedNode = node;
    this.OnSelect.emit(node);
  }

  // expand all untill ID and set selected to node with this id
  triesleft = 10;
  setNodeFromFile(node: FileDisplayItem|any) {
    if (this.triesleft < 0) {
      return;
    }
    if (!this.folders) {
      setTimeout(()=>{this.setNodeFromFile(node);}, 500);
      return;
    }
    console.log(this.triesleft);
    this.triesleft = 10;
    this.treeControl.expansionModel.clear()
    let treenode = this.expand_till_id(node.id, {id: undefined, children: this.folders._data});
    this.SelectedNode = treenode;
  }

  expand_till_id(id, tree) {
    if (tree.id == id) {
      this.treeControl.expansionModel.select(tree)
      return tree;
    }
    
    if (tree.children) {
      for (const child of tree.children) {
        const founde = this.expand_till_id(id, child);
        if (founde != null) {
          this.treeControl.expansionModel.select(tree)
          return founde
        }
      }
    }
    return null;
  }

  node_from_id(id, tree) {
    if (tree.id == id) {
      return tree;
    }
    
    if (tree.children) {
      for (const child of tree.children) {
        const founde = this.node_from_id(id, child);
        if (founde != null) {
          return founde
        }
      }
    }
    return null;
  }
  
  public hasChild = (_: number, node: folderNode) => !!node.children && node.children.length > 0;
  
  async init() {
    let { data, error } = await this.supa.supabase
      .from('folderstructure')
      .select('*')
      .eq('category', this.category)
      .order('name')
    this.folders = new ArrayDataSource(this.list_to_tree(data as Folder[]));
    if (this.SelectedNode.id != null) {
      this.setNodeFromFile({id:this.SelectedNode.id})
    }
  }

  public reload() {
    this.init();
  }

  list_to_tree(list) {

    var map = {}, node, roots = [], i;
    
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== null) {
        list[map[node.parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }

    roots.unshift({
      id: null,
      name: 'Root',
      children: []
    })

    return roots;
  }
}

export interface folderNode {
  id: number;
  name: string;
  category: string;
  parent: number;
  children?: folderNode[];
}