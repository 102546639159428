import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { IframeWrapperComponent } from '../iframewrapper/iframewrapper.component';

@Component({
  selector: 'app-vehiclevisuals',
  templateUrl: './vehiclevisuals.component.html',
  styleUrls: ['./vehiclevisuals.component.scss'],
})
export class VehiclevisualsDialog implements OnInit {

  constructor(private modalController: ModalController, private supa: SupaService, private http: HttpClient, private loadingController: LoadingController, private helper: HelperService) { }

  VISU_LANG = "AR" // en_UK
  vvdata: any = {};

  catalog: BehaviorSubject<VideoCategory[]|undefined> = new BehaviorSubject<VideoCategory[]|undefined>(undefined);

  @Input('preselect') preSelect = '';

  currentVideo: VideoData | undefined= undefined

  ngOnInit() {
    this.supa.supabase.from<VideoCategory>('vehiclevisuals').select('*').then(v => {
      if (v.data) {
        for(let c = 0; c < v.data.length && this.currentVideo == undefined; c++) {
          for (let i = 0; i < v.data[c].children.length && this.currentVideo == undefined; i++) {
            if (v.data[c].children[i].id == this.preSelect) {
              this.currentVideo = v.data[c].children[i];
            }
          }
        }

        this.catalog.next(v.data);

      } else {
        this.catalog.next([]);
      }
    })
  }

  async play() {
    if (this.currentVideo) {
      let vdetails: any = await this.getVideo(this.currentVideo.id);
      if (vdetails) {
        this.helper.createAndOpenModal(IframeWrapperComponent, { url: vdetails.video_url, title: vdetails.video_title, color: 'dark' }, "fullscreen-modal", true)
      }
    }
  }

  @ConfirmDelete('This might take a lot of time.', 'Do you want to reload the video cache?')
  async reload() {
    const loading = await this.loadingController.create({
      message: 'Downloading catalog',
      spinner: 'bubbles',
      backdropDismiss: false
    });
    await loading.present();
    this.vvdata = await this.getCatalog();
    let videodata: VideoCategory[] = []
    let idcount = 0;
    for(let i = 0; i < Object.keys(this.vvdata).length; i++) {
      const id = Object.keys(this.vvdata)[i]
      idcount++
      let children: VideoData[] = [{id: id, name: this.vvdata[id].en_UK, description: this.vvdata[id].description_en_UK}]
      if (this.vvdata[id].children) {
        for (let x = 0; x < Object.keys(this.vvdata[id].children).length; x++) {
          const cid = Object.keys(this.vvdata[id].children)[x]
          idcount++
          children.push({id: cid, name: this.vvdata[id].children[cid].en_UK, description: this.vvdata[id].children[cid].description_en_UK});

          if (this.vvdata[id].children[cid].children) {
            for (let y = 0; y < Object.keys(this.vvdata[id].children[cid].children).length; y++) {
              const ccid = Object.keys(this.vvdata[id].children[cid].children)[y];
              idcount++
              children.push({id: ccid, name: this.vvdata[id].children[cid].children[ccid].en_UK, description: this.vvdata[id].children[cid].children[ccid].description_en_UK});
            }
          }
        }
      }
      videodata.push({
        children: children,
        id: id,
        name: this.vvdata[id].en_UK
      })
    }
    let gotimg = 0;
    for (let i = 0; i < videodata.length; i++) {
      for (let c = 0; c < videodata[i].children.length; c++) {
        loading.message = `Retrieving video ${gotimg++} of ${idcount}`;
        videodata[i].children[c].thumbnail = await this.getVideoThumbail(videodata[i].children[c].id)
        videodata[i].children[c].thumbnail += ''
      }
    }
    loading.message = 'Saving to database'
    await this.supa.supabase.from('vehiclevisuals').delete().neq('id', 0)
    await this.supa.supabase.from('vehiclevisuals').insert([...videodata], { returning: 'minimal'})
    loading.dismiss();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  select() {
    if (this.currentVideo) {
      this.modalController.dismiss({
        id: this.currentVideo.id
      });
    }
  }

  private async getCatalog() {
    var formdata = new FormData();
    formdata.append("subscriber_login_id", "pwouters");
    formdata.append("subscriber_password", "oxEEfISK3v");
    formdata.append("api_key", "j1a3a7ylxugm");
    formdata.append("moduleName", "animation");
    formdata.append("methodName", "getAnimationDetails");
    formdata.append("lang", "en_UK");

    return await this.http.post("https://iqmotive.vehiclevisuals.com/api/animation_link/api/api.php", formdata).toPromise()
  }

  private async getVideoThumbail(id) {
    var formdata = new FormData();
    formdata.append("subscriber_login_id", "pwouters");
    formdata.append("subscriber_password", "oxEEfISK3v");
    formdata.append("api_key", "j1a3a7ylxugm");
    formdata.append("moduleName", "animation");
    formdata.append("methodName", "getVideoDetails");
    formdata.append("lang", "en_UK");
    formdata.append("brand", "generic");
    formdata.append("part_id", id);

    let v = await this.http.post("https://iqmotive.vehiclevisuals.com/api/animation_link/api/api.php", formdata).toPromise()
    return (v[Object.keys(v)[0]].homepage_image ? (v[Object.keys(v)[0]].homepage_image == 'https://cdn.vehiclevisuals.com/' ? '/assets/nopreview.png' : v[Object.keys(v)[0]].homepage_image) : '/assets/nopreview.png')
  }

  async getVideo(pid) {
    return this.http.get(`https://iqmotive.vehiclevisuals.com/api/animation_link/api/api.php?api_key=2v1a3wxxd5gs&moduleName=emailananimation&methodName=getStreamingLink&part_id=${pid}&driverside=l&lang=${this.VISU_LANG}&track_type=email`).toPromise();
  }
}

export interface VideoCategory {
  id:string,
  name: string,
  children: VideoData[]
}

export interface VideoData {
  name: string;
  id: string;
  description: string;
  thumbnail?: string;
}