import { Component, Input, OnInit } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-imagedialog',
  templateUrl: './imagedialog.dialog.html',
  styleUrls: ['./imagedialog.dialog.scss'],
})
export class ImagedialogDialog implements OnInit {

  @Input() Url: string;

  constructor(private modalCtrl : ModalController, private helper: HelperService) {
  }

  ngOnInit() {
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
