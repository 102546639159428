import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable, Injector, NgZone } from "@angular/core";

declare var HUBID;

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(@Inject(Injector) private injector: Injector) {
        super();
    }

    private get httpClient(): HttpClient {
        return this.injector.get(HttpClient);
    }

    public handleError(error: any): void {
        const message = error?.message || 'Undefined client error';
        const name = error?.name || "";
        const cause = error?.cause || "";
        const fileName = error?.fileName || "";
        const lineNumber = error?.lineNumber || "";
        const stack = error?.stack || error.url || "-";
        let hubobject: HubObject = {
            product_id: HUBID,
            error:stack,
            gelezen:false,
            path:lineNumber,
            body:name,
            error_name:message,
            location:fileName,
            version:cause
        }
        // console.table(hubobject);
        this.postError(hubobject);

        super.handleError(error);
    }

    postError(errorobject: HubObject) {
        this.httpClient.post( 'https://alcrveshtambcsaxrded.supabase.co/rest/v1/iqplanningerrors' , errorobject, {
            headers: new HttpHeaders({
                'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFsY3J2ZXNodGFtYmNzYXhyZGVkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTcwMjMxMjIsImV4cCI6MTk3MjU5OTEyMn0.7X2FfwNvoU0V3aeOFOyso3ZhS5fY4d0pEak6DfExfCQ',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFsY3J2ZXNodGFtYmNzYXhyZGVkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTcwMjMxMjIsImV4cCI6MTk3MjU5OTEyMn0.7X2FfwNvoU0V3aeOFOyso3ZhS5fY4d0pEak6DfExfCQ',
                'content-type':'application/json',
                'Prefer': 'return=minimal'
            })
        }).toPromise()
    }

}
export interface HubObject {
    product_id: number;
    error: string;
    gelezen: boolean;
    path?: string;
    body: string;
    error_name: string;
    location: string;
    version: string

}