import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jjj-crumbtrail',
  templateUrl: './crumbtrail.component.html',
  styleUrls: ['./crumbtrail.component.scss'],
})
export class CrumbtrailComponent implements OnInit {


  @Input('crumb') crumbItems = [["Page subtitle"]];

  constructor() { }

  ngOnInit() {}

}
