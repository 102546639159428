import { _ra } from "../../functions";
import { HelperService } from "../../services/helper.service";
import { InputField } from "../../services/new-edit.dialog";
import { SupaService } from "../../services/supa.service";
import { Vhcmodule } from "../vhcmodule";

export class VhcInstructionModule extends Vhcmodule
{
    visibleForCustomer = false

    constructor(helper: HelperService, supa: SupaService) {
        super(helper, supa);
        this.type = "Instruction";
        this.name = "Your instruction here";
    }

    getName() {
        return (this.visibleForCustomer ? 'Visible for customer' : 'Internal only');
    }

    public async openEditor(): Promise<void> {
        await this.loadSettings();

        let inputarr: InputField[] = [{ type: 'text', label: 'Change the display name ('+this.settings.lang1name+')', value: this.name, name: 'name' }]
        if (this.settings.lang2enabled) {
            inputarr.push({ type: 'text', label: 'Change the display name ('+this.settings.lang2name+')', value: this.name2, name: 'name2' });
        }
        inputarr.push({ type: 'boolean', label: 'Visible to the customer', value: this.visibleForCustomer, name: 'visibleForCustomer' });

        let {data} = await this.helper.presentNewEditDialog('Edit note / instruction', inputarr);
        console.log(data);
        if (data) {
            this.name = data.data.name;
            this.name2 = data.data.name2;
            this.visibleForCustomer = data.data.visibleForCustomer;
        }
    }

    getHtmlVersion(param:any=undefined,primarylang=false) {
        if (!this.visibleForCustomer) {
            return null;
        }
        return `
        <p>${primarylang?this.name:this.name2}</p>
        `;
    }

    getPdfVersion(param:any=undefined,primarylang=false) {
        return {
            table: {
                widths: ['*'],
                body: [
                    [{ text: primarylang ? this.name : _ra(this.name2), fillColor: "#efefde", margin: 10 }]
                ],
            },
            margin: [0, 0, 0, 10],
            layout: 'noBorders'
        }
    }

    public toDatabase(includedata=false) {
        return {name: this.name, name2: this.name2, visibleForCustomer: this.visibleForCustomer, type: this.type, dateAuthorised: this.dateAuthorised}
    }
    
    public fromDatabase(obj, includedata=false) {
        this.name = obj.name
        this.name2 = obj.name2
        this.visibleForCustomer = obj.visibleForCustomer;
        this.dateAuthorised = obj.dateAuthorised;
    }
}