import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { JSettings } from '../../../services/supa.service';
import SignaturePad from 'signature_pad';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-siganswer',
  templateUrl: './siganswer.dialog.html',
  styleUrls: ['./siganswer.dialog.scss'],
})
export class SiganswerDialog implements AfterViewInit, OnInit {

  @Input('signature') signature: string;
  @Input('stext') stext: string;
  @Input('settings') settings: JSettings;

  @ViewChild('canvas', { static: true }) signaturePadElement;

  signaturePad: SignaturePad;

  constructor(private modalController: ModalController, private helper: HelperService) { }

  dismissModal() {
    this.modalController.dismiss(false, 'cancel');
  }
  
  nextModal() {
    this.modalController.dismiss(true);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.fromDataURL(this.signature)
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

}
