import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { JSettings, SupaService } from '../../../services/supa.service';
import { VHCQuestion } from '../vhcquestionblockmodule';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagedialogDialog } from '../../../components/imagedialog/imagedialog.dialog';
import { HelperService } from '../../../services/helper.service';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { MediaService } from '../../../services/media.service';
import { IframeWrapperComponent } from '../../../components/iframewrapper/iframewrapper.component';
import { VideodialogDialog } from '../../../components/videodialog/videodialog.dialog';
import { SpeachService } from '../../../services/speach.service';
import { Subscription } from 'rxjs';
import { Howl } from 'howler';
import { MyAudio } from 'iq-audio-recorder-lib';

@Component({
  selector: 'app-qbanswer',
  templateUrl: './qbanswer.dialog.html',
  styleUrls: ['./qbanswer.dialog.scss'],
})
export class QbanswerDialog implements OnInit, OnDestroy {

  @Input('readonly') readonly = false;
  @Input('question') question: VHCQuestion;
  @Input('settings') settings: JSettings;

  sound = new Howl({
    src: ['/assets/pleaseperforminspection.mp3']
  });

  constructor(private actionSheetController: ActionSheetController,
        private helper: HelperService,
        private modalController: ModalController,
        public platform: Platform,
        private androidPermissions: AndroidPermissions,
        private media: MediaService,
        private photoViewer: PhotoViewer,
        private supa: SupaService,
        private speach: SpeachService
    ) { 
  }

  subs:Subscription[] = [];

  ngOnInit() {
    this.subs.push(this.speach.CommandObservable.subscribe(v => {
      const command = v.substring(13);
      switch (command) {
        case 'Red':
          this.question.value=1;this.nextModal();
          break;
        case 'Amber':
          this.question.value=2;this.nextModal();
          break;
        case 'Green':
          this.question.value=3;this.nextModal();
          break;
        case 'Add Image':
          this.addPicture();
          break;
        case 'Add Video':
          this.addVideo();
          break;
        case 'What can I do':
          this.wcid();
          break;
      }
    }));
    if (!(this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p)))) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => {
          console.log('Has permission?', result.hasPermission)
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      );
    }
  }

  wcid() {
    this.sound.play();
  }

  ngOnDestroy() {
    this.stopPlaying();
    this.subs.forEach(s => {
      s.unsubscribe();
    });
  }

  mText() {
    switch (this.question.maintenancenote) {
      case '.':
        return 'Do always';
      case 'T':
        return 'Tighten';
      case 'A':
        return 'Adjust';
      case 'C':
        return 'Clean';
      case 'R':
        return 'Replace';
      case 'I':
        return 'Inspect';
      default:
        return 'Inspect'; // Should never reach this default case; but I added inspect here to be safe ;)
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  nextModal() {
    this.modalController.dismiss(true);
  }

  async openRecommendations() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Select a predefined recommendation',
      buttons: [...this.question.answers.map((answ, i) => {
        return {
          text: answ[0],
          role: i.toString()
        }
      }), {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel'
      }]
    });

    await actionSheet.present();
    let { role } = await actionSheet.onDidDismiss();
    if (!(role == "backdrop" || role == "cancel")) {
      const idx = Number.parseInt(role);
      this.question.recommendation = this.question.answers[idx][0]
      this.question.recommendation2 = this.question.answers[idx][1]
    }
  }

  async addPicture() {
    if (!this.question.images) this.question.images = [];
    const picture = await this.media.takePicture()
    if (picture == 'rejected') {
      return;
    }
    this.question.images.push(picture);
  }

  @ConfirmDelete()
  deleteImg(i) {
    this.question.images.splice(i, 1)
  }

  async addVideo() {
    if (!this.question.videos) this.question.videos = [];
    let path = await this.media.takeVideo();
    if (path) {
      this.question.videos.push(path)
    }
  }

  currentHowl:undefined|Howl = undefined
  currentAudioPlaying = undefined
  playAudio(audioIdx) {
    this.stopPlaying();
    this.currentHowl = new Howl({
      src: [this.question.audio[audioIdx]],
      autoplay: true,
      onend: ()=> {
        this.stopPlaying();
      }
    });
    this.currentAudioPlaying = audioIdx;
  }

  stopPlaying() {
    this.currentAudioPlaying = undefined;
    if (this.currentHowl !== undefined) {
      this.currentHowl?.stop();
    }
  }

  addAudio(audioEvent: MyAudio) {
    this.stopPlaying();
    if (!this.question.audio) this.question.audio = [];
    this.question.audio.push(audioEvent.audio);
  }

  @ConfirmDelete()
  deleteAudio(i) {
    this.stopPlaying();
    this.question.audio.splice(i, 1);
  }

  @ConfirmDelete()
  deleteVideo(i) {
    this.question.videos.splice(i, 1);
  }

  async openPicture(imgs, index) {
    if (this.platform.platforms().some(p => ["desktop", "mobileweb"].includes(p))) {
      //Desktop
      this.helper.openLightBox(imgs, index)
    } else {
      // android / ios
      this.photoViewer.show(imgs[index]);
    }
  }

  async openVideo(index) {
    if (this.question?.videos?.length) {
      let urlObj = await this.supa.supabase.storage.from('internaldesign').createSignedUrl(this.question.videos[index], 600)
      console.log("URLOBJ", index, this.question.videos[index], urlObj)
      this.helper.createAndOpenModal(VideodialogDialog, { url: urlObj.signedURL }, 'doubled-modal fullscreen-modal')
    }
  }

}
