import { Component, Input, OnInit } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-videodialog',
  templateUrl: './videodialog.dialog.html',
  styleUrls: ['./videodialog.dialog.scss'],
})
export class VideodialogDialog implements OnInit {

  @Input() url: string;

  constructor(private modalCtrl : ModalController, private helper: HelperService) {
  }

  ngOnInit() {
    console.log(this.url)
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
