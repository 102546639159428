import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { VhcDrawModule } from '../vhcdrawmodule';
import { ModalController } from '@ionic/angular';
import { DrawerLibComponent } from 'drawer-lib';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-drawanswer',
  templateUrl: './drawanswer.dialog.html',
  styleUrls: ['./drawanswer.dialog.scss'],
})
export class DrawanswerDialog implements AfterViewInit {

  @ViewChild('drawer', {static: false}) drawer: DrawerLibComponent;

  @Input('drawmodule') drawmodule: VhcDrawModule = null;

  retryCounter = 50;

  constructor(private modalController: ModalController, private helper: HelperService) { }

  ngAfterViewInit() {
    this.retryCounter--;
    if (this.drawer) {
      this.drawer.Background.crossOrigin = 'Anonymous';
      this.drawer.Background.src = this.drawmodule.data.background;
      this.drawer.Deserialize(this.drawmodule.data.drawItems);
    } else if (this.retryCounter > 0) {
      setTimeout(()=>{ this.ngAfterViewInit(); }, 100);
    } else {
      this.helper.notify('Could not load the drawer.', 'danger');
    }
  }

  handleClose() {
    if (this.drawer) {
      this.drawmodule.data.finalDrawing = this.drawer.GetImage();
      this.drawmodule.data.drawItems = this.drawer.Serialize();
      this.drawmodule.data.background = this.drawer.Background.src;
    }
  }

  dismissModal() {
    this.handleClose();
    this.modalController.dismiss(false, 'cancel');
  }

  nextModal() {
    this.handleClose();
    this.modalController.dismiss(true);
  }

}
