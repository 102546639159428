import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-iframewrapper',
  templateUrl: './iframewrapper.component.html',
  styleUrls: ['./iframewrapper.component.scss'],
})
export class IframeWrapperComponent implements OnInit {

  @Input('url') url = '';
  @Input('title') title = '';
  @Input('color') color = 'primary';

  constructor(private modalController: ModalController, private hostElement: ElementRef, protected sanitizer: DomSanitizer) { }

  getUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }

  ngOnInit() {
    const iframe = this.hostElement.nativeElement.querySelector('iframe');
    iframe.src = this.url;
    console.log(iframe.src)
  }
  
  dismissModal() {
    this.modalController.dismiss();
  }

}
