import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { JSettings, SupaService } from '../../../services/supa.service';
import { HelperService } from '../../../services/helper.service';
import { VhcBoschModule } from '../vhcboschmodule';
@Component({
  selector: 'app-boschanswer',
  templateUrl: './boschanswer.dialog.html',
  styleUrls: ['./boschanswer.dialog.scss'],
})
export class BoschAnswerDialog implements OnInit {

  @Input('module') bmodule: VhcBoschModule;
  @Input('settings') settings: JSettings;

  bdevs:BoschDevice[];
  bresult = [
    {
      "id": 1,
      "results": ["<DiagnosticCheck><DTCCode><Code>B0001</Code><Severity>3</Severity><Symptoms>SRS - air bag lamp illuminated</Symptoms><Causes>SRS - air bags system - connectors - sensing and diagnostic module - Instrument Panel module - connectors</Causes><Description>Driver Frontal Stage 1 Deployment Control (Subfault)</Description><IsActive>false</IsActive></DTCCode><DTCCode><Code>B0020</Code><Severity>3</Severity><Symptoms>Possible  SRS - air bag light illuminated</Symptoms><Causes>Wiring - sensing and diagnostic module connector - sensing and diagnostic module - side impact module - Left Front connector -  Left Front side impact module</Causes><Description>Left Side Airbag Deployment Control (Subfault)</Description><IsActive>false</IsActive></DTCCode><DTCCode><Code>B0052</Code><Severity>3</Severity><Symptoms>SRS - air bag lamp illuminated</Symptoms><Causes>Wiring - passenger seat belt sensor - SDM (sensing and diagnostic module)</Causes><Description>Passenger Seatbelt Sensor (Subfault)</Description><IsActive>false</IsActive></DTCCode>/DiagnosticCheck>",
    "<DiagnosticCheck><DTCCode><Code>B0052</Code><Severity>3</Severity><Symptoms>SRS - air bag lamp illuminated</Symptoms><Causes>Wiring - passenger seat belt sensor - SDM (sensing and diagnostic module)</Causes><Description>Passenger Seatbelt Sensor (Subfault)</Description><IsActive>false</IsActive></DTCCode>/DiagnosticCheck>",
    "<DiagnosticCheck><DTCCode><Code>B0001</Code><Severity>3</Severity><Symptoms>SRS - air bag lamp illuminated</Symptoms><Causes>SRS - air bags system - connectors - sensing and diagnostic module - Instrument Panel module - connectors</Causes><Description>Driver Frontal Stage 1 Deployment Control (Subfault)</Description><IsActive>false</IsActive></DTCCode>"
    ]
    },
    {
      "id": 2,
      "results": ["{\"InspectionResult\":{\"StartTime\":\"2016-09-26T09:22:01.22\",\"EndTime\":\"2016-09-26T09:24:33.147\",\"Equipments\":{\"Equipment\":{\"EquipmentType\":\"TPMS\",\"EquipmentName\":\"02342346\",\"Result\":{\"TirePressure\":{\"LeftFront\":{\"Id\":\"2621379679\",\"Pressure\":\"34\",\"Temperature\":\"71\",\"BatteryStatus\":\"Good\"},\"RightFront\":{\"Id\":\"2621435171\",\"Pressure\":\"35\",\"Temperature\":\"71\",\"BatteryStatus\":\"Good\"},\"RightRear\":{\"Id\":\"2621379679\",\"Pressure\":\"34\",\"Temperature\":\"71\",\"BatteryStatus\":\"Good\"},\"LeftRear\":{\"Id\":\"2621435171\",\"Pressure\":\"32\",\"Temperature\":\"70\",\"BatteryStatus\":\"Good\"}}}}}}}", 
    "{\"InspectionResult\":{\"StartTime\":\"2016-09-28T15:36:05.08\",\"EndTime\":\"2016-09-28T15:36:15.007\",\"Equipments\":{\"Equipment\":{\"EquipmentType\":\"TPMS\",\"EquipmentName\":\"02342346\",\"Result\":{\"TirePressure\":{\"LeftFront\":{\"Id\":\"2621379679\",\"Pressure\":\"35\",\"Temperature\":\"75\",\"BatteryStatus\":\"Good\"},\"RightFront\":{\"Id\":\"2621435171\",\"Pressure\":\"36\",\"Temperature\":\"75\",\"BatteryStatus\":\"Good\"},\"RightRear\":{\"Id\":\"2621379679\",\"Pressure\":\"35\",\"Temperature\":\"75\",\"BatteryStatus\":\"Good\"},\"LeftRear\":{\"Id\":\"2621435171\",\"Pressure\":\"33\",\"Temperature\":\"74\",\"BatteryStatus\":\"Good\"}}}}}}}",
    "{\"InspectionResult\":{\"StartTime\":\"2016-09-29T10:02:05.08\",\"EndTime\":\"2016-09-29T10:04:34.921\",\"Equipments\":{\"Equipment\":{\"EquipmentType\":\"TPMS\",\"EquipmentName\":\"02342346\",\"Result\":{\"TirePressure\":{\"LeftFront\":{\"Id\":\"2621379679\",\"Pressure\":\"34\",\"Temperature\":\"69\",\"BatteryStatus\":\"Good\"},\"RightFront\":{\"Id\":\"2621435171\",\"Pressure\":\"35\",\"Temperature\":\"69\",\"BatteryStatus\":\"Good\"},\"RightRear\":{\"Id\":\"2621379679\",\"Pressure\":\"35\",\"Temperature\":\"69\",\"BatteryStatus\":\"Good\"},\"LeftRear\":{\"Id\":\"2621435171\",\"Pressure\":\"32\",\"Temperature\":\"69\",\"BatteryStatus\":\"Good\"}}}}}}}"
    ]
    },
    {
      "id": 3,
      "results": ["<MidtronicsResult><Results Type=\"Midtronics Data\"><Result><Property Name=\"URL\"></Property><Property Name=\"ID\">31</Property><Property Name=\"TIME\"> 4:56 </Property><Property Name=\"DATE\"> 01/05/2016</Property><Property Name=\"DCODE\"></Property><Property Name=\"FILENAME\"></Property><Property Name=\"TEST_INDX\"></Property><Property Name=\"TEST_TIME\"></Property><Property Name=\"TEST_DATE\"></Property><Property Name=\"VIN\">KMHDH4AE2GU583763</Property><Property Name=\"RO\">498922</Property><Property Name=\"USER_ID\">UNREG</Property><Property Name=\"BATT_UNIT\">CCA</Property><Property Name=\"BATT_TYPE\">FLOODED</Property><Property Name=\"RATD_VOLT\">12.00</Property><Property Name=\"RATD\">100</Property><Property Name=\"MEAS_VOLT\">11.59</Property><Property Name=\"MEAS\">0</Property><Property Name=\"TEMP\">76</Property><Property Name=\"TEMP_UNIT\">F</Property><Property Name=\"BATT_DECI\">CHARGE AND RETEST</Property><Property Name=\"WARR_CODE\">00GLA-UCA2K</Property><Property Name=\"SOH\">112</Property><Property Name=\"DEALER_ID\">99999</Property><Property Name=\"MAC\">00:1E:C0:0D:36:B4</Property><Property Name=\"SERIAL\">0514100124</Property><Property Name=\"SOFT_VER\">192-110544A</Property></Result></Results></MidtronicsResult>",
    "<MidtronicsResult><Results Type=\"Midtronics Data\"><Result><Property Name=\"URL\"></Property><Property Name=\"ID\">31</Property><Property Name=\"TIME\"> 2:35 </Property><Property Name=\"DATE\"> 05/07/2016</Property><Property Name=\"DCODE\"></Property><Property Name=\"FILENAME\"></Property><Property Name=\"TEST_INDX\"></Property><Property Name=\"TEST_TIME\"></Property><Property Name=\"TEST_DATE\"></Property><Property Name=\"VIN\">KMHDH4AE2GU583763</Property><Property Name=\"RO\">498922</Property><Property Name=\"USER_ID\">UNREG</Property><Property Name=\"BATT_UNIT\">CCA</Property><Property Name=\"BATT_TYPE\">FLOODED</Property><Property Name=\"RATD_VOLT\">12.00</Property><Property Name=\"RATD\">100</Property><Property Name=\"MEAS_VOLT\">11.45</Property><Property Name=\"MEAS\">0</Property><Property Name=\"TEMP\">83</Property><Property Name=\"TEMP_UNIT\">F</Property><Property Name=\"BATT_DECI\">CHARGE AND RETEST</Property><Property Name=\"WARR_CODE\">00GLA-UCA2K</Property><Property Name=\"SOH\">110</Property><Property Name=\"DEALER_ID\">99999</Property><Property Name=\"MAC\">00:1E:C0:0D:36:B4</Property><Property Name=\"SERIAL\">0514100124</Property><Property Name=\"SOFT_VER\">192-110544A</Property></Result></Results></MidtronicsResult>",
    "<MidtronicsResult><Results Type=\"Midtronics Data\"><Result><Property Name=\"URL\"></Property><Property Name=\"ID\">31</Property><Property Name=\"TIME\"> 5:17 </Property><Property Name=\"DATE\"> 16/12/2016</Property><Property Name=\"DCODE\"></Property><Property Name=\"FILENAME\"></Property><Property Name=\"TEST_INDX\"></Property><Property Name=\"TEST_TIME\"></Property><Property Name=\"TEST_DATE\"></Property><Property Name=\"VIN\">KMHDH4AE2GU583763</Property><Property Name=\"RO\">498922</Property><Property Name=\"USER_ID\">UNREG</Property><Property Name=\"BATT_UNIT\">CCA</Property><Property Name=\"BATT_TYPE\">FLOODED</Property><Property Name=\"RATD_VOLT\">12.00</Property><Property Name=\"RATD\">100</Property><Property Name=\"MEAS_VOLT\">12.83</Property><Property Name=\"MEAS\">0</Property><Property Name=\"TEMP\">72</Property><Property Name=\"TEMP_UNIT\">F</Property><Property Name=\"BATT_DECI\">CHARGE AND RETEST</Property><Property Name=\"WARR_CODE\">00GLA-UCA2K</Property><Property Name=\"SOH\">125</Property><Property Name=\"DEALER_ID\">99999</Property><Property Name=\"MAC\">00:1E:C0:0D:36:B4</Property><Property Name=\"SERIAL\">0514100124</Property><Property Name=\"SOFT_VER\">192-110544A</Property></Result></Results></MidtronicsResult>"
      ]
    }
  ]  

  constructor(private helper: HelperService, private modalController: ModalController, public platform: Platform, private supa: SupaService) { }

  ngOnInit() {
    if (!this.bmodule) {
      this.dismissModal()
      return;
    }
    this.init();
  }

  async init() {
    setTimeout(()=>{
      this.bdevs=[
        {
          signedIn: Math.random() < 0.5,
          id: 1,
          title: "U-SCAN",
          serial: "0138346253"
        },
        {
          signedIn: Math.random() < 0.5,
          id: 2,
          title: "TPA-200",
          serial: "02342346"
        },
        {
          signedIn: Math.random() < 0.5,
          id: 3,
          title: "Battery Tester",
          serial: "0514100124"
        }
      ];
      this.bdevs[Math.floor(Math.random()*2)+1].signedIn = true;
    }, 3000)
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  
  nextModal() {
    this.modalController.dismiss(true);
  }

}

export interface BoschDevice {
  title: string,
  serial: string,
  signedIn: boolean,
  id: number
}