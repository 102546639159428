import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './pages/account/account.component';
import { HomeComponent } from './pages/home/home.component';
import { LocationeditorComponent } from './pages/locations/locationeditor/locationeditor.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { BrakediscpadsComponent } from './pages/settings/brakediscpads/brakediscpads.component';
import { StorageComponent } from './pages/settings/storage/storage.component';
import { JobsComponent } from './pages/settings/job/job.component';
import { MaintenanceeditorComponent } from './pages/settings/maintenancetables/maintenanceeditor/maintenanceeditor.component';
import { MaintenancetablesComponent } from './pages/settings/maintenancetables/maintenancetables.component';
import { PackagesComponent } from './pages/settings/packages/packages.component';
import { PartsComponent } from './pages/settings/part/part.component';
import { QuotationlayoutComponent } from './pages/settings/quotationlayout/quotationlayout.component';
import { QuotationlayouteditorComponent } from './pages/settings/quotationlayout/quotationlayouteditor/quotationlayouteditor.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ToceditorComponent } from './pages/settings/toceditor/toceditor.component';
import { TyrebrandsComponent } from './pages/settings/tyrebrands/tyrebrands.component';
import { Cross_sellComponent } from './pages/settings/cross_sell/cross_sell.component';
import { VhcblueprinteditorComponent } from './pages/settings/vhcblueprints/vhcblueprinteditor/vhcblueprinteditor.component';
import { VhcblueprintsComponent } from './pages/settings/vhcblueprints/vhcblueprints.component';
import { VehicleeditorComponent } from './pages/vehicles/vehicleeditor/vehicleeditor.component';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { VhcrecordComponent } from './pages/vhcrecord/vhcrecord.component';
import { FollowupComponent } from './pages/followup/followup.component';
import { MessagetemplatesComponent } from './pages/settings/messagetemplates/messagetemplates.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { VincouplerComponent } from './pages/settings/vincoupler/vincoupler.component';

const routes: Routes = [
  {
    path: 'account',
    component: AccountComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'messages',
    component: MessagesComponent
  },
  {
    path: 'vhc-record',
    component: VhcrecordComponent
  },
  {
    path: 'followup',
    component: FollowupComponent
  },
  {
    path: 'vehicles',
    component: VehiclesComponent
  },
  {
    path: 'vehicles/editor',
    component: VehicleeditorComponent
  },
  {
    path: 'locations',
    component: LocationsComponent
  },
  {
    path: 'locations/editor',
    component: LocationeditorComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'settings/tocedit',
    component: ToceditorComponent
  },
  {
    path: 'settings/mtedit',
    component: MessagetemplatesComponent
  },
  {
    path: 'settings/vincoupler',
    component: VincouplerComponent
  },
  {
    path: 'settings/tyrebrands',
    component: TyrebrandsComponent
  },
  {
    path: 'settings/storage',
    component: StorageComponent
  },
  {
    path: 'settings/brakediscpads',
    component: BrakediscpadsComponent
  },
  {
    path: 'settings/packages',
    component: PackagesComponent
  },
  {
    path: 'settings/cross_sell',
    component: Cross_sellComponent
  },
  {
    path: 'settings/parts',
    component: PartsComponent
  },
  {
    path: 'settings/jobs',
    component: JobsComponent
  },
  {
    path: 'settings/quotationlayout',
    component: QuotationlayoutComponent
  },
  {
    path: 'settings/quotationlayout/editor',
    component: QuotationlayouteditorComponent
  },
  {
    path: 'settings/vhcblueprints',
    component: VhcblueprintsComponent
  },
  {
    path: 'settings/vhcblueprints/editor',
    component: VhcblueprinteditorComponent
  },
  {
    path: 'settings/maintenance-tables',
    component: MaintenancetablesComponent
  },
  {
    path: 'settings/maintenance-tables/editor',
    component: MaintenanceeditorComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'account',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
