import { Component, OnDestroy, OnInit } from '@angular/core';
import { RealtimeSubscription } from '@supabase/supabase-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { SupaService } from '../../services/supa.service';
import { MessageDialog } from './messagedialog.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {

  MessagesObservable: BehaviorSubject<VhcMessage[]> = new BehaviorSubject<VhcMessage[]>([
    {
      id: 1,
      created_at: new Date(),
      message: 'This is a test message',
      isread: false,
      userid: '',
      url: ''
    }
  ]);

  constructor(private helper: HelperService, private supa: SupaService) { }

  subscription: Subscription

  totalRecords = 0;
  from = 0
  amount = 9

  ngOnInit() {
    this.supa.countUnreadMessages();
    this.getData();
    this.subscription = this.supa.NotificationObservable.subscribe(v => {
      this.totalRecords++;
      if (this.from == 0) {
        this.MessagesObservable.next([v, ...this.MessagesObservable.value.slice(0, this.amount)])
      }
    })
  }

  async getData() {
    this.MessagesObservable.next(undefined)
    const c = (await this.supa.supabase.from('vhcmessages').select('id', { count: 'exact', head: true }).eq('userid',this.supa.LoginObject.value?.id+'')).count;
    this.totalRecords = c ? c : 0
    this.supa.supabase.from<VhcMessage>('vhcmessages')
      .select('*')
      .eq('userid',this.supa.LoginObject.value?.id+'')
      .range(this.from, this.from + this.amount)
      .order('id', { ascending: false })
      .then(v => this.MessagesObservable.next((v.data ? v.data : [])))
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  openNotification(message: VhcMessage) {
    this.helper.createAndOpenModal(MessageDialog, {message: message})
    if (!message.isread) {
      this.supa.supabase.from("vhcmessages").update({isread: true}).eq('id', message.id).then(v => {
        message.isread = true;
        this.supa.countUnreadMessages();
      })
    }
  }

}

export interface VhcMessage {
  id: number,
  created_at: Date,
  message: string,
  isread: boolean,
  userid: string,
  url: string
}